import { fabric } from 'fabric';
import CanvasObjectType from '../../../enums/CanvasObjectType';
import CustomFabricObject from '../../../types/CustomFabricObject';
import { SnappingLineCoord, BleedCoord } from './utils';

type ClipBounds = {
	height: number;
	width: number;
	top: number;
	left: number;
};

export const anonymousLayerProperties = {
	hiddenLayer: true,
	layerLocked: true,
	hasControls: false,
	selectable: false,
	evented: false,
	absolutePositioned: true,
	crossOrigin: 'anonymous',
};

export function generateClipPath(clipBounds: ClipBounds) {
	const clipPath = new fabric.Rect({
		...anonymousLayerProperties,
		...clipBounds,
		hoverCursor: 'default',
		fill: 'transparent',
		objectCaching: false,
	}) as CustomFabricObject;

	return clipPath.set({
		layerName: 'Clip Area',
		objectType: CanvasObjectType.CLIP,
	});
}

export function generateClipBorder(clipBounds: ClipBounds, invertedColor: string) {
	const clipBorder = new fabric.Rect({
		...anonymousLayerProperties,
		...clipBounds,
		strokeWidth: 2,
		strokeDashArray: [20, 20],
		stroke: invertedColor,
		fill: 'transparent',
	}) as CustomFabricObject;

	return clipBorder.set({
		layerName: 'Clip Border',
		objectType: CanvasObjectType.CLIP_BORDER,
	});
}

export function generateSnappingLines(snappingLines: SnappingLineCoord[]) {
	const snapGroupItems: CustomFabricObject[] = [];

	snappingLines.forEach((s) => {
		const clipSnapLine = new fabric.Line(s.size, {
			top: s.coords[0],
			left: s.coords[1],
			stroke: 'transparent',
			strokeWidth: 1,
		}) as CustomFabricObject;

		clipSnapLine.set({ layerName: s.name });
		snapGroupItems.push(clipSnapLine);
	});

	const snapGroup = new fabric.Group(snapGroupItems) as CustomFabricObject;

	return snapGroup.set({
		objectType: CanvasObjectType.SNAP,
		layerName: 'Snapping Controllers',
		...anonymousLayerProperties,
	});
}

export function generateGrid(clipBounds: ClipBounds, invertedColor: string, visible?: boolean) {
	const gridLen = (clipBounds.height - 2) / 8;
	const gridLines = [];

	const lineProps = {
		type: 'line',
		stroke: invertedColor,
	};

	// Create horizontal grid lines
	for (let i = 0; i < clipBounds.width / gridLen; i++) {
		gridLines.push(new fabric.Line([i * gridLen, 0, i * gridLen, clipBounds.height - 2], lineProps));
	}

	// Create vertical grid lines
	for (let index = 0; index < clipBounds.height / gridLen; index++) {
		gridLines.push(new fabric.Line([0, index * gridLen, clipBounds.width - 2, index * gridLen], lineProps));
	}

	const gridGroup = new fabric.Group(gridLines) as CustomFabricObject;

	return gridGroup.set({
		objectType: CanvasObjectType.GRID,
		layerName: 'Grid Lines',
		left: clipBounds.left + 2,
		top: clipBounds.top + 2,
		opacity: 0.5,
		visible,
		...anonymousLayerProperties,
	});
}

export function generateBleed(bleedObjects: BleedCoord[]) {
	const bleedGroupItems: CustomFabricObject[] = [];
	const bleedGroup = new fabric.Group(bleedGroupItems) as CustomFabricObject;

	bleedObjects.forEach((s) => {
		const bleedRect = new fabric.Rect({
			top: s.coords[0],
			left: s.coords[1],
			height: s.coords[2],
			width: s.coords[3],
			stroke: 'transparent',
			backgroundColor: 'white',
			opacity: 0.1,
		}) as CustomFabricObject;

		bleedGroupItems.push(bleedRect);
	});

	return bleedGroup.set({
		...anonymousLayerProperties,
		objectType: CanvasObjectType.BLEED,
		layerName: 'Bleed',
	});
}
