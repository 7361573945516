/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import { uniq, difference } from 'lodash';
import { useCallback, createRef, useEffect, useContext, useState } from 'react';
import WebFontLoader from 'webfontloader';
import * as S from './Editor.style';
import { Canvas, ProductDetails, Layers, Loader, AssetManager } from './components';
import * as Controller from './components/Controllers';
import { ProductActionsContainer } from './containers';
import { useIsLoading, useActiveCanvas, useTemplate, useErrorState, useProduct, useSaveProduct } from './hooks';
import { createNewStore, createNewItem, saveItem, toggleItemPublish } from './services/MerchAPI';
import { CanvasesContext, EditorsContext, UserContext } from './store';
import { CanvasAction, Vendors, EditorStateAction, ErrorTypes } from './types';
import { uploadAssets, generatePrintCanvases, prepareVariantImages } from './utils';

export function Editor() {
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { state: editorState } = useContext(EditorsContext);
	const { state: userState } = useContext(UserContext);

	const { activeCanvas } = useActiveCanvas();
	const { product } = useProduct();
	const { template } = useTemplate();
	const { setErrorState } = useErrorState();
	const { executeSaveProduct, saveError, saveSuccess } = useSaveProduct();
	const loading = useIsLoading();

	const [appLoading, setAppLoading] = useState<boolean>(false);
	const [assetManagerModalOpen, setAssetManagerModelOpen] = useState<boolean>(false);
	const [assetManagerTab, setAssetManagerTab] = useState<number>(1);
	const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
	const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
	const [multiSideSaveOpen, setMultiSideSaveOpen] = useState<boolean>(false);

	const canvasRef = createRef<HTMLDivElement>();
	const isEmbroidery = !!template?.vendorPlacements[Object.keys(template.vendorPlacements)[0]].options;

	function redirectToDashboard(itemId?: string | null) {
		window.onbeforeunload = null;
		window.location.href = `https://streamelements.com/dashboard/se-merch?editorAction=${
			itemId || template?.id || 'init'
		}`;
	}

	async function saveProductGooten(
		imageUrl: string,
		imageTransformUrl: string,
		duplicateCanvasData: string,
		position: string,
		isPublished: boolean,
	) {
		if (!editorState || !template?.variants.length || !userState.user) return;

		const finalPrice = editorState.product.price - editorState.product.basePrice;
		const getActiveColors = () =>
			uniq(
				canvasState.variants
					.filter((variant) => canvasState.activeVariants.some((activeVariant) => variant.color === activeVariant))
					.map((filteredVariant) => filteredVariant.colorCodes[0]),
			);

		let profitOpts = {};

		if (editorState.state === EditorStateAction.CREATE) {
			profitOpts = {
				selectedProfit: 'custom',
				customValue: finalPrice,
			};
		} else {
			profitOpts = {
				profit: {
					selectedProfit: 'custom',
					customValue: finalPrice,
				},
			};
		}

		const item = {
			canvas: '',
			title: editorState.product.name,
			description: editorState.product.description || ' ',
			templateId: canvasState.preloadId,
			editorMode: 'design_editor',
			colors: getActiveColors(),
			isPublished,
			...profitOpts,
			assets: {
				[position]: {
					transformUrl: imageTransformUrl,
					url: imageUrl,
					designConfiguration: {
						designType: 'design_editor',
						text: 't',
						preset: 't',
						font: 't',
						color: 't',
					},
				},
			},
		};

		const imageWidth = template.variants[0].templates[Object.keys(template.variants[0].templates)[0]].printAreaWidth;
		const imageHeight = template.variants[0].templates[Object.keys(template.variants[0].templates)[0]].printAreaHeight;

		if (template.productType !== 'Mugs') {
			const variantImages = await prepareVariantImages(
				template.variants,
				canvasState.activeVariants,
				{
					id: canvasState.preloadId,
					name: template.name,
				},
				imageTransformUrl,
				{ imageWidth, imageHeight },
				null,
			);

			// @ts-ignore
			item.variantImages = variantImages;
		}

		item.canvas = JSON.stringify(duplicateCanvasData);

		switch (editorState.state) {
			case EditorStateAction.NEW_STORE:
				createNewStore(userState.user._id, imageUrl)
					.then(() => redirectToDashboard())
					.catch(() => {
						setAppLoading(false);
						throw new Error('Error creating the store, please retry or contact support');
					});
				break;

			default:
			case EditorStateAction.CREATE:
				createNewItem(userState.user._id, item)
					.then(() => redirectToDashboard())
					.catch(() => {
						setAppLoading(false);
						throw new Error('Error creating the item, please retry or contact support');
					});
				break;

			case EditorStateAction.EDIT:
				{
					if (!product) break;

					const prevPalette = uniq(product?.variants.map((variant) => variant.color));
					const newPalette = canvasState.activeVariants;

					const removeColors = difference(prevPalette, newPalette);
					const addColors = difference(newPalette, prevPalette);

					if (!!removeColors && removeColors.length && !!removeColors[0]) {
						// @ts-ignore
						item.remove = {
							color: removeColors,
						};
					}

					if (!!addColors && addColors.length && !!addColors[0]) {
						// @ts-ignore
						item.add = {
							color: addColors,
						};
					}
					// @ts-ignore
					item.colors = undefined;
					// @ts-ignore
					item.isPublished = undefined;
					// @ts-ignore
					item.templateId = undefined;

					saveItem(userState.user._id, product.id, item)
						.then((res) => {
							if (res.statusCode === 400) {
								throw new Error();
							}
						})
						.then(() => {
							if (userState.user?._id) {
								toggleItemPublish(userState.user._id, product.id || '', isPublished).then(() =>
									redirectToDashboard(product.id),
								);
							}
						})
						.catch(() => {
							setAppLoading(false);
							throw new Error('Error updating the item, please retry or contact support');
						});
				}
				break;
		}
	}

	async function saveProduct(isPublished: boolean) {
		setSaveModalOpen(false);
		setAppLoading(true);

		const printCanvases = await generatePrintCanvases(canvasState, editorState);
		const placements = await uploadAssets(printCanvases, canvasState, setAppLoading, setErrorState);

		if (!placements.length) return null;

		if (template?.vendor === Vendors.GOOTEN) {
			saveProductGooten(
				placements[0].assetUrl,
				placements[0].transformUrl,
				placements[0].canvas,
				placements[0].position,
				isPublished,
			);
		}

		if (template?.vendor === Vendors.PRINTFUL) {
			executeSaveProduct(placements, isPublished);
		}
	}

	const handleSelectObj = useCallback(() => {
		if (!activeCanvas) return;

		canvasDispatch({
			type: CanvasAction.SET_SELECTED_OBJECT,
			payload: activeCanvas.canvas?.getActiveObject(),
		});
	}, [activeCanvas?.canvas, canvasDispatch]);

	const handleDeselect = useCallback(() => {
		canvasDispatch({ type: CanvasAction.SET_SELECTED_OBJECT, payload: null });
	}, [canvasDispatch]);

	const handleWindowResize = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas || !canvasRef.current) return;

		let canvasH = canvas.getHeight();
		let canvasW = canvas.getWidth();

		const { height, width } = canvasRef.current.getBoundingClientRect();
		const scale = Math.min(height / canvasH, width / canvasW);

		canvasH = canvasH * scale;
		canvasW = canvasW * scale;

		canvas.setDimensions({
			width: canvasW,
			height: canvasH,
		});

		canvas.setZoom(canvas.getZoom() * scale);
		canvas.renderAll();
	}, [activeCanvas, canvasRef]);

	useEffect(() => {
		window.onbeforeunload = (e: BeforeUnloadEvent) => {
			e = e || window.event;
			if (e) e.returnValue = 'Sure?';
			return 'Sure?';
		};
	}, []);

	useEffect(() => {
		if (!activeCanvas?.canvas) return;

		if (editorState.fonts) {
			WebFontLoader.load({
				google: {
					families: editorState.fonts,
				},
				active: function () {
					fabric.util.clearFabricFontCache();
					activeCanvas?.canvas?.renderAll();
				},
			});
		}
	}, [activeCanvas?.canvas, editorState.fonts]);

	useEffect(() => {
		const itemId = editorState.state === EditorStateAction.EDIT ? canvasState.preloadId : undefined;

		if (saveError) {
			setAppLoading(false);
			setErrorState(true, saveError === 400 ? ErrorTypes.SAVE_PRODUCT_FAILED : ErrorTypes.TOO_MANY_REQUESTS);
		}

		if (saveSuccess) {
			setAppLoading(false);
			redirectToDashboard(itemId);
		}
	}, [redirectToDashboard, saveError, saveSuccess, setErrorState, editorState.state]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		canvas.on('selection:updated', () => {
			handleDeselect();
			handleSelectObj();
		});
		canvas.on('selection:created', handleSelectObj);
		canvas.on('selection:cleared', handleDeselect);

		return () => {
			canvas.off('selection:cleared');
			canvas.off('selection:updated');
			canvas.off('selection:created');
		};
	}, [activeCanvas?.canvas, handleSelectObj, handleDeselect]);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	if (loading) return <p>Loading...</p>;

	return (
		<div className={S.EditorRoot}>
			{appLoading && <Loader />}
			<Controller.ObjectFloatController />
			<Controller.SnappingController />
			<Controller.ErrorController openSaveModal={setSaveModalOpen} />

			<ProductActionsContainer
				onSave={saveProduct}
				saveModal={{ saveModalOpen, setSaveModalOpen }}
				previewModal={{ previewModalOpen, setPreviewModalOpen }}
				multiSideSaveModal={{ multiSideSaveOpen, setMultiSideSaveOpen }}
			/>

			<S.EditorActionsContainer embroidery={isEmbroidery}>
				<S.SidebarContainer>
					<ProductDetails />
					<Layers setAssetManagerModelOpen={setAssetManagerModelOpen} setAssetManagerTab={setAssetManagerTab} />
				</S.SidebarContainer>
				<Canvas canvasRef={canvasRef} />

				<AssetManager
					setLoading={setAppLoading}
					open={assetManagerModalOpen}
					defaultTab={assetManagerTab}
					onOpenChange={(open: boolean) => setAssetManagerModelOpen(open)}
				/>

				{isEmbroidery && <Controller.ThreadController />}
			</S.EditorActionsContainer>
			<Controller.BackgroundImageController />
			<S.BottomBarContainer>
				<Controller.ZoomController />
			</S.BottomBarContainer>
		</div>
	);
}
