import { Template } from '.';

export interface ProductsResponse {
	list: Template[];
}

export interface PreviewData {
	catalogProductId: string;
	color: string;
	placements: {
		[key: string]: {
			threadColors: string[];
		};
	};
}

export enum ProductTypes {
	EMBROIDERY = 'EMBROIDERY',
	MUG = 'Mugs',
}

type Placement = {
	threadColors: string[] | [];
	canvas: string;
	assetURL: string;
};

export interface CreateNewItemRequest {
	catalogProductID: string;
	colors: string[];
	price: number;
	customDescription: string;
	itemName: string;
	published: boolean;
	placements: {
		[key: string]: Placement;
	};
}
