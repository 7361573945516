import { css, Select, styled } from '@streamelements/frontend-ui';

const stylePrefix = css({})();

const styledSelect = css({
    [`.${stylePrefix}`]: {
        '&__value-container' : {
            padding: '$base',
            margin: 'inherit',
            minWidth: 100,
        },
        '&__menu' : {
            width: '160%'
        },
        '&__control': {
            padding: 0,
            display: 'grid',
            gridAutoFlow: 'column',
            
            '&:hover, &--is-focused': {
                borderColor: '$themePrimaryMain',
                [`.${stylePrefix}__dropdown-indicator`]: {
                    color: '$themePrimaryMain',
                }
            },
            '&--menu-is-open': {
                [`.${stylePrefix}__dropdown-indicator`]: {
                    fill: '$themePrimaryMain',
                    transform: 'rotate(180deg)',
                    backgroundColor: '$themePrimary15',
                }
            },
        },
        '&__single-value' : {
            color: '$themePrimaryMain'
        },
        '&__dropdown-indicator': {
            color: '$themePrimaryMain',
            marginRight: 0,

            'svg' : {
                height: 10,
            }
        },
        '&__option': {
            '&--is-selected': {
                color: '$themePrimaryMain',

                '&:hover, &:focus, &--is-focused': {
                    color: '$themePrimaryMain'
                }
            },

            '&:hover, &--is-focused': {
                cursor: 'pointer',
                br: '$base'
            }
        },
    },

    variants: {
        bigValue: {
            true: {
                [`.${stylePrefix}`]: {
                    '&__value-container' : {
                        minWidth: 130
                    }
                }
            }
        },
        midValue: {
            true: {
                [`.${stylePrefix}`]: {
                    '&__value-container' : {
                        minWidth: 20
                    }
                }
            }
        },
        smallValue: {
            true: {
                [`.${stylePrefix}`]: {
                    '&__value-container' : {
                        minWidth: 30
                    }
                }
            }
        },
    }
});

export const ThemedSelectOption = styled('div', {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gap: '$base',
    alignItems: 'center',
});

const ThemedSelect = (props: any) => <Select {...props} className={styledSelect({ smallValue: props.smallValue })} />

export default ThemedSelect;