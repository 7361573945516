import { createContext, useReducer, useEffect } from 'react';
import { userInitialState, UserReducer } from '.';
import { getCurrentUser, getUserAssets } from '../../services/MerchAPI';
import { getStore } from '../../services/merchCatalog';
import { UserState, UserAction, UserActions } from '../../types';

export const UserContext = createContext<{
	state: UserState;
	dispatch: React.Dispatch<UserActions>;
}>({
	state: userInitialState,
	dispatch: () => null,
});

export const UserProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(UserReducer, userInitialState);

	useEffect(() => {
		async function fetchTemplates() {
			dispatch({ type: UserAction.GET_USER });

			try {
				const store = await getStore();
				const user = await getCurrentUser();
				const assets = await getUserAssets();

				dispatch({
					type: UserAction.GET_USER_SUCCESS,
					payload: {
						store,
						user,
						assets,
					},
				});
			} catch {
				dispatch({ type: UserAction.GET_USER_ERROR });
			}
		}

		if (!state.loading && !state.store && !state.user) fetchTemplates();
	}, []);

	return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};
