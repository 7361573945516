import UrlMap, { token, decodeToken } from '../config/UrlMap';
import { UserAssetsResponse } from '../types';

const BASE_URL = UrlMap.KappaApi;
const MERCH_BASE_URL = `${BASE_URL}/merch`;
const MERCH_CATALOG_URL = UrlMap.MerchCatalog;

const ROUTES = {
	me: '/channels/me',
	templates: '/gooten/templates',
	store: '/store',
	userAssets: '/store/images',
};

const doFetch = (method: string, url: string, includeAuth?: boolean, options: RequestInit = {}, ignoreJson = false) => {
	let headers = {
		Authorization: '',
	};

	if (options && options.headers) {
		headers = {
			...headers,
			...options.headers,
		};
	}

	if (includeAuth) {
		headers.Authorization = `Bearer ${token}`;
	}
	
	const res = fetch(url, {
		method,
		...options,
		headers,
	});

	return ignoreJson ? res : res.then((res) => res.json());
};

export const getCurrentUser = () => doFetch('get', BASE_URL + ROUTES.me, true);
export const deleteUserAsset = (userId: string, assetId: string) =>
	doFetch('delete', `${MERCH_BASE_URL}/${userId}${ROUTES.userAssets}/${assetId}`, true);
export const toggleItemPublish = (userId: string, itemId: string, isPublished: boolean) =>
	doFetch('put', `${MERCH_BASE_URL}/${userId}/editor/item/${itemId}/publish/${isPublished}`, true);

export const createNewItem = (userId: string, item: any) =>
	doFetch('post', `${MERCH_BASE_URL}/${userId}/editor/item`, true, {
		body: JSON.stringify(item),
		headers: {
			'Content-Type': 'application/json',
		},
	});

export const saveItem = (userId: string, itemId: string, item: any) =>
	doFetch('put', `${MERCH_BASE_URL}/${userId}/editor/item/${itemId}`, true, {
		body: JSON.stringify(item),
		headers: {
			'Content-Type': 'application/json',
		},
	});

export const createNewStore = (channelId: string, imgUrl: string) =>
	doFetch('post', `${MERCH_CATALOG_URL}/stores/${channelId}`, true, {
		body: JSON.stringify({
			assetUrl: imgUrl
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	}, true);

/**
 * async fetch request to POST an image.
 *
 * @param   {File | Blob} file - The file to upload
 * @param   {string} channelId - users channel id
 *
 * @returns {Promise<{any}>} returns a url to the image.
 */
export const uploadImage = async (file: File | Blob) => {
	const decodedToken = decodeToken();

	if (!decodedToken) return Promise.reject('No channelId could be decoded');

	const body: BodyInit = new FormData();

	body.append('file', file, 'Graphics.png');

	const image = await fetch(`${MERCH_BASE_URL}/${decodedToken.channelId}/store/images`, {
		method: 'POST',
		body,
		headers: {
			'se-transform': 'true',
			Authorization: `Bearer ${token}`,
		},
	});

	const res = await image.json();

	return res;
};

/**
 * async fetch request to get the users store data
 *
 * @param   {string} channelId - users channel id
 *
 * @returns {Promise<StoreResponse>} returns a promise with the StoreResponse data
 */
export const getUserAssets = async (): Promise<UserAssetsResponse> => {
	const decodedToken = decodeToken();

	if (!decodedToken) return Promise.reject('No channelId could be decoded');

	const store = await fetch(`${MERCH_BASE_URL}/${decodedToken.channelId}/store/images`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	const res = (await store.json()) || {};

	return res;
};
