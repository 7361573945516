import { useEffect, useState } from 'react';
import { CloseRounded } from '@streamelements/frontend-icons';
import { Modal, Button, Slot, Text } from '@streamelements/frontend-ui';
import { ScienceEvent, ScienceSource } from '../../enums';
import { useScience } from '../../hooks';
import { CanvasObject } from '../../types';
import { objectVisualizer, clearProperties, duplicateAndClearCanvas } from '../../utils';

interface PreviewButtonProps {
	canvasObject: CanvasObject;
}

const visualizer = objectVisualizer();

export function PreviewButton({ canvasObject }: PreviewButtonProps) {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const { trackEvent } = useScience();

	function onModalOpen(val: boolean) {
		setModalOpen(val);
		trackEvent({
			name: ScienceEvent.PREVIEW_ITEM_CLICK,
			source: ScienceSource.EDITOR_PANEL,
			placement: 'preview_button',
		});
	}

	async function getRawCanvas() {
		const [{ canvas, width, height }] = await duplicateAndClearCanvas([canvasObject]);
		const DupCanvasData = canvas.toDatalessJSON(clearProperties) as any;

		DupCanvasData.originalCanvasWidth = width;
		DupCanvasData.originalCanvasHeight = height;

		try {
			visualizer.initApp(
				canvas.toDataURL({
					width,
					height,
					format: 'png',
				}),
			);
		} catch {
			console.warn('Error Converting canvas, includes tainted images');
		}
	}

	useEffect(() => {
		if (!modalOpen) return;

		getRawCanvas();
	}, [modalOpen, getRawCanvas]);

	return (
		<Modal.Root onOpenChange={(val) => onModalOpen(val)} open={modalOpen}>
			<Modal.Trigger as={Slot}>
				<Button variant="outlined" color="primary">
					Preview
				</Button>
			</Modal.Trigger>
			<Modal.Overlay />
			<Modal.Content css={{ display: 'grid', padding: 'calc($base * 3)' }}>
				<Modal.Close as={Slot}>
					<CloseRounded />
				</Modal.Close>
				<Text.Heading level={5} weight="bold">
					3D Preview
				</Text.Heading>
				<Text.Body variant="caption" css={{ opacity: 0.5 }}>
					Click or tap and drag to rotate
				</Text.Body>
				<canvas id="viewport" />
			</Modal.Content>
		</Modal.Root>
	);
}
