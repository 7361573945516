import { useHistory } from 'react-router-dom';
import { Button, styled, Text } from '@streamelements/frontend-ui';
import { halfGreyText } from '../Editor.style';

const Container = styled('div', {
	display: 'grid',
	gridAutoRows: 'max-content',
	rowGap: 'calc($base * 3)',
	backgroundColor: '#fff',
	justifyContent: 'center',
	textAlign: 'center',
	padding: 'calc($base * 3)',
});

export function NoMobileSupportOverlay() {
	const history = useHistory();
	return (
		<Container>
			<img src="/images/NoMobileImage.png" alt="No Mobile Support" />
			<Text.Heading level={5} weight="black">
				Mobile Support <br />
				Coming Soon!
			</Text.Heading>
			<Text.Body weight="bold" className={halfGreyText}>
				Unfortunately we do not support SE.Merch
				<br />
				Editor in mobile devices yet.
				<br />
				<br />
				Try viewing this page with
				<br />
				your Desktop or Tablet device
			</Text.Body>
			<Button variant="outlined" onClick={() => history.goBack()} style={{ marginTop: 16 }}>
				RETURN TO SE.MERCH
			</Button>
		</Container>
	);
}
