import { createContext, useReducer, useMemo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { editorInitialState, EditorReducer } from '.';
import { UserContext } from '../';
import { useTemplate } from '../../hooks';
import { EditorState, EditorActions, EditorAction, EditorStateAction } from '../../types';

export const EditorsContext = createContext<{
	state: EditorState;
	dispatch: React.Dispatch<EditorActions>;
}>({
	state: editorInitialState,
	dispatch: () => null,
});

export const EditorsProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(EditorReducer, editorInitialState);
	const { state: userState } = useContext(UserContext);
	const { template } = useTemplate();

	const location = useLocation();
	const query = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location]);
	const templateId = query.get('id');
	const newStore = query.get('newStore');

	useEffect(() => {
		if (!userState.store || !template) return;

		const activeTemplate = userState.store?.items?.find((item) => item.id === templateId);

		const editorState = newStore
			? EditorStateAction.NEW_STORE
			: activeTemplate
			? EditorStateAction.EDIT
			: EditorStateAction.CREATE;

		dispatch({ type: EditorAction.SET_EDITOR_STATE, payload: editorState });

		dispatch({ type: EditorAction.SET_BASE_PRICE, payload: activeTemplate?.basePrice || template?.basePrice || 0 });

		dispatch({
			type: EditorAction.SET_RECOMMENDED_PRICE,
			payload: activeTemplate?.recommendedPrice || template?.recommendedPrice || 0,
		});

		dispatch({
			type: EditorAction.SET_PLACEMENTS,
			payload:
				editorState === EditorStateAction.EDIT && activeTemplate ? Object.keys(activeTemplate.placementsAssets) : [],
		});

		dispatch({
			type: EditorAction.SET_SAVE_PLACEMENTS,
			payload: activeTemplate?.vendorPlacements ? Object.keys(activeTemplate?.vendorPlacements) : [],
		});
	}, [userState.store, template]);

	return <EditorsContext.Provider value={{ state, dispatch }}>{children}</EditorsContext.Provider>;
};
