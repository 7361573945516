import { DownloadRounded } from '@streamelements/frontend-icons';
import { Button } from '@streamelements/frontend-ui';
import { ScienceEvent, ScienceSource, SciencePrefix } from '../../enums';
import { useScience } from '../../hooks';
import { CanvasObject } from '../../types';
import { duplicateAndClearCanvas } from '../../utils';

async function exportImage(canvasObject: CanvasObject) {
	const [{ canvas, width, height }] = await duplicateAndClearCanvas([canvasObject], true);
	const link = document.createElement('a');

	const dataURL = canvas.toDataURL({
		width: width,
		height: height,
		format: 'png',
	});

	link.download = 'image.png';
	link.href = dataURL;
	link.click();
	canvas.fire('canvas:downloaded');
}

export function ExportButton({ canvasObject }: { canvasObject: CanvasObject }) {
	const { trackEvent } = useScience();

	function onExport() {
		trackEvent({
			name: ScienceEvent.EXPORT,
			source: ScienceSource.EDITOR_PANEL,
			prefix: SciencePrefix.DASHBOARD,
			placement: 'export_button',
		});

		exportImage(canvasObject);
	}

	return (
		<Button css={{ padding: '0 calc($base * 2)' }} variant="ghost" color="primary" onClick={() => onExport()}>
			<DownloadRounded />
			Export
		</Button>
	);
}
