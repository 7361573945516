import { useState } from 'react';
import { KeyboardArrowUpRounded, Check } from '@streamelements/frontend-icons';
import { Popover, Text, styled } from '@streamelements/frontend-ui';
import { useActiveCanvas } from '../../../hooks';
import { PrintSide } from '../../../types';

const StyledTrigger = styled(Popover.Trigger, {
	background: 'none',
	border: '1px solid transparent',
	padding: '0 12px',
	textTransform: 'capitalize',
	fontSize: '14px',
	fontWeight: 900,
	lineHeight: '36px',
	borderRadius: '$base',
	display: 'flex',
	alignItems: 'center',
	fontFamily: '"Nunito Sans", sans-serif;',
	cursor: 'pointer',
	transition: '0.2s all',

	span: {
		marginLeft: '$base',
		fontSize: '1rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '20px',
		height: '20px',
		borderRadius: '4px',
	},

	'&:hover': {
		border: '1px solid rgba(86, 132, 253, 0.5)',
	},

	variants: {
		isOpen: {
			true: {
				border: '1px solid rgba(86, 132, 253, 0.5)',

				span: {
					color: '#5684FD',
					background: 'rgba(86, 132, 253, 0.16)',
					transform: 'rotate(180deg)',
				},
			},
		},
	},
});

const StyledContent = styled(Popover.Content, {
	margin: '-20px 0 0 76px',
	width: '272px',
	borderRadius: 'calc($base * 2)',
	background: '#ffffff',
	color: '#000000',
	border: '1px solid rgba(0, 0, 0, 0.1)',
	boxShadow: '$2',
	padding: '0 $base',
	overflow: 'hidden',

	label: {
		display: 'block',
		width: '100%',
		float: 'left',
	},
});

const StyledHeading = styled('div', {
	borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	padding: '12px',

	p: {
		color: '#000000',
		display: 'flex',
		fontSize: '0.75rem',

		span: {
			ml: 'auto',
		},
	},
});

const StyledFooter = styled('div', {
	borderTop: '1px solid rgba(0, 0, 0, 0.1)',
	padding: '12px',

	p: {
		color: '#000000',
		display: 'flex',
		fontSize: '0.75rem',

		span: {
			ml: 'auto',
		},
	},
});

const StyledSide = styled('div', {
	display: 'flex',
	cursor: 'pointer',
	height: '38px',
	alignItems: 'center',
	padding: '$base',
	boxSizing: 'border-box',
	textTransform: 'capitalize',
	fontSize: '0.75rem',
	borderRadius: '$base',
	transition: '0.2s all',

	span: {
		display: 'flex',
		width: '16px',
		height: '16px',
		border: '1px solid rgba(0, 0, 0, 0.5)',
		borderRadius: '16px',
		alignItems: 'center',
		justifyContent: 'center',
		mr: '$base',

		svg: {
			color: '#FFFFFF',
			fontSize: '0.75rem',
			opacity: 0,
			transition: '0.2s all',
		},
	},

	'&:hover': {
		background: 'rgba(86, 132, 253, 0.16)',
	},

	variants: {
		selected: {
			true: {
				span: {
					background: '$uiPrimaryMain',
					border: 'none',

					svg: {
						opacity: 1,
					},
				},
			},
		},
	},
});

export function PrintSideSelector({
	printSides,
	selectedPrintSide,
}: {
	printSides: PrintSide[];
	selectedPrintSide: string | null;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const { setActivePrintSide } = useActiveCanvas();

	if (!selectedPrintSide) return null;

	return (
		<div style={{ pointerEvents: 'initial' }}>
			<Popover.Root onOpenChange={(open: boolean) => setIsOpen(open)}>
				<StyledTrigger isOpen={isOpen}>
					{selectedPrintSide.replace('_', ' ')}
					<span>
						<KeyboardArrowUpRounded />
					</span>
				</StyledTrigger>
				<StyledContent>
					<StyledHeading>
						<Text.Body weight="bold">
							Print Side <span>Additional Cost*</span>
						</Text.Body>
					</StyledHeading>
					<div style={{ padding: '8px 0' }}>
						{printSides.map(({ value, price }) => (
							<StyledSide key={price} onClick={() => setActivePrintSide(value)} selected={selectedPrintSide === value}>
								<span>
									<Check />
								</span>
								<Text.Body weight="bold">{value.replace('_', ' ')}</Text.Body>
								<Text.Body css={{ marginLeft: 'auto', fontSize: '0.75rem', opacity: 0.5 }}>
									+${price.toFixed(2)}
								</Text.Body>
							</StyledSide>
						))}
					</div>
					<StyledFooter>
						<Text.Body weight="bold">* Cost of one side is already included.</Text.Body>
					</StyledFooter>
				</StyledContent>
			</Popover.Root>
		</div>
	);
}
