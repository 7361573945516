import { fabric } from 'fabric';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { CanvasState, CanvasObject, CustomFabricImage, CanvasAction, CustomFabricObject } from '../../types';
import CustomFabricText from '../../types/CustomFabricText';
import { numberOrZero } from '../../utils/NumberUtils';

export function makeSelectedObject(
	activeCanvas: CanvasObject,
	obj: CustomFabricObject,
	shiftKey?: boolean,
	ctrlKey?: boolean,
) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	if (shiftKey) {
		const selection = new fabric.ActiveSelection([...canvas.getActiveObjects(), obj], { canvas });
		canvas.setActiveObject(selection);
		canvas.requestRenderAll();
	} else if (ctrlKey) {
		const objects = canvas?.getActiveObjects();
		if (!objects.length) return;
		const selectedObjects = [...objects.filter((i) => i !== obj)];
		if (selectedObjects.length) {
			const selection = new fabric.ActiveSelection(selectedObjects, { canvas });
			canvas.setActiveObject(selection);
			canvas.requestRenderAll();
		}
	} else {
		canvas?.setActiveObject(obj);
	}
}

export function addToCanvas(canvas: fabric.Canvas, obj: CustomFabricObject, callback?: () => void) {
	if (!canvas) return;

	obj.set({
		top: 0,
		left: 0,
		fill: obj.fill || '#000000',
		stroke: '#000000',
		strokeWidth: 0,
		paintFirst: 'stroke',
		centeredRotation: true,
		borderColor: '#5684FD',
		cornerColor: '#5684FD',
		snapAngle: 15,
	});

	obj.setControlsVisibility({
		mt: false, // middle top disable
		mb: false, // midle bottom
		ml: false, // middle left
		mr: false, // I think you get it
	});

	const clipLayer = canvas.getObjects().find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP);

	if (clipLayer) {
		const clipBounds = clipLayer.getBoundingRect();

		obj.set({
			clipPath: clipLayer,
			top: clipBounds.height / 2 + clipBounds.top - obj.getScaledHeight() / 2,
			left: clipBounds.width / 2 + clipBounds.left - obj.getScaledWidth() / 2,
		});
	}

	obj.center();
	canvas.add(obj);
	canvas.setActiveObject(obj);
	canvas.renderAll();
	callback && callback();
}

export function addText(activeCanvas: CanvasObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const newText: CustomFabricText = new fabric.IText('Hello World', {
		fontWeight: '100',
		fontFamily: 'Arial',
		layerName: 'Text',
		objectType: CanvasObjectType.TEXT,
		fill: 'black',
	} as CustomFabricText);

	addToCanvas(canvas, newText);
}

export function addShape(activeCanvas: CanvasObject, objLink: string, objName = 'generic') {
	fabric.loadSVGFromURL(objLink, (objects, options) => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const svgData = fabric.util.groupSVGElements(objects, options);
		const baseProps = {
			strokeUniform: false,
			paintFirst: 'stroke',
			objectType: CanvasObjectType.SHAPE,
			layerName: objName,
		};

		svgData.set(baseProps);
		svgData.scaleToWidth(100);
		svgData.scaleToHeight(100);
		addToCanvas(canvas, svgData as CustomFabricObject);
	});
}

export function addImageRaw(
	state: CanvasState,
	activeCanvas: CanvasObject,
	imgObj: CustomFabricImage,
	fileName: string,
	fromPrevData?: boolean,
	callback?: (img: CustomFabricImage) => void,
	overrideCanvas?: fabric.Canvas,
) {
	const canvas = overrideCanvas || activeCanvas?.canvas;

	if (!canvas) return;

	const imageHeight = imgObj.get('height') || 10;
	const imageWidth = imgObj.get('width') || 10;

	const filenameChunks = fileName.split('.');
	const fileType = filenameChunks[filenameChunks.length - 1];

	const canvasObjType = fileType === 'svg' ? CanvasObjectType.SHAPE : CanvasObjectType.IMAGE;

	imgObj.set({
		angle: 0,
		objectType: canvasObjType,
		layerName: fileName,
		height: imageHeight,
		width: imageWidth,
		oldObject: !!fromPrevData,
		strokeWidth: 0,
	});

	const originalCanvasH = state.defaultHeight || 1;
	const originalCanvasW = state.defaultWidth || 1;
	const canvasH = canvas.getHeight();
	const canvasW = canvas.getWidth();

	const canvasScaleH = canvasH / originalCanvasH;
	const canvasScaleW = canvasW / originalCanvasW;

	const imageScaledW = imageWidth * canvasScaleW;
	const imageScaledH = imageHeight * canvasScaleH;

	const clipLayer = canvas.getObjects()?.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP);

	if (
		imageScaledH >= canvasH ||
		imageScaledW >= canvasW ||
		(clipLayer && (imageScaledH >= clipLayer.getScaledHeight() || imageScaledW >= clipLayer.getScaledWidth()))
	) {
		const scaleRatioH = canvasH / imageHeight;
		const scaleRatioW = canvasW / imageWidth;

		let scale = Math.max(scaleRatioH, scaleRatioW) / 2;

		if (clipLayer) {
			scale = Math.min((clipLayer.get('width') || 1) / imageWidth, (clipLayer.get('height') || 1) / imageHeight) / 1.05;
		}

		imgObj.set({
			scaleX: scale,
			scaleY: scale,
		});
	} else {
		imgObj.set({
			scaleX: canvasScaleW,
			scaleY: canvasScaleH,
		});
	}

	addToCanvas(canvas, imgObj, () => {
		callback && callback(imgObj);
	});
}

export function addImage(
	state: CanvasState,
	activeCanvas: CanvasObject,
	imgObj: HTMLImageElement,
	fileName: string,
	fromPrevData?: boolean,
	callback?: (img: CustomFabricImage) => void,
) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	imgObj.crossOrigin = 'anonymous';

	fabric.Image.fromURL(
		imgObj.src,
		(image) => {
			addImageRaw(state, activeCanvas, image, fileName, fromPrevData, callback);
		},
		{ crossOrigin: 'Anonymous' },
	);
}

export function removeObject(
	canvas: fabric.Canvas | null,
	obj?: CustomFabricObject,
	dispatch?: (payload: any) => void,
) {
	if (!canvas) return;

	const activeObjects = obj ? [obj] : canvas.getActiveObjects();
	activeObjects.forEach((o) => o && canvas?.remove(o));

	let hasErrors = false;

	canvas.getObjects().forEach((obj: CustomFabricObject) => {
		if (obj.get('errored')) {
			hasErrors = true;
		}
	});

	if (dispatch) {
		dispatch({ type: CanvasAction.SET_ERROR, payload: hasErrors });
	}

	canvas.discardActiveObject();
	canvas.renderAll();
}

export function groupObjects(activeCanvas: CanvasObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const activeObject = canvas.getActiveObject() as CustomFabricObject;

	if (!activeObject || activeObject.type !== 'activeSelection') return;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	activeObject.toGroup().set({
		objectType: CanvasObjectType.GROUP,
		layerName: 'Group',
	});

	const clipLayer = canvas.getObjects().find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP);

	if (clipLayer) {
		activeObject.set({
			clipPath: clipLayer,
		});
	}

	canvas.renderAll();
	canvas.fire('object:modified');
}

export function ungroupObjects(activeCanvas: CanvasObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const activeObject = canvas.getActiveObject() as CustomFabricObject;

	if (!activeObject || activeObject.objectType !== CanvasObjectType.GROUP) return;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	activeObject.toActiveSelection();
	canvas.renderAll();
}

export function toggleVisibility(activeCanvas: CanvasObject, obj: CustomFabricObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	canvas
		.getObjects()
		.find((o) => o === obj)
		?.set('visible', !obj.get('visible'));
	canvas.renderAll();
}

export function selectAll(activeCanvas: CanvasObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const unselectableLayers = [
		CanvasObjectType.CLIP,
		CanvasObjectType.OVERLAY,
		CanvasObjectType.SNAP,
		CanvasObjectType.BLEED,
		CanvasObjectType.GRID,
		CanvasObjectType.BACKGROUND_IMAGE,
		CanvasObjectType.PRODUCT_IMAGE,
		CanvasObjectType.CLIP_BORDER,
	];
	canvas.discardActiveObject();
	const sel = new fabric.ActiveSelection(
		canvas
			.getObjects()
			.filter((o: CustomFabricObject) => !unselectableLayers.includes(o.get('objectType') || CanvasObjectType.TEXT)),
		{
			canvas,
		},
	);
	canvas.setActiveObject(sel);
	canvas.renderAll();
}

export function unselect(activeCanvas: CanvasObject) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	canvas.discardActiveObject().renderAll();
}

export function moveObjVertical(activeCanvas: CanvasObject, amount: number) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const activeObject = canvas.getActiveObject() as CustomFabricObject;

	if (!activeObject) return;

	activeObject.set('left', numberOrZero(activeObject.get('left')) + amount);
	canvas.renderAll();
}

export function moveObjHorizontal(activeCanvas: CanvasObject, amount: number) {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const activeObject = canvas.getActiveObject() as CustomFabricObject;

	if (!activeObject) return;

	activeObject.set('top', numberOrZero(activeObject.get('top')) + amount);
	canvas.renderAll();
}

export function cloneObject(activeCanvas: CanvasObject, obj: CustomFabricObject | undefined) {
	const canvas = activeCanvas?.canvas;

	if (!canvas || !obj) return;

	obj.clone((cloned: CustomFabricObject) => {
		cloned.set({
			id: (cloned.id || 1) * 2,
			objectType: obj.get('objectType'),
			layerName: obj.layerName,
			evented: true,
		});
		canvas?.add(cloned);
		canvas?.setActiveObject(cloned);
		canvas?.renderAll();
	});
}
