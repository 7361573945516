import { useContext, useState, useEffect } from 'react';
import { CanvasesContext, UserContext } from '../store';
import { ProductItem } from '../types';

export function useProduct() {
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: userState } = useContext(UserContext);
	const [product, setProduct] = useState<ProductItem | null>(null);

	useEffect(() => {
		if (userState.store?.items && canvasState.preloadId) {
			const activeTemplate = userState.store.items.find((item) => item.id === canvasState.preloadId);

			if (activeTemplate) setProduct(activeTemplate);
		}
	}, [userState.store?.items, canvasState.preloadId]);

	return { product };
}
