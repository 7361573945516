import { useContext, useState, useEffect } from 'react';
import { CanvasesContext, EditorsContext } from '../store';
import { updateLayers } from '../store/ActionCreators/LayersActionCreator';
import { CanvasObject, CanvasAction } from '../types';

export function useActiveCanvas() {
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { state: editorState, dispatch: editorDispatch } = useContext(EditorsContext);
	const [activeCanvas, setActiveCanvas] = useState<CanvasObject | null>(null);

	function setActivePrintSide(printSide: string) {
		const newCanvas = canvasState.canvases.find((v) => v.position === printSide) as CanvasObject;

		canvasDispatch({ type: CanvasAction.SET_ACTIVE_PRINTSIDE, payload: printSide });

		if (newCanvas.canvas) {
			updateLayers(
				newCanvas.canvas,
				canvasDispatch,
				newCanvas.position,
				editorDispatch,
				editorState.product.placements,
			);
		}
	}

	useEffect(() => {
		const { selectedPrintSide, canvases } = canvasState;

		if (selectedPrintSide && canvases) {
			const foundVariant = canvases.find((v) => v.position === selectedPrintSide) as CanvasObject;

			setActiveCanvas(foundVariant);
		}
	}, [canvasState.selectedPrintSide, canvasState.canvases]);

	return { activeCanvas, setActivePrintSide };
}
