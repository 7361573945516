import { useContext, useCallback } from 'react';
import { styled, Text } from '@streamelements/frontend-ui';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext } from '../../store';
import { CustomFabricObject, CanvasAction } from '../../types';

const GridToggle = styled('button', {
	cursor: 'pointer',
	transition: 'opacity 0.2s ease-in-out',
	opacity: 0.9,
	background: 'none',
	border: 'none',
	height: '100%',
	margin: 0,
	padding: '0 calc($base * 2)',

	'&:hover': {
		opacity: 1,
	},
});

export function GridController() {
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { activeCanvas } = useActiveCanvas();

	const toggleGrid = useCallback(
		(grid: boolean) => {
			const canvas = activeCanvas?.canvas;

			if (!canvas) return;

			canvasDispatch({ type: CanvasAction.SET_GRID_ENABLED, payload: grid });

			const gridObj = canvas
				.getObjects()
				.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.GRID);

			if (gridObj) {
				gridObj.set('visible', grid);

				canvas.renderAll();
			}
		},
		[activeCanvas?.canvas, canvasDispatch],
	);

	return (
		<GridToggle onClick={() => toggleGrid(!canvasState.grid)}>
			<Text.Body variant="caption" weight="bold">
				{canvasState.grid ? 'Disable' : 'Enable'} Grid
			</Text.Body>
		</GridToggle>
	);
}
