import { Text } from "@streamelements/frontend-ui";
import { PropsWithChildren } from "react";
import AssetItem from "../AssetItem";

interface UserAsset {
    _id: string,
    name: string,
    size: number,
    type: string,
    createdAt: string,
    url: string,
    thumbnailUrl?: string
}

interface Props {
    items: UserAsset[],
    onItemDelete: (e: React.MouseEvent<HTMLDivElement>, assetId: string) => void,
    onItemDownload: (e: React.MouseEvent<HTMLDivElement>, assetId: string) => void,
    onItemClick: (img: string) => void
}

export default function UserGallery({ items, onItemDelete, onItemDownload, onItemClick }: PropsWithChildren<Props>) {
    return (
        <>
        {!!items.length && items.map(i => (
            <AssetItem
                onDelete={onItemDelete}
                onDownload={onItemDownload}
                itemId={i._id}
                backgroundImage={i.thumbnailUrl || i.url}
                img={i.url}
                key={i._id}
                onClick={onItemClick}
                name={i.name}
                size={i.size}
                type={i.type}
                createdAt={i.createdAt}
            />
        ))}
        {!items.length && <Text.Body>No Items Found</Text.Body>}
        </>
    )
}