import { useEffect, useState } from 'react';
import { ActionBlock } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import { numberOrZero } from '../../utils/NumberUtils';
import ThemedSelect from '../ThemesSelect';

const availableWidth = [16, 24, 32, 40, 48, 56, 64, 72, 80];

export default function FontSizeController() {
	const { activeCanvas } = useActiveCanvas();

	const [fontSize, setFontSize] = useState<string | number>(0);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'fontSize', numberOrZero(+fontSize));
		}
	}, [fontSize]);

	useEffect(() => {
		if (!activeCanvas?.canvas || !activeCanvas?.selectedObject) return;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setFontSize(numberOrZero(activeCanvas.canvas.getActiveObject().get('fontSize')));
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject]);

	const getValueObjFromNumber = (value: number | string) => ({ label: `${value}px`, value });

	return (
		<ActionBlock clearVerticalSpacing>
			<ThemedSelect
				borderless
				midValue
				value={getValueObjFromNumber(fontSize)}
				options={availableWidth.map((a) => getValueObjFromNumber(a))}
				onChange={(newVal: any) => setFontSize(newVal.value)}
			/>
		</ActionBlock>
	);
}
