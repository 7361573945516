import { ActionBlock, ActionTitle, ActionVerticalDivider, lightGreyText } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import CustomFabricObject from '../../types/CustomFabricObject';
import AlignController from './AlignController';
import FillController from './FillController';
import StrokeController from './StrokeController';

export function ShapeController() {
	const { activeCanvas } = useActiveCanvas();
	const canvas = activeCanvas?.canvas;

	if (!canvas) return null;

	const activeObject = canvas?.getActiveObject() as CustomFabricObject;

	return activeObject && activeObject.get('objectType') === CanvasObjectType.SHAPE ? (
		<>
			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					SHAPE
				</ActionTitle>
			</ActionBlock>
			<FillController />
			<ActionVerticalDivider />
			<StrokeController />
			<ActionVerticalDivider />
			<AlignController />
		</>
	) : null;
}
