import { PropsWithChildren } from "react";
import { SvgIcon } from "@streamelements/frontend-icons";
import SvgIconProps from "../../types/SvgIconProps";

export default function ShapeIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M13.9541 10.3793C11.0723 10.3793 8.72776 12.7239 8.72776 15.6056C8.72776 18.4874 11.0723 20.832 13.9541 20.832C16.8358 20.832 19.1804 18.4874 19.1804 15.6056C19.1803 12.7239 16.8358 10.3793 13.9541 10.3793Z"/>
<path d="M20.9474 11.6054L15.5982 3.15753C15.536 3.05944 15.4281 3 15.312 3C15.196 3 15.0879 3.05944 15.0258 3.15753L12.7459 6.75805C12.7116 6.81213 12.6934 6.87509 12.6934 6.93922V9.31301C12.6934 9.41118 12.7359 9.5044 12.81 9.5688C12.8841 9.63316 12.9821 9.66207 13.0796 9.64838C13.3574 9.60911 13.6398 9.58925 13.9189 9.58925C15.7912 9.58925 17.583 10.4867 18.7122 11.9901C18.7763 12.0754 18.8765 12.1255 18.9832 12.1255H20.6614C20.7849 12.1255 20.8987 12.0582 20.9582 11.95C21.0175 11.8418 21.0135 11.7098 20.9474 11.6054Z"/>
<path d="M11.5883 10.0617C11.7134 10.0086 11.7947 9.88591 11.7947 9.74986V6.54681C11.7947 6.35978 11.643 6.20811 11.456 6.20811H3.3387C3.15163 6.20811 3 6.35974 3 6.54681V14.6642C3 14.8512 3.15163 15.0029 3.3387 15.0029H7.65567C7.82369 15.0029 7.96636 14.8797 7.99077 14.7133C8.29285 12.6577 9.67126 10.8754 11.5883 10.0617Z"/>
        </SvgIcon>
    )
}