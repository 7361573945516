import { useContext, useEffect, useState } from 'react';
import { styled, Card, Text } from '@streamelements/frontend-ui';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext } from '../../store';
import CustomFabricObject from '../../types/CustomFabricObject';
import ImageDPIController from './ImageDPIController';

const CanvasContainer = styled(Card.Root, {
	padding: 'calc($base * 2)',
	borderRadius: 0,
	display: 'grid',
	alignItems: 'center',
	gap: '$base',
	width: '100%',
	borderTop: '1px solid rgba(0, 0, 0, 0.1)',
	boxShadow: 'none',
	position: 'relative',
	boxSizing: 'border-box',
});

const dpiSupportedTypes: Array<CanvasObjectType | undefined> = [CanvasObjectType.IMAGE];

export function CanvasController() {
	const { state: canvasState } = useContext(CanvasesContext);
	const { activeCanvas } = useActiveCanvas();
	const [boardSize, setBoardSize] = useState<{ height: number; width: number }>({
		width: 0,
		height: 0,
	});

	const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;

	useEffect(() => {
		const defaultSize = {
			height: Number(canvasState.defaultHeight),
			width: Number(canvasState.defaultWidth),
		};

		if (activeCanvas?.clip) {
			defaultSize.height = activeCanvas.clip.height;
			defaultSize.width = activeCanvas.clip.width;
		}

		setBoardSize(defaultSize);
	}, [activeCanvas?.clip, canvasState.defaultHeight, canvasState.defaultWidth]);

	return (
		<CanvasContainer>
			<Text.Body variant="caption" css={{ lineHeight: '1.25rem', fontSize: '12px' }}>
				{boardSize.width}px x {boardSize.height}px
			</Text.Body>
			{dpiSupportedTypes.includes(activeObject?.get('objectType')) && <ImageDPIController />}
		</CanvasContainer>
	);
}
