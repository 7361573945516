import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ActionBlock, ActionTitle, lightGreyText } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import { numberOrZero } from '../../utils/NumberUtils';
import { ColorPicker } from '../ColorPicker';
import ThemedSelect from '../ThemesSelect';

const availableWidth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function StrokeController() {
	const { activeCanvas } = useActiveCanvas();

	const [strokeValue, setStrokeValue] = useState('');
	const [strokeWidth, setStrokeWidth] = useState<string | number>(0);
	const [value] = useDebounce(strokeValue, 250);

	const getValueObjFromNumber = (value: number | string) => {
		const label = value === 0 ? 'None' : value;
		return { label: label, value };
	};

	const getOptions = () => {
		const items = availableWidth.map((a) => getValueObjFromNumber(a));
		return [{ label: 'None', value: 0 }, ...items];
	};

	useEffect(() => {
		if (!activeCanvas?.canvas || !activeCanvas?.selectedObject) return;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setStrokeWidth(numberOrZero(activeCanvas?.selectedObject?.get('strokeWidth')));
		setStrokeValue(activeCanvas?.selectedObject?.get('stroke') || '');
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'stroke', strokeValue || '');
		}
	}, [strokeValue]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'strokeWidth', numberOrZero(+strokeWidth));
		}
	}, [strokeWidth]);

	return (
		<>
			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					OUTLINE
				</ActionTitle>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<ColorPicker items={[]} value={value} onChange={setStrokeValue} />
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<ThemedSelect
					borderless
					smallValue
					value={getValueObjFromNumber(strokeWidth)}
					options={getOptions()}
					onChange={(newVal: any) => setStrokeWidth(newVal.value)}
					formatOptionLabel={(o: { label: string; value: string }) => o.label}
				/>
			</ActionBlock>
		</>
	);
}
