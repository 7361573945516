import { PropsWithChildren } from "react";
import { SvgIcon } from "@streamelements/frontend-icons";
import SvgIconProps from "../../types/SvgIconProps";

export default function FlipVerticalIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M13.5 11.25C13.9139 11.25 14.2499 11.5859 14.2499 11.9999C14.2499 12.414 13.9139 12.7499 13.5 12.7499L4.49982 12.7499C4.08577 12.7499 3.74972 12.414 3.74972 11.9999C3.74972 11.5859 4.08577 11.25 4.49982 11.25L13.5 11.25Z"/>
<path d="M14.0626 20.9999L3.93745 20.9999C3.42067 20.9999 2.99989 20.5791 2.99989 20.0623C2.99989 19.7962 3.11085 19.5427 3.29995 19.3724L8.55823 14.4898C8.73223 14.3353 8.95498 14.2498 9.18748 14.2498C9.43344 14.2498 9.66745 14.348 9.89254 14.5798L14.6379 19.3273C14.649 19.3371 14.7135 19.3926 14.7241 19.4038C14.9018 19.5824 15 19.8164 15 20.0623C15 20.5791 14.5794 20.9999 14.0626 20.9999Z"/>
<path d="M9.92179 9.39003C9.91204 9.40115 9.85724 9.46419 9.8468 9.47394C9.49867 9.81974 8.92504 9.83704 8.54999 9.5025L3.30668 4.63344C3.11469 4.46095 2.99989 4.20071 2.99989 3.93744C2.99989 3.42067 3.42067 2.99988 3.93745 2.99988L14.0626 2.99988C14.5794 2.99988 15 3.42067 15 3.93744C15 4.18491 14.9018 4.41892 14.67 4.64236L9.92179 9.39003Z"/>
<path d="M19.461 15.6649L20.8352 17.0396C20.9956 17.2001 21.0443 17.4424 20.9574 17.6524C20.8703 17.8623 20.6649 17.9997 20.4376 17.9997L16.3126 17.9997C16.0021 17.9997 15.7501 17.7477 15.7501 17.4372L15.7501 13.312C15.7501 13.0849 15.8873 12.8793 16.0973 12.7924C16.167 12.7638 16.2398 12.7495 16.3126 12.7495C16.4588 12.7495 16.6028 12.8065 16.71 12.9146L18.3923 14.5969C19.0974 13.6255 19.5 12.4578 19.5 11.2496C19.5 9.5133 18.7111 7.88428 17.3356 6.77945C17.0131 6.51989 16.9613 6.04747 17.2208 5.72488C17.3694 5.54045 17.5867 5.44445 17.8058 5.44445C17.9709 5.44445 18.1366 5.49842 18.2753 5.60939C20.0071 7.00069 21.0001 9.05654 21.0001 11.2496C21.0001 12.8568 20.4383 14.4056 19.461 15.6649V15.6649Z"/>
        </SvgIcon>
    )
}