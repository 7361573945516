import { useContext, useEffect, useState, useCallback } from 'react';
import { EditorsContext } from '../store';
import { ErrorTypes, Error, EditorAction } from '../types';

const supportMessage = 'If needed get in contact with support for more help regarding this matter';
const link = 'https://support.streamelements.com/en/articles/167-general-overview';

const errors = {
	imageUploadFailed: {
		title: 'Image upload failed',
		message: `Your image is too large (Maximum 30mb). Try uploading a smaller image or running it through an optimizer. ${supportMessage}`,
		link,
	},
	saveProductFailed: {
		title: 'Product creation failed',
		message: `Your final design size it too large. Try uploading a smaller image or removing a few layers. ${supportMessage}`,
		link,
	},
	tooManyRequests: {
		title: 'We encountered an error',
		message: 'We were unable to save your item.',
		timeout: 60,
	},
};

export function useErrorState() {
	const [error, setError] = useState<Error>({ state: false });
	const { state, dispatch } = useContext(EditorsContext);

	/**
	 * callback to set the global error state
	 *
	 * @param   {boolean} state - Error visibility status
	 * @param   {ErrorTypes} type - Type of error to display
	 *
	 * @returns {void} returns void
	 */
	const setErrorState = useCallback(
		(state = false, type?: ErrorTypes) => {
			dispatch({ type: EditorAction.SET_ERROR_STATE, payload: { state, type } });
		},
		[dispatch],
	);

	useEffect(() => {
		if (state.error.state && state.error.type) {
			setError({
				state: true,
				...errors[state.error.type],
			});
		} else {
			setError({
				state: false,
			});
		}
	}, [state, state.error.state, state.error.type]);

	return { error, setErrorState };
}
