import { useEffect, useState } from 'react';
import { ActionBlock } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import ThemedSelect from '../ThemesSelect';

const items = [
	{
		label: 'Normal',
		value: 100,
	},
	{
		label: 'Bold',
		value: 800,
	},
];

export default function FontWeightController() {
	const { activeCanvas } = useActiveCanvas();

	const [value, setValue] = useState<number>(100);

	useEffect(() => {
		if (!activeCanvas?.canvas || !activeCanvas?.selectedObject) return;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setValue(activeCanvas?.selectedObject.get('fontWeight') as number);
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'fontWeight', value || '');
		}
	}, [value]);

	return (
		<ActionBlock clearVerticalSpacing>
			<ThemedSelect
				placeholder="Weight..."
				borderless
				options={items}
				value={items.find((i) => i.value === value)}
				onChange={(v: { value: number }) => setValue(v.value)}
			/>
		</ActionBlock>
	);
}
