export enum ErrorTypes {
	IMAGE_UPLOAD_FAILED = 'imageUploadFailed',
	SAVE_PRODUCT_FAILED = 'saveProductFailed',
	TOO_MANY_REQUESTS = 'tooManyRequests',
}

export interface ErrorState {
	state: boolean;
	type?: ErrorTypes;
}

export interface Error {
	state: boolean;
	title?: string;
	message?: string;
	link?: string;
	timeout?: number;
}
