import io from 'socket.io-client';
import UrlMap, { token } from '../../../config/UrlMap';

const SOCKET_URL = UrlMap.RealTime;

export const createSocketConnection = (): SocketIOClient.Socket => {
	const getCluster = () => localStorage.getItem('StreamElements.ws-cluster') || 'main';
	
	const sharedSocket = io(SOCKET_URL, {
		transports: ['websocket'],
		reconnection: true,
		reconnectionDelay: 5000,
		query: {
			cluster: getCluster(),
		},
	});

	sharedSocket.on('connect', () => {
		sharedSocket.emit('authenticate', { method: 'jwt', token });
	});

	sharedSocket.on("connect_error", (err: { message: any; }) => {
		console.log(`connect_error due to ${err.message}`);
	});
  
	return sharedSocket;
};
