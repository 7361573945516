/* eslint-disable @typescript-eslint/ban-ts-comment */
import { truncate } from 'lodash';
import React, { PropsWithChildren, useContext, useEffect, useCallback } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ReactTooltip from 'react-tooltip';
import { css, List, ListItem, styled, Text } from '@streamelements/frontend-ui';
import * as S from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext, EditorsContext } from '../../store';
import { makeSelectedObject, removeObject, toggleVisibility } from '../../store/ActionCreators/CanvasActionCreator';
import { sortLayers, updateLayers } from '../../store/ActionCreators/LayersActionCreator';
import CustomFabricObject from '../../types/CustomFabricObject';
import SortableLayerItemProps from '../../types/SortableLayerItemProps';
import {
	GroupIcon,
	TextIcon,
	ImageIcon,
	ShapeIcon,
	WarningIcon,
	EyeDisabledIcon,
	EyeIcon,
	TrashCanIcon,
	VerticalDotsIcon,
} from '../Icons';
import BadImageQualityTooltip from './BadImageQualityTooltip';

let timer: NodeJS.Timeout;
const delay = 200;
let prevent = false;

const itemIcon = css({
	width: '0.55em !important',
})();

const LayerListItem = styled(ListItem, {
	opacity: 0.7,
	display: 'grid',
	gridTemplateColumns: '12px 12px 1fr max-content',
	alignItems: 'center',
	height: 21,
	columnGap: '$base',
	cursor: 'pointer',

	'.controls': {
		display: 'grid',
		marginRight: '$base',
		columnGap: 'calc($base * 2)',
		gridAutoFlow: 'column',
		placeItems: 'center',

		'.control': {
			visibility: 'hidden',

			'&.locked, &.hidden': {
				visibility: 'visible',
			},
		},
	},

	'&:hover': {
		'.control': {
			visibility: 'visible',
		},
	},

	variants: {
		state: {
			selected: {
				opacity: 1,
				color: '$uiPrimaryMain',
			},
		},
		locked: {
			true: {
				gridTemplateColumns: '12px 1fr',
			},
		},
	},
});

export function LayerController() {
	const { activeCanvas } = useActiveCanvas();
	const { dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { state: editor, dispatch: editorDispatch } = useContext(EditorsContext);

	const canvas = activeCanvas?.canvas;

	const getIconFromType = (objectType: CanvasObjectType | undefined, isErrored: boolean | undefined) => {
		if (isErrored) return WarningIcon;

		switch (objectType) {
			default:
			case CanvasObjectType.GROUP:
				return GroupIcon;

			case CanvasObjectType.TEXT:
				return TextIcon;

			case CanvasObjectType.SHAPE:
				return ShapeIcon;

			case CanvasObjectType.IMAGE:
				return ImageIcon;
		}
	};

	const onLayerClick = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		layerLocked: boolean,
		item: CustomFabricObject,
	) => {
		timer = setTimeout(() => {
			if (!prevent && activeCanvas) {
				event.preventDefault();
				return !layerLocked && makeSelectedObject(activeCanvas, item, event.shiftKey, event.ctrlKey);
			}
			prevent = false;
		}, delay);
	};

	const onLayerDoubleClick = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		layerLocked: boolean,
		item: CustomFabricObject,
	) => {
		clearTimeout(timer);
		prevent = true;
		event.preventDefault();

		// @ts-ignore
		if (item.enterEditing) {
			// @ts-ignore
			item.enterEditing();
			// @ts-ignore
			item.hiddenTextarea.focus();
		}
	};

	const onLayerCtrlClick = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		layerLocked: boolean,
		item: CustomFabricObject,
	) => {
		event.preventDefault();
		return !layerLocked && activeCanvas && makeSelectedObject(activeCanvas, item, false, true);
	};

	const isItemSelected = (item: CustomFabricObject) => {
		return canvas && canvas.getActiveObjects().includes(item);
	};

	const DragHandle = SortableHandle(() => <VerticalDotsIcon className={S.smallIcon} style={{ cursor: 'grab' }} />);

	const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
		activeCanvas &&
			sortLayers(
				activeCanvas,
				canvasDispatch,
				oldIndex,
				newIndex,
				activeCanvas.position,
				editorDispatch,
				editor.product.placements,
			);
	};

	const layersUpdated = useCallback(
		(item) => {
			if (activeCanvas) toggleVisibility(activeCanvas, item);

			if (canvas) {
				updateLayers(canvas, canvasDispatch, activeCanvas.position, editorDispatch, editor.product.placements);
			}
		},
		[editor.product.placements, activeCanvas?.position],
	);

	const SortableItem = SortableElement(({ item }: PropsWithChildren<SortableLayerItemProps>) => {
		const layerLocked = item.get('layerLocked');
		const layerVisible = item.get('visible');
		const layerErrored = item.get('errored');
		const layerDPI = item.get('dpi');
		const IconObj = getIconFromType(item.get('objectType'), layerErrored);

		return (
			<LayerListItem locked={layerLocked} state={isItemSelected(item) ? 'selected' : undefined}>
				{!layerLocked && (
					// @ts-ignore
					<DragHandle />
				)}
				<IconObj className={itemIcon} data-tip data-delay-show={300} data-for="LayerObject" />
				{layerErrored && (
					// @ts-ignore
					<ReactTooltip id="LayerObject">{layerDPI && <BadImageQualityTooltip dpi={layerDPI} />}</ReactTooltip>
				)}
				<Text.Body
					className={(isItemSelected(item) && S.primaryText) || ''}
					weight={(isItemSelected(item) && 'bold') || 'regular'}
					onClick={(e) => onLayerClick(e, !!layerLocked, item)}
					onDoubleClick={(e) => onLayerDoubleClick(e, !!layerLocked, item)}
					onContextMenu={(e) => onLayerCtrlClick(e, !!layerLocked, item)}
					css={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
				>
					{truncate(item.get('layerName'), { length: 28 })}
				</Text.Body>
				{!layerLocked && (
					<div className="controls">
						<span onClick={() => layersUpdated(item)} style={{ display: 'grid', placeItems: 'center' }}>
							{layerVisible ? (
								<EyeIcon className={[S.smallIcon, 'control'].join(' ')} />
							) : (
								<EyeDisabledIcon className={[S.smallIcon, layerVisible ? 'visible' : 'hidden', 'control'].join(' ')} />
							)}
						</span>
						<span
							onClick={() => canvas && removeObject(canvas, item, canvasDispatch)}
							style={{ display: 'grid', placeItems: 'center' }}
						>
							<TrashCanIcon color="neutral" className={[S.smallIcon, 'control'].join(' ')} />
						</span>
					</div>
				)}
			</LayerListItem>
		);
	});

	const SortableList = SortableContainer((props: PropsWithChildren<{ items: CustomFabricObject[] }>) => (
		<List>
			{!!props.items.length &&
				props.items.map((item: CustomFabricObject, index) => {
					// @ts-ignore
					return <SortableItem key={index} index={index} item={item} />;
				})}
		</List>
	));

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const callbackFn = (layer: any) => {
			const objectType = layer?.target.get('objectType');

			if (
				objectType === CanvasObjectType.CLIP ||
				objectType === CanvasObjectType.BACKGROUND_IMAGE ||
				objectType === CanvasObjectType.PRODUCT_IMAGE ||
				objectType === CanvasObjectType.BLEED ||
				objectType === CanvasObjectType.GRID ||
				objectType === CanvasObjectType.CLIP_BORDER
			) {
				return;
			}

			updateLayers(canvas, canvasDispatch, activeCanvas.position, editorDispatch, editor.product.placements);
		};

		canvas.on('objects:loaded', callbackFn);
		canvas.on('object:added', callbackFn);
		canvas.on('object:modified', callbackFn);
		canvas.on('object:removed', callbackFn);

		return () => {
			const canvas = activeCanvas?.canvas;

			if (!canvas) return;

			canvas.off('objects:loaded', callbackFn);
			canvas.off('object:added', callbackFn);
			canvas.off('object:modified', callbackFn);
			canvas.off('object:removed', callbackFn);
		};
	}, [canvasDispatch, activeCanvas?.canvas]);

	return (
		<>
			{activeCanvas?.layers.length ? (
				// @ts-ignore
				<SortableList items={activeCanvas.layers} onSortEnd={onSortEnd} useDragHandle />
			) : (
				<div style={{ height: '100%', placeItems: 'center', display: 'grid', textAlign: 'center' }}>
					<div>
						<Text.Subtitle weight="black" className={S.midGreyText}>
							No layers have been added yet
						</Text.Subtitle>
						<Text.Body className={S.midGreyText}>Start by adding a layer via the toolbar</Text.Body>
					</div>
				</div>
			)}
		</>
	);
}
