import { EditorState, EditorAction, EditorActions, EditorStateAction } from '../../types';
import { fonts } from './fonts';

export const editorInitialState = {
	fonts,
	state: EditorStateAction.CREATE,
	error: {
		state: false,
	},
	product: {
		name: '',
		description: '',
		placements: [],
		savePlacements: [],
		basePrice: 0,
		recommendedPrice: 0,
		price: 0,
	},
};

export function EditorReducer(state: EditorState = editorInitialState, action: EditorActions) {
	switch (action.type) {
		case EditorAction.SET_PRODUCT_NAME:
			return {
				...state,
				product: {
					...state.product,
					name: action.payload,
				},
			};
		case EditorAction.SET_PRODUCT_PRICE:
			return {
				...state,
				product: {
					...state.product,
					price: action.payload,
				},
			};
		case EditorAction.SET_PRODUCT_DESCRIPTION:
			return {
				...state,
				product: {
					...state.product,
					description: action.payload,
				},
			};
		case EditorAction.SET_BASE_PRICE:
			return {
				...state,
				product: {
					...state.product,
					basePrice: action.payload,
				},
			};
		case EditorAction.SET_RECOMMENDED_PRICE:
			return {
				...state,
				product: {
					...state.product,
					recommendedPrice: action.payload,
				},
			};
		case EditorAction.SET_PLACEMENTS:
			return {
				...state,
				product: {
					...state.product,
					placements: action.payload,
					savePlacements: action.payload,
				},
			};
		case EditorAction.SET_SAVE_PLACEMENTS:
			return {
				...state,
				product: {
					...state.product,
					savePlacements: action.payload,
				},
			};
		case EditorAction.SET_ERROR_STATE:
			return {
				...state,
				error: action.payload,
			};
		case EditorAction.SET_EDITOR_STATE:
			return {
				...state,
				state: action.payload,
			};
		default:
			throw new Error();
	}
}
