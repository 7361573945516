enum CanvasObjectType {
	SHAPE,
	TEXT,
	IMAGE,
	GROUP,
	SVG,
	CLIP,
	CLIP_BORDER,
	OVERLAY,
	BLEED,
	SNAP,
	PATTERN,
	GRID,
	BACKGROUND_IMAGE,
	PRODUCT_IMAGE,
}

export default CanvasObjectType;
