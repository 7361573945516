import { ScienceEvent } from '../../enums';
import { CanvasObject, CanvasState, UserState, EditorState, EditorStateAction } from '../../types';
import { FieldType } from './';

export function generateFields(
	eventName: string,
	userState: UserState,
	state: CanvasState,
	activeCanvas: CanvasObject,
	editorState: EditorState,
): FieldType {
	const user = userState.user;

	if (!activeCanvas || !user || !state) return [];

	const template = state.template;

	if (!template) return [];

	const selectedThreads = activeCanvas?.selectedThreads?.length || 0;
	const productProfit =
		editorState.state === EditorStateAction.CREATE ? '' : editorState.product.price - editorState.product.basePrice;

	const baseFields = [
		['storeid', user.alias],
		['productid', template.id],
		['product_type', template.name],
		['itemid', state.preloadId],
	] as FieldType;

	const productFields = [
		['base_price', editorState.product.basePrice],
		['product_profit', productProfit],
		['creator_note', !!editorState.product.description],
		['layers_count', activeCanvas.layers.length],
	] as FieldType;

	switch (eventName) {
		case ScienceEvent.SAVE:
		case ScienceEvent.SAVE_AND_PUBLISH:
		case ScienceEvent.SAVE_ITEM:
		case ScienceEvent.REPORT_BUG:
		case ScienceEvent.EXPORT:
		case ScienceEvent.ITEM_GENERATOR:
		case ScienceEvent.EDIT_ITEM:
		case ScienceEvent.VIEW_FULL_PREVIEW:
			return [
				...baseFields,
				...productFields,
				['color_variations_count', state.activeVariants.length],
				['last_product_color', state.selectedVariant || ''],
				['thread_count', selectedThreads],
			];
		case ScienceEvent.ITEM_PREVIEW_START:
		case ScienceEvent.ITEM_PREVIEW_END:
			return [...baseFields, ['thread_count', selectedThreads]];
		case ScienceEvent.PREVIEW_ITEM_CLICK:
			return [...baseFields, ...productFields];
		case ScienceEvent.INFO_BANNER_APPEAR:
		case ScienceEvent.ADD_THREAD:
		case ScienceEvent.REMOVE_THREAD:
			return [...baseFields];
		case ScienceEvent.CHANGE_BACKGROUND_COLOR:
			return [
				...baseFields,
				...productFields,
				['color_variations_count', state.activeVariants.length],
				['last_product_color', state.selectedVariant || ''],
				['target_product_color', ''],
				['thread_count', selectedThreads],
			];
		default:
			return [];
	}
}
