import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function BricksPatternIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <ellipse cx="18.75" cy="5.25" rx="2.25" ry="2.25" transform="rotate(90 18.75 5.25)" />
            <ellipse cx="12" cy="5.25" rx="2.25" ry="2.25" transform="rotate(90 12 5.25)" />
            <ellipse cx="5.25" cy="5.25" rx="2.25" ry="2.25" transform="rotate(90 5.25 5.25)" />
            <circle cx="15.375" cy="12" r="2.25" transform="rotate(90 15.375 12)" />
            <circle cx="8.625" cy="12" r="2.25" transform="rotate(90 8.625 12)" />
            <circle cx="18.75" cy="18.75" r="2.25" transform="rotate(90 18.75 18.75)" />
            <circle cx="12" cy="18.75" r="2.25" transform="rotate(90 12 18.75)" />
            <ellipse cx="5.25" cy="18.75" rx="2.25" ry="2.25" transform="rotate(90 5.25 18.75)" />
        </SvgIcon>
    )
}