import { PropsWithChildren, useCallback } from 'react';
import { Text, css } from '@streamelements/frontend-ui';
import IMAGE_QUALITY from '../../enums/ImageQuality';
import { numberOrZero } from '../../utils/NumberUtils';

const ToolTipText = css({
	variants: {
		quality: {
			high: {
				color: '$uiSuccessMain',
			},
			medium: {
				color: '$uiAttentionMain',
			},
			low: {
				color: '$uiErrorMain',
			},
		},
	},
});

const ImageQuality = [
	{
		name: IMAGE_QUALITY.HIGH,
		label: 'Excellent',
		threshold: (dpi: number) => dpi >= 300,
	},
	{
		name: IMAGE_QUALITY.MEDIUM,
		label: 'Average',
		threshold: (dpi: number) => dpi >= 150 && dpi <= 299,
	},
	{
		name: IMAGE_QUALITY.LOW,
		label: 'Poor',
		threshold: (dpi: number) => dpi <= 149,
	},
];

interface Props {
	dpi?: number;
}

export default function BadImageQualityTooltip({ dpi }: PropsWithChildren<Props>) {
	const getDPILevel = useCallback(
		() => ImageQuality.find((q) => q.threshold(numberOrZero(dpi))) || ImageQuality[0],
		[dpi],
	);

	const GeneralError = () => (
		<>
			<Text.Body weight="bold" variant="caption" className={ToolTipText({ quality: 'low' })}>
				One or more images are in Bad quality
			</Text.Body>
			<br />
			<Text.Body style={{ color: '#fff' }} variant="caption">
				One or more images cannot used at current
				<br />
				size due to low print quality.
				<br />
				Try to scaling down or replacing your images
			</Text.Body>
		</>
	);

	const SpecificError = () => (
		<div>
			<Text.Body weight="bold" variant="caption" className={ToolTipText({ quality: getDPILevel().name })}>
				Image Quality: {getDPILevel().name} ({dpi}dpi)
			</Text.Body>
			<br />
			<Text.Body style={{ color: '#fff' }} variant="caption">
				We DO NOT recommend
				<br />
				using bad quality images.
				<br />
				Try to scaling down or replacing your image
			</Text.Body>
		</div>
	);

	return dpi ? SpecificError() : GeneralError();
}
