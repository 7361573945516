import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function EyeDisabledIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M12.0041 7.26316C14.2633 7.26316 16.0969 9.09053 16.0969 11.3421C16.0969 11.8724 15.9905 12.37 15.8022 12.835L18.1924 15.2171C19.4284 14.1892 20.4025 12.8595 21 11.3421C19.5839 7.76079 16.0887 5.22368 11.9959 5.22368C10.8499 5.22368 9.75307 5.42763 8.73806 5.79474L10.5061 7.55684C10.9727 7.36921 11.472 7.26316 12.0041 7.26316ZM3.81855 5.03605L5.68486 6.89605L6.06139 7.27132C4.70259 8.32368 3.63847 9.72684 3 11.3421C4.4161 14.9234 7.91132 17.4605 12.0041 17.4605C13.2729 17.4605 14.4843 17.2158 15.5894 16.7753L15.9332 17.1179L18.3315 19.5L19.3711 18.4639L4.85812 4L3.81855 5.03605ZM8.34516 9.54737L9.61392 10.8118C9.57299 10.9832 9.54843 11.1626 9.54843 11.3421C9.54843 12.6963 10.6453 13.7895 12.0041 13.7895C12.1842 13.7895 12.3643 13.765 12.5362 13.7242L13.8049 14.9887C13.2565 15.2579 12.6508 15.4211 12.0041 15.4211C9.74488 15.4211 7.91132 13.5937 7.91132 11.3421C7.91132 10.6976 8.07503 10.0939 8.34516 9.54737V9.54737ZM11.8731 8.91105L14.4516 11.4808L14.4679 11.3503C14.4679 9.99605 13.3711 8.90289 12.0123 8.90289L11.8731 8.91105Z" />
        </SvgIcon>
    )
}