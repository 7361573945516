import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useActiveCanvas } from '..';
import { ScienceEvent, ScienceSource } from '../../enums';
import { CanvasesContext, EditorsContext, UserContext } from '../../store';
import { generateFields } from './generateFields';

export type FieldType = [string, string | number | boolean][];

interface EventState {
	name: ScienceEvent;
	source: ScienceSource;
	placement?: string;
	fields?: [string, string | number | boolean][];
	prefix?: string;
	feature?: string;
}

export function useScience() {
	const { activeCanvas } = useActiveCanvas();
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: userState } = useContext(UserContext);
	const { state: editorState } = useContext(EditorsContext);
	const [alchemy, setAlchemy] = useState<any>();
	const [alchemyLoaded, setAlchemyLoaded] = useState<boolean>(false);
	const [queue, setQueue] = useState<EventState[]>([]);

	function getScienceId() {
		return Cookies.get('se-science-id');
	}

	function combineFields(fields: FieldType, providedFields: FieldType): FieldType {
		const combined = fields.map((field) => {
			let updatedField = field;

			providedFields.forEach((pfield) => {
				if (field[0] === pfield[0]) {
					updatedField = [field[0], pfield[1]];
				}
			});

			return updatedField;
		});

		return combined;
	}

	const trackEvent = useCallback(
		({ name, placement = '', source, feature = 'editor', fields = [], prefix }: EventState) => {
			if (!canvasState || !userState || !activeCanvas) return;

			const event = {
				product: 'merch',
				name,
				placement,
				source,
				feature,
				prefix,
				fields: [...combineFields(generateFields(name, userState, canvasState, activeCanvas, editorState), fields)],
			};

			if (!alchemy) {
				setQueue((prevQueue) => [...prevQueue, event]);

				return;
			}

			try {
				alchemy.track(event);
			} catch (e) {
				console.warn(e, 'Failed firing science event');
			}
		},
		[alchemy, activeCanvas, editorState, userState, canvasState],
	);

	const runQueue = useCallback(() => {
		if (!queue.length) return;

		queue.forEach(({ name, placement, fields, source, feature, prefix }) => {
			trackEvent({ name, placement, fields, source, feature, prefix });
		});

		setQueue([]);
	}, [queue, trackEvent]);

	useEffect(() => {
		if (!alchemyLoaded) {
			setAlchemyLoaded(true);

			import('@streamelements/alchemy').then((a) => {
				a.init();
				a.identify({ channelId: userState.user?._id || '' });

				setAlchemy(a);
				runQueue();
			});
		}
	}, [alchemyLoaded, runQueue, userState.user?._id]);

	useEffect(() => {
		!!alchemy && runQueue();
	}, [alchemy, runQueue]);

	return { trackEvent, getScienceId, runQueue };
}
