import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function FullDropPatternIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M3 5.5C3 6.60457 3.89543 7.5 5 7.5H5.5C6.60457 7.5 7.5 6.60457 7.5 5.5V5C7.5 3.89543 6.60457 3 5.5 3H5C3.89543 3 3 3.89543 3 5V5.5ZM9.75 19C9.75 20.1046 10.6454 21 11.75 21H12.25C13.3546 21 14.25 20.1046 14.25 19V18.5C14.25 17.3954 13.3546 16.5 12.25 16.5H11.75C10.6454 16.5 9.75 17.3954 9.75 18.5V19ZM3 19C3 20.1046 3.89543 21 5 21H5.5C6.60457 21 7.5 20.1046 7.5 19V18.5C7.5 17.3954 6.60457 16.5 5.5 16.5H5C3.89543 16.5 3 17.3954 3 18.5V19ZM3 12.25C3 13.3546 3.89543 14.25 5 14.25H5.5C6.60457 14.25 7.5 13.3546 7.5 12.25V11.75C7.5 10.6454 6.60457 9.75 5.5 9.75H5C3.89543 9.75 3 10.6454 3 11.75V12.25ZM9.75 12.25C9.75 13.3546 10.6454 14.25 11.75 14.25H12.25C13.3546 14.25 14.25 13.3546 14.25 12.25V11.75C14.25 10.6454 13.3546 9.75 12.25 9.75H11.75C10.6454 9.75 9.75 10.6454 9.75 11.75V12.25ZM18.5 3C17.3954 3 16.5 3.89543 16.5 5V5.5C16.5 6.60457 17.3954 7.5 18.5 7.5H19C20.1046 7.5 21 6.60457 21 5.5V5C21 3.89543 20.1046 3 19 3H18.5ZM9.75 5.5C9.75 6.60457 10.6454 7.5 11.75 7.5H12.25C13.3546 7.5 14.25 6.60457 14.25 5.5V5C14.25 3.89543 13.3546 3 12.25 3H11.75C10.6454 3 9.75 3.89543 9.75 5V5.5ZM16.5 12.25C16.5 13.3546 17.3954 14.25 18.5 14.25H19C20.1046 14.25 21 13.3546 21 12.25V11.75C21 10.6454 20.1046 9.75 19 9.75H18.5C17.3954 9.75 16.5 10.6454 16.5 11.75V12.25ZM16.5 19C16.5 20.1046 17.3954 21 18.5 21H19C20.1046 21 21 20.1046 21 19V18.5C21 17.3954 20.1046 16.5 19 16.5H18.5C17.3954 16.5 16.5 17.3954 16.5 18.5V19Z" />
        </SvgIcon>
    )
}