import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function InvertIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M11.744 5.32805V19.064C8.53993 19.064 5.936 16.5182 5.936 13.3818C5.936 11.8717 6.53617 10.4488 7.63001 9.37429L11.744 5.32805ZM6.2748 7.99005C4.8712 9.37429 4 11.2813 4 13.3818C4 17.5926 7.46545 21 11.744 21C16.0226 21 19.488 17.5926 19.488 13.3818C19.488 11.2813 18.6168 9.37429 17.2132 7.99005L12.4216 3.27588C12.0441 2.90804 11.4439 2.90804 11.0664 3.27588L6.2748 7.99005Z" />
        </SvgIcon>
    )
}