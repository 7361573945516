import { TemplateState, TemplateAction, TemplateActions } from '../../types';

export const templatesInitialState = {
	list: [],
	error: false,
	loading: false,
};

export function TemplatesReducer(state: TemplateState = templatesInitialState, action: TemplateActions) {
	switch (action.type) {
		case TemplateAction.GET_TEMPLATES:
			return {
				...state,
				loading: true,
			};
		case TemplateAction.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				list: action.payload,
				loading: false,
			};
		case TemplateAction.GET_TEMPLATES_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		default:
			throw new Error();
	}
}
