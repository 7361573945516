import { Button } from '@streamelements/frontend-ui';
import { ActionBlock, ActionVerticalDivider, mediumIcon, smallIconButton } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import {
	AlignHorizontalLeft,
	AlignHorizontalMiddle,
	AlignHorizontalRight,
	AlignVerticalBottom,
	AlignVerticalMiddle,
	AlignVerticalTop,
} from '../../store/ActionCreators/ObjectAlignActionCreator';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import CustomFabricObject from '../../types/CustomFabricObject';
import {
	FlipVerticalIcon,
	FlipHorizontalIcon,
	AlignVerticalCenterIcon,
	AlignTopIcon,
	AlignRightIcon,
	AlignLeftIcon,
	AlignHorizontalCenterIcon,
	AlignBottomIcon,
} from '../Icons';

export default function GroupController() {
	const { activeCanvas } = useActiveCanvas();

	if (!activeCanvas || !activeCanvas.canvas) return null;

	const activeObject = activeCanvas.canvas.getActiveObject() as CustomFabricObject;

	return activeCanvas.selectedObject && activeObject.get('objectType') === CanvasObjectType.GROUP ? (
		<>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignHorizontalLeft(activeCanvas)}
					className={smallIconButton}
				>
					<AlignLeftIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignHorizontalRight(activeCanvas)}
					className={smallIconButton}
				>
					<AlignRightIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignVerticalTop(activeCanvas)}
					className={smallIconButton}
				>
					<AlignTopIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignVerticalBottom(activeCanvas)}
					className={smallIconButton}
				>
					<AlignBottomIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignVerticalMiddle(activeCanvas)}
					className={smallIconButton}
				>
					<AlignVerticalCenterIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => AlignHorizontalMiddle(activeCanvas)}
					className={smallIconButton}
				>
					<AlignHorizontalCenterIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionVerticalDivider />
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => setObjProp(activeCanvas, 'flipX', !activeCanvas?.canvas?.getActiveObject().get('flipX'))}
					className={smallIconButton}
				>
					<FlipHorizontalIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => setObjProp(activeCanvas, 'flipY', !activeCanvas?.canvas?.getActiveObject().get('flipY'))}
					className={smallIconButton}
				>
					<FlipVerticalIcon className={mediumIcon} />
				</Button>
			</ActionBlock>
		</>
	) : null;
}
