import { createRef, useCallback, useEffect, useState } from 'react';
import { TextController, ShapeController } from '.';
import { FloatingActionsBar } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import { CustomFabricObject } from '../../types';
import GroupController from './GroupController';
import ImageController from './ImageController';

const POPUP_OFFSET = 60;
const POPUP_HEIGHT = 50;

export function ObjectFloatController() {
	const { activeCanvas } = useActiveCanvas();
	const floaterRef = createRef<HTMLDivElement>();
	const [top, setTop] = useState(0);
	const [left, setLeft] = useState(0);
	const [type, setType] = useState<CanvasObjectType | null>();

	const repositionObjectController = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas || !activeCanvas?.selectedObject || !canvas.viewportTransform) return;

		const selectedObject = canvas.getActiveObject();

		if (!selectedObject) return;

		selectedObject.setCoords();
		const canvasRect = canvas.getElement().getBoundingClientRect();
		const objRect = selectedObject.getBoundingRect();
		const objHalfWidth = objRect.width / 2;

		let calcedLeft = objRect.left + canvasRect.left;
		let calcedHeight = POPUP_HEIGHT;

		if (floaterRef.current) {
			const floaterBounds = floaterRef.current.getBoundingClientRect();
			const floaterCenter = floaterBounds.width / 2;
			const docBounds = document.documentElement.getBoundingClientRect();
			calcedLeft = calcedLeft - floaterCenter + objHalfWidth;

			const floaterRight = floaterBounds.width + calcedLeft;

			if (floaterRight > docBounds.width) {
				calcedLeft = calcedLeft - (floaterRight - docBounds.width);
			}

			calcedHeight = floaterBounds.height;
		}

		const calcedTop = objRect.top + canvasRect.top - POPUP_OFFSET - calcedHeight;

		if (calcedTop <= 0) {
			setTop(-calcedTop);
		} else {
			setTop(calcedTop);
		}

		setLeft(calcedLeft);
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject, floaterRef]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const activeobject = canvas?.getActiveObject() as CustomFabricObject;

		activeobject && setType(activeobject.get('objectType'));
		repositionObjectController();
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject, repositionObjectController]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		canvas.on('object:moved', repositionObjectController);
		canvas.on('object:rotated', repositionObjectController);
		canvas.on('object:scaled', repositionObjectController);
		canvas.on('canvas:zooming', repositionObjectController);
		window.addEventListener('resize', repositionObjectController);

		return () => {
			const canvas = activeCanvas.canvas;

			if (!canvas) return;

			canvas.off('object:moved', repositionObjectController);
			canvas.off('object:scaled', repositionObjectController);
			canvas.off('object:rotated', repositionObjectController);
			canvas.off('canvas:zooming', repositionObjectController);
			window.removeEventListener('resize', repositionObjectController);
		};
	}, [activeCanvas?.canvas, repositionObjectController]);

	const onClick = () => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const activeObject: CustomFabricObject | undefined = canvas?.getActiveObject();

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		activeObject?.exitEditing && activeObject?.exitEditing();
	};

	return activeCanvas?.selectedObject ? (
		<FloatingActionsBar ref={floaterRef} style={{ top, left }} onClick={onClick}>
			{type === CanvasObjectType.TEXT && <TextController />}
			{type === CanvasObjectType.SHAPE && <ShapeController />}
			{(type === CanvasObjectType.IMAGE || type === CanvasObjectType.PATTERN) && <ImageController />}
			{type === CanvasObjectType.SVG && <ImageController />}
			{type === CanvasObjectType.GROUP && <GroupController />}
		</FloatingActionsBar>
	) : null;
}
