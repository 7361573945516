import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function HalfDropPatternIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <circle cx="5.25" cy="5.25" r="2.25" />
            <ellipse cx="5.25" cy="12" rx="2.25" ry="2.25" />
            <ellipse cx="5.25" cy="18.75" rx="2.25" ry="2.25" />
            <ellipse cx="12" cy="8.625" rx="2.25" ry="2.25" />
            <ellipse cx="12" cy="15.375" rx="2.25" ry="2.25" />
            <ellipse cx="18.75" cy="5.25" rx="2.25" ry="2.25" />
            <ellipse cx="18.75" cy="12" rx="2.25" ry="2.25" />
            <circle cx="18.75" cy="18.75" r="2.25" />
        </SvgIcon>
    )
}