export type TemplateData = {
	backgroundColor: string;
	backgroundUrl: string;
	imageUrl: string;
	isImageOverlay: boolean;
	bleedPaddingBottom: number;
	bleedPaddingLeft: number;
	bleedPaddingRight: number;
	bleedPaddingTop: number;
	printAreaHeight: number;
	printAreaWidth: number;
	printAreaTop: number;
	printAreaLeft: number;
	templateHeight: number;
	templateWidth: number;
};

export interface ActiveVariantTemplateData extends TemplateData {
	invertedColor: string;
}

export interface TemplateVariant {
	setAssetBackground: any;
	bootstrap: boolean;
	color: string;
	colorCodes: string[];
	id: string;
	image: string;
	name: string;
	size: string;
	previewId: string;
	vendor: string;
	placementKeys: string[];
	placementFiles: {
		[key: string]: {
			id: string;
			height: number;
			width: number;
			dpi: number;
		};
	};
	templates: {
		[key: string]: TemplateData;
	};
}

export interface VendorPlacements {
	[key: string]: {
		additionalPrice: number;
		title: string;
		type: string;
		options: {
			flat: {
				threadColors: {
					[key: string]: string;
				};
			};
		};
	};
}

export interface Template {
	basePrice: number;
	id: string;
	name: string;
	productThumbnail: string;
	productType: string;
	recommendedPrice: number;
	vendor: string;
	vendorProductId: string;
	variants: TemplateVariant[];
	description: string;
	vendorPlacements: VendorPlacements;
}

export interface TemplateState {
	list: Template[];
	loading: boolean;
	error: boolean;
}

export enum TemplateAction {
	GET_TEMPLATES = 'GET_TEMPLATES',
	GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS',
	GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR',
}

export type TemplateActions =
	| { type: TemplateAction.GET_TEMPLATES }
	| { type: TemplateAction.GET_TEMPLATES_SUCCESS; payload: Template[] }
	| { type: TemplateAction.GET_TEMPLATES_ERROR };
