import { Text } from '@streamelements/frontend-ui';
import { truncate } from 'lodash';
import { MouseEvent, PropsWithChildren, useState } from 'react';
import { ImageItem, DownloadIconContainer, ItemDetails, ItemContainer, ItemTransitionBackground, DeleteIconContainer } from './AssetManager.style';
import { formatDistance } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import { DownloadIcon, TrashCanIcon } from '../Icons';
import { whiteIcon } from '../../Editor.style';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface AssetItemsProps {
    itemId: string,
    backgroundImage: string;
    img?: string,
    onClick: (bgImage: string, fileName?: string) => void,
    name: string,
    size: number,
    type: string,
    createdAt?: string,
    onDelete?: (e: MouseEvent<HTMLDivElement>, assetId: string) => void,
    onDownload?: (e: MouseEvent<HTMLDivElement>, assetId: string) => void,
    bundle?: string,
    thumbnail?: string
}

export default function AssetItem(props: PropsWithChildren<AssetItemsProps>) {
    const [hover, setHover] = useState<boolean>(false);
    const fileExt = props.type.split('/');

    return (
        <ItemContainer
            onMouseOut={() => setHover(false)}
            onMouseOver={() => setHover(true)}
            onClick={() => props.onClick(props.img || props.backgroundImage, props.name)}
        >
            <ItemTransitionBackground hover={hover} />
            <ImageItem>
                <LazyLoadImage
                    effect='blur'
                    alt={props.name}
                    height={260}
                    src={props.thumbnail || props.backgroundImage}
                    width={260}
                />
            </ImageItem>
            <ItemDetails hover={hover}>
                <Text.Subtitle weight='bold' style={{ color: '#fff' }}>{truncate(props.name, { length: 30 })}</Text.Subtitle>
                {props.createdAt ? <Text.Body style={{ color: 'rgba(255, 255, 255, 0.7)' }}>Uploaded {formatDistance(new Date(props.createdAt), new Date(), { addSuffix: true })}</Text.Body> : <div />}
                <div style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: 'max-content', columnGap: '8px' }}>
                    {props.bundle ? (
                        <Text.Body style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{props.bundle}</Text.Body>
                    ) : (
                        <>
                            <Text.Body style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{fileExt[1].toUpperCase()}</Text.Body>
                            <Text.Body style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{prettyBytes(props.size)}</Text.Body>
                        </>
                    )}
                </div>
                {props.onDelete && (
                    <DeleteIconContainer onClick={(e: MouseEvent<HTMLDivElement>) => props.onDelete && props.onDelete(e, props.itemId)}>
                        <TrashCanIcon className={whiteIcon} />
                    </DeleteIconContainer>
                )}

                {props.onDownload && (
                    <DownloadIconContainer onClick={(e: MouseEvent<HTMLDivElement>) => props.onDownload && props.onDownload(e, props.itemId)}>
                        <DownloadIcon className={whiteIcon} />
                    </DownloadIconContainer>
                )}
            </ItemDetails>
        </ItemContainer>
    );
}