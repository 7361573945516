import { Modal, styled, css } from "@streamelements/frontend-ui";

export const ModalContainer = styled(Modal.Content, {
    height: 700,
    width: 1144,
    display: 'grid',
    gridTemplateRows: '163px 1fr',
    overflow: 'hidden'
});

export const Toolbar = styled('div', {
    display: 'grid',
    gridTemplateRows: '2fr max-content'
});

export const ToolbarActions = styled('div', {
    display: 'grid',
    alignItems: 'center',
    columnGap: '$base',
    padding: 'calc($base * 3)',
    gridTemplateColumns: 'max-content 1fr max-content max-content'
});

export const ToolbarFilters = styled('div', {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    justifyContent: 'space-between',
    py: 'calc($base * 2)',
    px: 'calc($base * 3)',
});

export const Content = styled('div', {
    backgroundColor: '$uiSecondary',
    overflow: 'auto',
});

export const ContentInner = styled('div', {
    margin: 'calc($base * 3)',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 'calc($base * 2) calc($base * 2)',
    minHeight: 'calc(100% - calc($space$base * 6))',
    position: 'relative',

    variants: {
        draggingState: {
            true: {
                gridTemplateColumns: '1fr'
            }
        }
    }
});

export const ItemDetails = styled('div', {
    height: 55,
    position: 'absolute',
    padding: '$base',
    color: '#fff',
    bottom: -71,
    left: 0,
    right: 0,
    transition: 'bottom 0.2s ease-in-out',

    variants: {
        hover: {
            true: {
                bottom: 0,
            }
        }
    }
});

export const ItemContainer = styled('div', {
    width: 260,
    height: 260,
    borderRadius: '$base',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
});

export const ItemTransitionBackground = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 260,
    height: 260,
    background: 'rgba(0,0,0,0.5)',
    opacity: 0,
    transition: 'all 0.2s ease-in-out',

    variants: {
        hover: {
            true: {
                opacity: 1,
            }
        }
    }
})

export const Item = styled('div', {
    width: 260,
    height: 260,
    borderRadius: '$base',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden',
    transition: 'box-shadow 0.2s ease-in-out',
});

export const ImageItem = styled('div', {
    width: 260,
    height: 260,
    transition: 'box-shadow 0.2s ease-in-out',
    borderRadius: '$base',
    overflow: 'hidden',

    'img': {
        width: 260,
        height: 260,
        objectFit: 'contain',
    }
});

export const SortByWrapper = styled('div', {
    width: 256,
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content 1fr',
    columnGap: '$base',
    alignItems: 'center'
});

export const DragAndDropContainer = styled('div', {
    background: 'url(/images/dndBackground.png)',
    height: '100%',
    width: '100%',
    border: '3px dashed $uiPrimaryMain',
    borderRadius: 'calc($base * 2)',
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',

    variants: {
        dragOver: {
            true: {
                background: 'url(/images/dndBackgroundOver.png)',
            }
        }
    }
});

export const primaryText = css({
    color: '$uiPrimaryMain'
})();

export const DeleteIconContainer = styled('div', {
    position: 'absolute',
    bottom: '$base',
    right: '$base',
    cursor: 'pointer'
});

export const DownloadIconContainer = styled('div', {
    position: 'absolute',
    top: '$base',
    right: '$base',
    cursor: 'pointer'
})

export const uploadIconStyle = css({
    fontSize: '5rem !important'
})();