import { useEffect, useState } from 'react';
import { ActionBlock, ActionTitle, lightGreyText, smallIcon, tinyIconButton } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import CustomFabricObject from '../../types/CustomFabricObject';
import { TextAlignRightIcon, TextAlignCenterIcon, TextAlignLeftIcon } from '../Icons';
import ThemedIconButton from '../ThemedIconButton';

export default function TextFormatController() {
	const { activeCanvas } = useActiveCanvas();
	const [value, setValue] = useState<string>();

	const canvas = activeCanvas?.canvas;

	useEffect(() => {
		const activeObject = canvas?.getActiveObject() as CustomFabricObject;

		if (activeObject) setValue(activeObject.get('textAlign') || 'left');
	}, [activeCanvas]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'textAlign', value || '');
		}
	}, [value]);

	return (
		<>
			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					FORMAT
				</ActionTitle>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<ThemedIconButton
					variant="ghost"
					color="neutral"
					iconButton
					className={tinyIconButton}
					onClick={() => setValue('left')}
				>
					<TextAlignLeftIcon className={smallIcon} />
				</ThemedIconButton>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<ThemedIconButton
					variant="ghost"
					color="neutral"
					iconButton
					className={tinyIconButton}
					onClick={() => setValue('center')}
				>
					<TextAlignCenterIcon className={smallIcon} />
				</ThemedIconButton>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<ThemedIconButton
					variant="ghost"
					color="neutral"
					iconButton
					className={tinyIconButton}
					onClick={() => setValue('right')}
				>
					<TextAlignRightIcon className={smallIcon} />
				</ThemedIconButton>
			</ActionBlock>
		</>
	);
}
