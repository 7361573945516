import { ProductTypes, Clip, CanvasObject } from '../../types';
import { ExportButton } from './ExportButton';
import { PreviewButton } from './PreviewButton';
import { SaveButton } from './SaveButton';

export type SaveData = {
	onSave: () => void;
	disabled: boolean;
	placements: string[];
};

interface ProductActionsState {
	isEmbroidery: boolean;
	isMultiside: boolean;
	productType: string;
	canvasObject: CanvasObject;
	clip: Clip | undefined;
	saveData: SaveData;
}

export function ProductActions({ isEmbroidery, productType, canvasObject, clip, saveData }: ProductActionsState) {
	const showPreview = productType === ProductTypes.MUG && clip;

	return (
		<>
			<ExportButton canvasObject={canvasObject} />
			{showPreview && <PreviewButton canvasObject={canvasObject} />}
			<SaveButton isEmbroidery={isEmbroidery} saveData={saveData} />
		</>
	);
}
