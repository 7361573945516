import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { styled } from '@streamelements/frontend-ui';
import { Editor } from './Editor';
import { NoMobileSupportOverlay, Header } from './components';
import UrlMap from './config/UrlMap';
import { UserProvider, TemplatesProvider, CanvasesProvider, EditorsProvider } from './store';

const Container = styled('div', {
	display: 'grid',
	height: 'calc(100% - calc($space$base * 4))',
	gridTemplateRows: '60px 1fr',
	backgroundColor: '#FFFFFF',
	rowGap: '$base',
	padding: 'calc($base * 3)',
	pt: '$base',
});

export function ProductEditor() {
	const isMobile = window.matchMedia('(max-width: 1023px)').matches;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.process = {
			...window.process,
		};

		// if (!Cookies.get('se-token')) window.location.href = `${UrlMap.Base}/auth/twitch/?return=${window.location.href}`;
	}, []);

	return (
		<TemplatesProvider>
			<UserProvider>
				<CanvasesProvider>
					<EditorsProvider>
						<Container>
							<Header />
							{isMobile ? <NoMobileSupportOverlay /> : <Editor />}
						</Container>
					</EditorsProvider>
				</CanvasesProvider>
			</UserProvider>
		</TemplatesProvider>
	);
}
