import { useEffect, useState, useRef } from 'react';
import { Modal, CircularProgress } from '@streamelements/frontend-ui';
import { LoadingModalContent } from '../Editor.style';

export function Loader() {
	const [progress, setProgress] = useState<number>(0);
	const interval = useRef<NodeJS.Timeout>();

	useEffect(() => {
		interval.current = setInterval(() => {
			setProgress((p) => p + 1);
		}, 100);

		return () => {
			if (interval.current) clearInterval(interval.current);
		};
	}, []);

	return (
		<Modal.Root open={true}>
			<Modal.Overlay />
			<LoadingModalContent>
				<CircularProgress.Root value={progress} />
			</LoadingModalContent>
		</Modal.Root>
	);
}
