import UrlMap, { token, decodeToken } from '../config/UrlMap';
import { PreviewData, ProductsResponse, CreateNewItemRequest, StoreResponse } from '../types';

const MERCH_CATALOG_URL = UrlMap.MerchCatalog;

/**
 * async fetch request to POST an embroidery preview.
 *
 * @param   {File | Blob} file - The file to generate a preview for
 * @param   {string} channelId - users channel id
 * @param   {PreviewData} data - Generate preview params
 *
 * @returns {Promise<{ previewId: string }>} returns a previewId for use in realtime event
 */
export const generatePreview = async (file: File | Blob, placement: string, channelId: string, data: PreviewData) => {
	const body: BodyInit = new FormData();

	body.append('json', JSON.stringify(data));
	body.append(placement, file, 'graphic.png');

	const preview = await fetch(`${MERCH_CATALOG_URL}/stores/${channelId}/generatePreview`, {
		body,
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!preview.ok) {
		return preview;
	}

	const res = await preview.json();

	return res;
};

/**
 * async fetch request to GET available templates
 *
 * @returns {Promise<Store>} returns a list of available templates for user
 */
export const getTemplates = async (): Promise<ProductsResponse> => {
	const templates = await fetch(`${MERCH_CATALOG_URL}/catalog/products`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!templates.ok) {
		throw new Error('There was a problem fetching available templates');
	}

	const res = await templates.json();

	return res;
};

/**
 * async fetch request to save a product
 *
 * @param   {string} channelId - users channel id
 * @param   {PreviewData} data - Generate preview params
 *
 * @returns {Promise<{}>} returns empty response
 */
export const saveProduct = async (channelId: string, data: CreateNewItemRequest) => {
	const product = await fetch(`${MERCH_CATALOG_URL}/stores/${channelId}/items`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return product;
};

/**
 * async fetch request to save a product
 *
 * @param   {string} channelId - users channel id
 * @param   {PreviewData} data - Generate preview params
 * @param   {PreviewData} itemId - Item id we are updating
 *
 * @returns {Promise<{}>} returns empty response
 */
export const updateProduct = async (channelId: string, data: CreateNewItemRequest, itemId: string) => {
	const product = await fetch(`${MERCH_CATALOG_URL}/stores/${channelId}/items/${itemId}`, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return product;
};

/**
 * async fetch request to get the users store data
 *
 * @param   {string} channelId - users channel id
 *
 * @returns {Promise<StoreResponse>} returns a promise with the StoreResponse data
 */
export const getStore = async (): Promise<StoreResponse> => {
	const decodedToken = decodeToken();

	if (!decodedToken) return Promise.reject('No channelId could be decoded');

	const store = await fetch(`${MERCH_CATALOG_URL}/stores/${decodedToken.channelId}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	const res = (await store.json()) || {};

	return res;
};
