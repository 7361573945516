import { useState } from 'react';
import { Button, Text, styled } from '@streamelements/frontend-ui';
import { ScienceEvent, ScienceSource } from '../../enums';
import { useScience } from '../../hooks';
import { SaveData } from './ProductActions';

interface SaveButtonProps {
	isEmbroidery: boolean;
	saveData: SaveData;
}

const SaveButtonWrap = styled('div', {
	position: 'relative',
});

const SaveErrorTooltip = styled('span', {
	position: 'absolute',
	top: '2.75rem',
	right: 0,
	width: '225px',
	marginTop: '$base',
	background: '$uiErrorMain',
	boxShadow: '$2',
	padding: 'calc($base * 2) calc($base * 4)',
	borderRadius: '$base',

	'&:before': {
		content: '',
		position: 'absolute',
		top: '-3px',
		right: '2.3rem',
		width: '20px',
		height: '20px',
		borderRadius: '2px',
		transform: 'rotate(45deg)',
		background: '$uiErrorMain',
	},

	'p, h6': {
		color: '#FFFFFF',
	},

	h6: {
		marginBottom: '$base',
	},
});

const errors = {
	noLayersTitle: 'Product is empty',
	noLayersMessage: 'To save or preview your product please add a design within the dashed area.',
	badImageTitle: 'Bad image quality',
	badImageMessage:
		'One or more of your images quality is not sufficient for print, try uploading a higher resolution image.',
};

export function SaveButton({ isEmbroidery, saveData }: SaveButtonProps) {
	const [isHovered, setIsHovered] = useState(false);
	const { onSave, disabled, placements } = saveData;
	const { trackEvent } = useScience();

	function onClick() {
		if (isEmbroidery) {
			trackEvent({
				name: ScienceEvent.ITEM_GENERATOR,
				source: ScienceSource.EDITOR_PANEL,
				placement: 'preview_button',
			});
		}

		onSave();
	}

	return (
		<SaveButtonWrap onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Button color="primary" disabled={disabled} onClick={() => onClick()}>
				{isEmbroidery ? 'Preview' : 'Next'}
			</Button>

			{isHovered && disabled && (
				<SaveErrorTooltip>
					<Text.Subtitle weight="bold">
						{placements.length === 0 ? errors.noLayersTitle : errors.badImageTitle}
					</Text.Subtitle>
					<Text.Body>{placements.length === 0 ? errors.noLayersMessage : errors.badImageMessage}</Text.Body>
				</SaveErrorTooltip>
			)}
		</SaveButtonWrap>
	);
}
