export const fonts = [
	'Arial',
	'Bungee',
	'Share Tech Mono',
	'Righteous',
	'Squada One',
	'Knewave',
	'Oswald',
	'Quicksand',
	'Josefin Sans',
	'Questrial',
	'Fredoka One',
	'Play',
	'Carter One',
	'Kaushan Script',
	'Russo One',
	'Orbitron',
	'Fugaz One',
	'Aldrich',
	'Atomic Age',
	'Ruge Boogy',
	'Lobster',
	'Architects Daughter',
	'Futura',
];
