import { fabric } from 'fabric';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@streamelements/frontend-ui';
import { ColorCircle } from '../..';
import { useActiveCanvas, useActiveVariant, useTemplate } from '../../../hooks';
import { CanvasesContext } from '../../../store';
import {
	CanvasAction,
	CustomFabricImage,
	CustomFabricObject,
	CanvasObjectType,
	ActiveVariantTemplateData,
} from '../../../types';
import { numberOrZero } from '../../../utils/NumberUtils';
import { PrintSideSelector } from './PrintSideSelector';
import {
	generateClipPath,
	generateClipBorder,
	generateSnappingLines,
	generateGrid,
	generateBleed,
	anonymousLayerProperties,
} from './generateCanvasElements';
import { generateSnappingLineCoords, generateBleedCoords } from './utils';

const ProductColorsContainer = styled('div', {
	display: 'flex',
	gap: '12px',
	justifyContent: 'center',
});

const VariantSelectorInner = styled('div', {
	display: 'grid',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	padding: '0 20px',
	br: 'calc($base * 2)',
	justifySelf: 'end',
	alignSelf: 'end',
	placeItems: 'center',
	boxShadow: '$2',
	border: '1px solid rgba(0, 0, 0, 0.1)',
	height: '60px',
});

const VariantSelectorContainer = styled('div', {
	bottom: 'calc($base * 3)',
	position: 'absolute',
	width: '100%',
	zIndex: 5,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	pointerEvents: 'none',
});

const Divider = styled('span', {
	display: 'block',
	width: 1,
	height: '100%',
	background: 'rgba(0, 0, 0, 0.1)',
	margin: '0 1.25rem',
});

export function BackgroundImageController() {
	const [initialLoad, setInitialLoad] = useState(false);
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { activeCanvas } = useActiveCanvas();
	const { template } = useTemplate();
	const { activeVariant, activeTemplateData } = useActiveVariant();

	function createBackgroundComposition(activeTemplateData: ActiveVariantTemplateData) {
		if (!activeCanvas) return null;

		canvasState.canvases.map((canvasObj) => {
			const { canvas, clip, bleed, height, width, position } = canvasObj;

			if (!canvas || !template || !activeVariant || (initialLoad && position !== activeCanvas.position)) return;

			const { backgroundUrl, imageUrl, backgroundColor, invertedColor } = activeTemplateData;

			fabric.util.loadImage(
				imageUrl,
				function (imageUrlLoaded) {
					fabric.util.loadImage(
						backgroundUrl,
						function (backgroundUrlLoaded) {
							const canvasObjects = canvas?.getObjects() as CustomFabricObject[];
							const templateImageLayer = canvasObjects.filter(
								(o) => o.get('objectType') === CanvasObjectType.PRODUCT_IMAGE,
							) as CustomFabricImage[];
							const backgroundLayer = canvasObjects.filter(
								(o) => o.get('objectType') === CanvasObjectType.BACKGROUND_IMAGE,
							) as CustomFabricImage[];

							const img = !templateImageLayer.length
								? (new fabric.Image(imageUrlLoaded || backgroundUrlLoaded) as CustomFabricImage)
								: (templateImageLayer[0] as CustomFabricImage);

							const backgroundImg =
								imageUrlLoaded && backgroundUrlLoaded
									? !backgroundLayer.length
										? (new fabric.Image(backgroundUrlLoaded) as CustomFabricImage)
										: (backgroundLayer[0] as CustomFabricImage)
									: null;

							const { width: cwidth = 1, height: cheight = 1 } = canvas;
							const { width: iwidth = 1, height: iheight = 1 } = imageUrlLoaded || backgroundUrlLoaded;
							const imageScaleRatio = Math.min(cwidth / iwidth, cheight / iheight);
							const imageProperties = {
								scaleX: imageScaleRatio,
								scaleY: imageScaleRatio,
								...anonymousLayerProperties,
							};

							if (!templateImageLayer.length) {
								canvas.add(
									img.set({
										...imageProperties,
										objectType: CanvasObjectType.PRODUCT_IMAGE,
									}),
								);
							}

							if (!backgroundLayer.length && backgroundImg) {
								canvas.add(
									backgroundImg.set({
										...imageProperties,
										objectType: CanvasObjectType.BACKGROUND_IMAGE,
									}),
								);
							}

							if (
								templateImageLayer.length &&
								templateImageLayer[0].getSrc() !== (imageUrlLoaded ? imageUrl : backgroundUrl)
							) {
								templateImageLayer[0].set({ ...imageProperties });
								templateImageLayer[0].setSrc(imageUrlLoaded ? imageUrl : backgroundUrl, () => canvas.renderAll(), {
									crossOrigin: 'anonymous',
								});
							}

							if (
								imageUrlLoaded &&
								backgroundUrlLoaded &&
								backgroundLayer.length &&
								backgroundLayer[0].getSrc() !== backgroundUrl
							) {
								backgroundLayer[0].set({ ...imageProperties });
								backgroundLayer[0].setSrc(backgroundUrl, () => canvas.renderAll(), { crossOrigin: 'anonymous' });
							}

							if (!backgroundUrlLoaded && backgroundLayer.length) {
								canvas.remove(backgroundLayer[0]);
							}

							if (clip) {
								const clipScaleRatio = Math.max(
									numberOrZero(cwidth) / numberOrZero(width),
									numberOrZero(cheight) / numberOrZero(height),
								);

								const clipBounds = {
									height: clip.height * clipScaleRatio,
									width: clip.width * clipScaleRatio,
									top: clip.top * clipScaleRatio,
									left: clip.left * clipScaleRatio,
								};

								/** Create and set grid layer if it doesn't exist and update stroke */
								const gridLayer = canvasObjects.filter((o) => o.get('objectType') === CanvasObjectType.GRID);
								const gridGroup = generateGrid(clipBounds, invertedColor, canvasState.grid);

								if (!gridLayer.length) {
									canvas.add(gridGroup);
								} else {
									gridLayer[0].set({ stroke: invertedColor });
								}

								/** Create and set snapping layer if it doesn't exist */
								const snappingLayer = canvasObjects.filter((o) => o.get('objectType') === CanvasObjectType.SNAP);
								const snappingLinesCoords = generateSnappingLineCoords(clipBounds);
								const snappingLineGroup = generateSnappingLines(snappingLinesCoords);

								if (!snappingLayer.length) canvas.add(snappingLineGroup);

								/** Create and set bleed layer if it doesn't exist */
								const bleedLayer = canvasObjects.filter((o) => o.get('objectType') === CanvasObjectType.BLEED);

								if (bleed && !bleedLayer?.length) {
									const bleedObjects = generateBleedCoords(clipScaleRatio, bleed, cwidth, cheight);
									const bleedGroup = generateBleed(bleedObjects);

									canvas.add(bleedGroup);
								}

								/** Create and set the clipping layer if it doesn't exist else update stoke color */
								const clipLayer = canvasObjects.filter((o) => o.get('objectType') === CanvasObjectType.CLIP);
								const clipPath = generateClipPath(clipBounds);

								if (!clipLayer.length) canvas.add(clipPath);

								const clipBorderLayer = canvasObjects.filter(
									(o) => o.get('objectType') === CanvasObjectType.CLIP_BORDER,
								);
								const clipBorder = generateClipBorder(clipBounds, invertedColor);

								if (!clipBorderLayer.length) {
									canvas.add(clipBorder);
								} else {
									clipBorderLayer[0].set({ stroke: invertedColor });
								}

								/** Apply clipPath */
								if (canvasObjects.length) {
									canvasObjects.forEach((o: CustomFabricObject) => {
										const objectType = o.get('objectType');

										if (
											objectType === CanvasObjectType.PRODUCT_IMAGE ||
											objectType === CanvasObjectType.BACKGROUND_IMAGE ||
											objectType === CanvasObjectType.BLEED ||
											objectType === CanvasObjectType.CLIP_BORDER
										) {
											return;
										}

										o.set('clipPath', clipPath);
									});
								}
							}

							canvas.sendToBack(img);
							if (backgroundImg) canvas.sendToBack(backgroundImg);
							canvas.backgroundColor = backgroundColor;
							canvas.renderAll();

							canvas.fire('history:clear');
						},
						null,
						'anonymous',
					);
				},
				null,
				'anonymous',
			);
		});

		setInitialLoad(true);
	}

	useEffect(() => {
		if (activeTemplateData && activeCanvas?.position && canvasState.ready) {
			createBackgroundComposition(activeTemplateData);
		}
	}, [activeTemplateData, activeCanvas?.position, canvasState.ready]);

	return canvasState.activeVariants.length ? (
		<VariantSelectorContainer>
			<VariantSelectorInner>
				{canvasState.printSides.length > 1 && (
					<>
						<PrintSideSelector printSides={canvasState.printSides} selectedPrintSide={canvasState.selectedPrintSide} />
						<Divider />
					</>
				)}
				<ProductColorsContainer>
					{canvasState.activeVariants.map((c) => (
						<ColorCircle
							onClick={() => canvasDispatch({ type: CanvasAction.SET_ACTIVE_VARIANT, payload: c })}
							key={c}
							color={c}
							selected={c === canvasState.selectedVariant}
							pointerEvents={c === canvasState.selectedVariant}
							small
						/>
					))}
				</ProductColorsContainer>
			</VariantSelectorInner>
		</VariantSelectorContainer>
	) : null;
}
