import { useEffect, useState } from 'react';
import { Button } from '@streamelements/frontend-ui';
import { ActionBlock, clickableIcon, mediumIcon, tinyIconButton } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import CustomFabricObject from '../../types/CustomFabricObject';
import { TextDirectionIcon, TextUnderlineIcon, TextCapitalizeIcon, TextItalicIcon } from '../Icons';

export default function FontStyleController() {
	const { activeCanvas } = useActiveCanvas();

	const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;
	const [italicValue, setItalicValue] = useState(activeObject?.get('fontStyle')?.toString());
	const [underlineValue, setUnderlineValue] = useState(activeObject?.get('underline'));

	const capitalizeText = () => {
		let text = activeObject.get('text');
		if (text === text?.toUpperCase()) {
			text = text?.toLowerCase();
		} else {
			text = text?.toUpperCase();
		}

		if (activeCanvas) {
			setObjProp(activeCanvas, 'text', text);
		}
	};

	const changeTextDirection = () => {
		const isVertical = activeObject.get('verticalText') || false;
		let text = activeObject.get('text');
		if (isVertical) {
			text = text?.replace(/(\n){1}/g, '');
		} else {
			text = text?.replace(/(\b\n){1}/g, '\n');
			text = text?.split('').join('\n');
		}

		if (activeCanvas) {
			setObjProp(activeCanvas, 'text', text);
			setObjProp(activeCanvas, 'verticalText', !isVertical);
		}
	};

	useEffect(() => {
		if (!activeCanvas?.canvas || !activeCanvas?.selectedObject) return;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setItalicValue(activeObject?.get('fontStyle')?.toString());
		setUnderlineValue(activeObject?.get('underline'));
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'fontStyle', italicValue);
		}
	}, [italicValue]);

	useEffect(() => {
		if (activeCanvas) {
			setObjProp(activeCanvas, 'underline', underlineValue);
		}
	}, [underlineValue]);

	return (
		<>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => setUnderlineValue(!underlineValue)}
					className={tinyIconButton}
				>
					<TextUnderlineIcon className={[mediumIcon, clickableIcon].join(' ')} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button
					iconButton
					variant="ghost"
					color="neutral"
					onClick={() => setItalicValue(italicValue === 'italic' ? 'normal' : 'italic')}
					className={tinyIconButton}
				>
					<TextItalicIcon className={[mediumIcon, clickableIcon].join(' ')} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button iconButton variant="ghost" color="neutral" onClick={capitalizeText} className={tinyIconButton}>
					<TextCapitalizeIcon className={[mediumIcon, clickableIcon].join(' ')} />
				</Button>
			</ActionBlock>
			<ActionBlock clearVerticalSpacing>
				<Button iconButton variant="ghost" color="neutral" onClick={changeTextDirection} className={tinyIconButton}>
					<TextDirectionIcon className={[mediumIcon, clickableIcon].join(' ')} />
				</Button>
			</ActionBlock>
		</>
	);
}
