import { PropsWithChildren, useContext, useState } from 'react';
import { HotKeys } from 'react-keyboard';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext } from '../../store';
import {
	cloneObject,
	groupObjects,
	moveObjHorizontal,
	moveObjVertical,
	removeObject,
	selectAll,
	ungroupObjects,
	unselect,
} from '../../store/ActionCreators/CanvasActionCreator';
import CustomFabricObject from '../../types/CustomFabricObject';

const hotKeysMap = {
	delete: ['del', 'backspace'],
	group: ['command+g', 'control+g', 'ctrl+g'],
	ungroup: ['command+shift+g', 'control+shift+g', 'ctrl+shift+g'],
	selectAll: ['command+a', 'control+a', 'ctrl+a'],
	moveRight: ['right'],
	moveShiftRight: ['shift+right'],
	moveLeft: ['left'],
	moveShiftLeft: ['shift+left'],
	moveUp: ['up'],
	moveShiftUp: ['shift+up'],
	moveDown: ['down'],
	moveShiftDown: ['shift+down'],
	copy: ['command+c', 'control+c', 'ctrl+c'],
	paste: ['command+v', 'control+v', 'ctrl+v'],
	undo: ['command+z', 'control+z', 'ctrl+z'],
	redo: ['command+shift+z', 'control+shift+z', 'ctrl+shift+z'],
	unselect: ['esc'],
};

export function HotKeysController(props: PropsWithChildren<Record<string, unknown>>) {
	const { activeCanvas } = useActiveCanvas();
	const { dispatch: canvasDispatch } = useContext(CanvasesContext);
	const [clipboard, setClipboard] = useState<CustomFabricObject | undefined>();

	const hotKeysHandlers = (() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		return {
			delete: (e: Event) => {
				e.preventDefault();
				removeObject(canvas, undefined, canvasDispatch);
			},
			group: (e: Event) => {
				e.preventDefault();
				groupObjects(activeCanvas);
			},
			ungroup: (e: Event) => {
				e.preventDefault();
				ungroupObjects(activeCanvas);
			},
			selectAll: (e: Event) => {
				e.preventDefault();
				selectAll(activeCanvas);
			},
			moveRight: (e: Event) => {
				e.preventDefault();
				moveObjVertical(activeCanvas, 5);
			},
			moveShiftRight: (e: Event) => {
				e.preventDefault();
				moveObjVertical(activeCanvas, 50);
			},
			moveLeft: (e: Event) => {
				e.preventDefault();
				moveObjVertical(activeCanvas, -5);
			},
			moveShiftLeft: (e: Event) => {
				e.preventDefault();
				moveObjVertical(activeCanvas, -50);
			},
			moveUp: (e: Event) => {
				e.preventDefault();
				moveObjHorizontal(activeCanvas, -5);
			},
			moveShiftUp: (e: Event) => {
				e.preventDefault();
				moveObjHorizontal(activeCanvas, -50);
			},
			moveDown: (e: Event) => {
				e.preventDefault();
				moveObjHorizontal(activeCanvas, 5);
			},
			moveShiftDown: (e: Event) => {
				e.preventDefault();
				moveObjHorizontal(activeCanvas, 50);
			},
			copy: (e: Event) => {
				e.preventDefault();
				setClipboard(canvas.getActiveObject());
			},
			paste: (e: Event) => {
				e.preventDefault();
				cloneObject(activeCanvas, clipboard);
			},
			undo: (e: Event) => {
				e.preventDefault();
				canvas.fire('history:undo');
			},
			redo: (e: Event) => {
				e.preventDefault();
				canvas.fire('history:redo');
			},
			unselect: (e: Event) => {
				e.preventDefault();
				unselect(activeCanvas);
			},
		};
	})();

	return (
		<HotKeys keyMap={hotKeysMap} handlers={hotKeysHandlers}>
			{props.children}
		</HotKeys>
	);
}
