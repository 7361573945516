/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import React from 'react';
import CanvasObjectType from '../enums/CanvasObjectType';
import { uploadImage } from '../services/MerchAPI';
import { addToCanvas, removeObject } from '../store/ActionCreators/CanvasActionCreator';
import { getBoundsAndEdges, getUnscaledBoundsAndEdges } from '../store/ActionCreators/ObjectAlignActionCreator';
import {
	ErrorTypes,
	CanvasState,
	CanvasObject,
	CustomFabricObject,
	CanvasCallback,
	SavePlacementsAssets,
	EditorState,
} from '../types';
import PatternSettings from '../types/PatternSettings';
import { PatternType } from '../types/PatternType';
import { dataURItoBlob } from '../utils';

export const clearProperties = [
	'objectType',
	'layerLocked',
	'layerName',
	'selectable',
	'hasControls',
	'hoverCursor',
	'hiddenLayer',
	'capitalize',
	'verticalText',
	'absolutePositioned',
	'objectCaching',
	'errored',
];

export async function duplicateAndClearCanvas(
	canvases: CanvasObject[],
	showBackground = false,
): Promise<CanvasCallback[]> {
	fabric.Object.NUM_FRACTION_DIGITS = 8;
	const duplicateCanvases = [] as CanvasCallback[];

	return new Promise((resolve) => {
		canvases.forEach((canvasState) => {
			if (!canvasState.canvas || !canvasState.clip) return;

			const datalessCanvas = canvasState.canvas.toDatalessJSON(clearProperties);
			const duplicateCanvas = new fabric.Canvas('canvasHiddenDuplicate', {
				backgroundColor: canvasState.canvas.backgroundColor,
				controlsAboveOverlay: true,
				height: canvasState.canvas.getHeight(),
				width: canvasState.canvas.getWidth(),
			});

			duplicateCanvas.loadFromJSON(datalessCanvas, () => {
				const duplicateCanvasObjects = duplicateCanvas.getObjects();
				const removeableLayers: Array<CanvasObjectType | undefined> = [
					CanvasObjectType.OVERLAY,
					CanvasObjectType.SNAP,
					CanvasObjectType.BLEED,
					CanvasObjectType.GRID,
					CanvasObjectType.CLIP_BORDER,
				];

				if (!showBackground) {
					removeableLayers.push(CanvasObjectType.PRODUCT_IMAGE, CanvasObjectType.BACKGROUND_IMAGE);
					duplicateCanvas.backgroundColor = undefined;
				}

				const layersToRemove = duplicateCanvasObjects.filter((o: CustomFabricObject) =>
					removeableLayers.includes(o.get('objectType')),
				);

				if (layersToRemove.length) layersToRemove.forEach((layer) => duplicateCanvas.remove(layer));

				duplicateCanvasObjects
					.filter((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP)
					.forEach((o) => o.set('strokeWidth', 0));

				let outputWidth = duplicateCanvas.getWidth();
				let outputHeight = duplicateCanvas.getHeight();

				const outputCanvasWidth = duplicateCanvas.getWidth();
				const outputCanvasHeight = duplicateCanvas.getHeight();

				if (canvasState.clip) {
					const clipLayer = duplicateCanvasObjects.find(
						(o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP,
					);

					if (clipLayer && !showBackground) {
						const clipLayerTop = Number(clipLayer.get('top'));
						const clipLayerLeft = Number(clipLayer.get('left'));
						const clipLayerHeight = Number(clipLayer.get('height'));
						const clipLayerWidth = Number(clipLayer.get('width'));
						const desiredHeight = canvasState.exportData.height;
						const desiredWidth = canvasState.exportData.width;
						duplicateCanvas.absolutePan(new fabric.Point(clipLayerLeft, clipLayerTop));
						const scale = Math.min(desiredWidth / clipLayerWidth, desiredHeight / clipLayerHeight);

						clipLayer.set({
							strokeWidth: 0,
							width: desiredWidth,
							height: desiredHeight,
						});

						duplicateCanvas.backgroundImage = undefined;
						duplicateCanvas.backgroundColor = undefined;
						duplicateCanvas.overlayImage = undefined;
						duplicateCanvas.setHeight(desiredHeight);
						duplicateCanvas.setWidth(desiredWidth);
						duplicateCanvas.setZoom(scale);
						duplicateCanvas.renderAll();

						outputWidth = desiredWidth;
						outputHeight = desiredHeight;
					}

					duplicateCanvases.push({
						canvas: duplicateCanvas,
						width: outputWidth,
						height: outputHeight,
						canvasWidth: outputCanvasWidth,
						canvasHeight: outputCanvasHeight,
						threadColors: canvasState.selectedThreads.map((thread) => thread.value),
						position: canvasState.position || '',
					});

					if (duplicateCanvases.length === canvases.length) {
						resolve(duplicateCanvases);
					}
				}
			});
		});
	});
}

export const objectToPattern = (
	activeCanvas: CanvasObject,
	obj: CustomFabricObject,
	patternSettings: PatternSettings,
	patternType: PatternType = 'FullDrop',
) => {
	const canvas = activeCanvas?.canvas;

	if (!canvas) return;

	const originalClone = fabric.util.object.clone(obj);
	const unscaledBounds = getUnscaledBoundsAndEdges(activeCanvas);

	const boundingClip = getBoundsAndEdges(canvas);
	const scale = boundingClip.width / unscaledBounds.width;

	removeObject(canvas, obj);

	originalClone.set({
		top: 0,
		left: 0,
		strokeUniform: false,
		strokeWidth: 0,
		clipPath: undefined,
		hasBorders: false,
		hasControls: false,
		imageSmoothing: true,
		borderScaleFactor: 0,
		objectCaching: false,
	});

	originalClone.scaleToWidth(Number(originalClone.get('width')));

	const bottomLeftObjectClone = fabric.util.object.clone(originalClone);
	const bottomRightObjectClone = fabric.util.object.clone(originalClone);

	const objectScaledHeight = Number(originalClone.get('width'));
	const objectScaledWidth = Number(originalClone.get('height'));

	const patternSourceCanvas = new fabric.StaticCanvas(null, {
		width: unscaledBounds.width,
		height: unscaledBounds.height,
	});

	const groupObjects = [originalClone];

	let groupHeight = objectScaledHeight;
	let groupWidth = objectScaledWidth;

	switch (patternType) {
		case 'FullDrop':
			break;

		case 'HalfDrop':
			originalClone.set({
				top: objectScaledHeight / 2,
				left: 0,
			});
			bottomLeftObjectClone.set({
				top: 0,
				left: objectScaledWidth,
			});

			bottomRightObjectClone.set({
				top: objectScaledHeight,
				left: objectScaledWidth,
			});
			groupObjects.push(bottomLeftObjectClone);
			groupObjects.push(bottomRightObjectClone);
			groupHeight = objectScaledHeight;
			groupWidth = objectScaledWidth * 2;
			break;

		default:
		case 'Brick':
			bottomLeftObjectClone.set({
				top: objectScaledHeight,
				left: -(objectScaledWidth / 2),
			});

			bottomRightObjectClone.set({
				top: objectScaledHeight,
				left: objectScaledWidth / 2,
			});
			groupObjects.push(bottomLeftObjectClone);
			groupObjects.push(bottomRightObjectClone);
			groupHeight = objectScaledHeight * 2;
			break;
	}

	const group = new fabric.Group(groupObjects, {
		width: groupWidth,
		height: groupHeight,
		top: 0,
		left: 0,
		objectCaching: false,
		scaleX: scale,
		scaleY: scale,
	});

	patternSourceCanvas.setDimensions({
		width: groupWidth * scale,
		height: groupHeight * scale,
	});

	patternSourceCanvas.add(group);
	patternSourceCanvas.renderAll();

	const pattern = new fabric.Pattern({
		// @ts-ignore
		source: patternSourceCanvas.getElement(),
		offsetX: 0,
		offsetY: 0,
		repeat: 'repeat',
		sourceImage: group,
		sourceCanvas: patternSourceCanvas,
	});

	const rect = new fabric.Rect({
		width: unscaledBounds.width,
		height: unscaledBounds.height,
		top: boundingClip.top,
		left: boundingClip.left,
		fill: pattern,
		scaleX: scale,
		scaleY: scale,
	}) as CustomFabricObject;

	rect.set({
		layerName: 'Pattern',
		// @ts-ignore
		defaultSettings: patternSettings,
		objectType: CanvasObjectType.PATTERN,
		// @ts-ignore
		patternSourceImage: obj._element.src,
	});

	rect.scaleToWidth(boundingClip.width);

	addToCanvas(canvas, rect);
};

export async function generatePrintCanvases(canvasState: CanvasState, editorState: EditorState) {
	const canvases = canvasState.canvases.filter((canvas) =>
		canvas.position ? editorState.product.savePlacements.includes(canvas.position) : true,
	);

	return await duplicateAndClearCanvas(canvases);
}

export async function uploadAssets(
	printCanvases: CanvasCallback[],
	canvasState: CanvasState,
	setAppLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorState: (error: boolean, type: ErrorTypes) => void,
): Promise<SavePlacementsAssets[]> {
	return new Promise((resolve) => {
		const savePlacementsObjects = [] as SavePlacementsAssets[];

		printCanvases.forEach(async (canvasObject) => {
			if (!canvasObject?.canvas) return;

			const currentCanvas = canvasState.canvases.find((c) => c.position === canvasObject.position);
			const duplicateCanvasData = canvasObject.canvas.toDatalessJSON(clearProperties);

			if (!currentCanvas?.canvas || !duplicateCanvasData) return;

			//  @ts-ignore
			duplicateCanvasData.patterns = [];

			currentCanvas.canvas.getObjects().forEach((object: CustomFabricObject) => {
				if (object.get('objectType') === CanvasObjectType.PATTERN) {
					//  @ts-ignore
					duplicateCanvasData.patterns.push({
						patternSourceImage: object.get('patternSourceImage') || '',
						patternOffsetY: object.get('patternOffsetY') ?? 0,
						patternOffsetX: object.get('patternOffsetX') ?? 0,
						patternWidth: object.get('patternWidth') ?? 0,
						patternAngle: object.get('patternAngle') ?? 0,
						patternPadding: object.get('patternPadding') ?? 0,
						patternType: object.get('patternType') ?? '',
					});
				}
			});

			// @ts-ignore
			duplicateCanvasData.originalCanvasWidth = canvasObject.canvasWidth;
			// @ts-ignore
			duplicateCanvasData.originalCanvasHeight = canvasObject.canvasHeight;

			const file = dataURItoBlob(
				canvasObject.canvas.toDataURL({
					width: canvasObject.width,
					height: canvasObject.height,
					format: 'png',
				}),
			);

			if (!file) return;

			try {
				const image = await uploadImage(file);

				savePlacementsObjects.push({
					canvas: duplicateCanvasData,
					assetUrl: image.url,
					transformUrl: image.transformUrl,
					position: canvasObject.position,
					threadColors: canvasObject.threadColors,
				});
			} catch (e) {
				setAppLoading(false);
				setErrorState(true, ErrorTypes.SAVE_PRODUCT_FAILED);
			}

			if (savePlacementsObjects.length === printCanvases.length) {
				resolve(savePlacementsObjects);
			}
		});
	});
}
