import CanvasObjectType from '../../enums/CanvasObjectType';
import { CanvasObject } from '../../types';
import CustomFabricObject from '../../types/CustomFabricObject';

export function setObjProp(activeCanvas: CanvasObject, key: any, value: string | number | boolean | undefined): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject || value === undefined) return;

	const activeObject: CustomFabricObject = canvas.getActiveObject();

	if (!activeObject) return;

	if (activeObject.get(key) === value) return;

	activeObject.set(key, value);

	if (activeObject._objects && activeObject.get('objectType') !== CanvasObjectType.GROUP) {
		activeObject._objects.forEach((obj) => obj.set(key, value));
	}

	activeObject.setCoords();

	canvas.fire('object:manualChanged');
	canvas.renderAndReset();
}
