import { SnappingLines } from '../../../enums/SnappingLines';
import { Bleed } from '../../../types/EditorOptions';
import { IObjectBoundingRect } from '../../../types/IObjectBoundingRect';

export interface SnappingLineCoord {
	name: SnappingLines;
	size: number[];
	coords: number[];
}

export interface BleedCoord {
	name: string;
	coords: number[];
}

export function generateSnappingLineCoords(clipBounds: IObjectBoundingRect): SnappingLineCoord[] {
	const horizontalLine = [clipBounds.left, 0, clipBounds.width + clipBounds.left, 0];
	const verticalLine = [0, clipBounds.top, 0, clipBounds.height + clipBounds.top];
	const roundedTop = 2 * Math.round(clipBounds.top / 2);
	const roundedLeft = 2 * Math.round(clipBounds.left / 2);

	return [
		{
			name: SnappingLines.Top,
			size: horizontalLine,
			coords: [clipBounds.top, clipBounds.left],
		},
		{
			name: SnappingLines.Bottom,
			size: horizontalLine,
			coords: [clipBounds.top + clipBounds.height + 2, clipBounds.left],
		},
		{
			name: SnappingLines.HorizontalCenter,
			size: horizontalLine,
			coords: [roundedTop + clipBounds.height / 2, roundedLeft],
		},
		{
			name: SnappingLines.Left,
			size: verticalLine,
			coords: [clipBounds.top, clipBounds.left],
		},
		{
			name: SnappingLines.Right,
			size: verticalLine,
			coords: [clipBounds.top, clipBounds.left + 2 + clipBounds.width],
		},
		{
			name: SnappingLines.VerticalCenter,
			size: verticalLine,
			coords: [roundedTop, roundedLeft + clipBounds.width / 2],
		},
	];
}

export function generateBleedCoords(scale: number, bleed: Bleed, cwidth: number, cheight: number) {
	const bleedSizes = {
		top: bleed.top * scale,
		left: bleed.left * scale,
		bottom: bleed.bottom * scale,
		right: bleed.right * scale,
	};

	return [
		{
			name: 'BleedTop',
			coords: [0, 0, bleedSizes.top, cwidth],
		},
		{
			name: 'BleedBottom',
			coords: [cheight - bleedSizes.bottom, 0, bleedSizes.top, cwidth],
		},
		{
			name: 'BleedLeft',
			coords: [bleedSizes.top, 0, cheight - bleedSizes.top - bleedSizes.bottom, bleedSizes.left],
		},
		{
			name: 'BleedRight',
			coords: [
				bleedSizes.top,
				cwidth - bleedSizes.right,
				cheight - bleedSizes.top - bleedSizes.bottom,
				bleedSizes.left,
			],
		},
	];
}
