import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, Button, Text } from '@streamelements/frontend-ui';
import { goBackIcon, tinyIconButton } from '../../Editor.style';
import { EditorsContext } from '../../store';
import { GoBackIcon } from '../Icons';

const HeaderContainer = styled('div', {
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	columnGap: '$base',
	alignItems: 'center',
});

export function Header() {
	const history = useHistory();
	const { state: editorState } = useContext(EditorsContext);

	return (
		<HeaderContainer>
			<Button iconButton variant="ghost" color="neutral" className={tinyIconButton} onClick={() => history.goBack()}>
				<GoBackIcon color="neutral" className={goBackIcon} />
			</Button>
			<Text.Heading level={5} weight="black">
				{editorState.product.name ? editorState.product.name : 'New Item'}
			</Text.Heading>
		</HeaderContainer>
	);
}
