import { PropsWithChildren } from 'react';
import { Check } from '@streamelements/frontend-icons';
import { styled, Tooltip, Slot, Text } from '@streamelements/frontend-ui';
import { useVariantData } from '../hooks';
import { invertColor } from '../utils/ColorUtils';

const Container = styled('button', {
	display: 'flex',
	height: 28,
	width: 28,
	borderRadius: '10em',
	transition: 'box-shadow 0.2s ease-in-out',
	backgroundClip: 'padding-box',
	justifySelf: 'center',
	position: 'relative',
	overflow: 'hidden',
	pointerEvents: 'initial',
	border: 'none',
	padding: 0,

	'&:before': {
		position: 'absolute',
		top: '0',
		left: '0',
		content: '',
		display: 'block',
		width: '100%',
		height: '100%',
		borderRadius: '10em',
		boxShadow: 'inset 0px 0px 0px 2px rgba(0,0,0, 0.3)',
		zIndex: 1,
	},

	'&:hover': {
		cursor: 'pointer',
		boxShadow: '0 0 0 2px $colors$uiPrimaryMain',
	},

	variants: {
		pointerEvents: {
			true: {
				pointerEvents: 'none',
			},
		},
		small: {
			true: {
				height: 24,
				width: 24,
			},
		},
		disabled: {
			true: {
				opacity: 0.2,
			},
		},
		selected: {
			true: {
				opacity: 1,
			},
		},
		type: {
			span: {
				'&:hover': {
					cursor: 'initial',
					boxShadow: 'none',
				},
			},
		},
	},
});

const StyledHalfColor = styled('span', {
	display: 'block',
	width: 13,
	height: 26,
	position: 'absolute',
	top: 0,
	right: 0,

	variants: {
		small: {
			true: {
				width: 11,
				height: 24,
			},
		},
	},
});

const StyledCheckIcon = styled(Check, {
	'&&': {
		fontSize: '1rem',
		margin: 'auto',
		filter: 'drop-shadow(0 0 1px rgba(0, 0, 0, 1))',
		zIndex: 1,
	},
});

interface Props {
	color: string;
	onClick?: () => void;
	selected?: boolean;
	disabled?: boolean;
	pointerEvents?: boolean;
	small?: boolean;
}

export function ColorCircle({
	color,
	onClick,
	selected,
	disabled,
	pointerEvents,
	small = false,
}: PropsWithChildren<Props>) {
	const { variant } = useVariantData(color);

	if (!color) return null;

	if (!onClick) {
		return (
			<Container type="span" as="span" css={{ backgroundColor: variant?.colorCodes[0] || color }}>
				{variant && variant.colorCodes.length > 1 && (
					<StyledHalfColor css={{ backgroundColor: variant.colorCodes[1] }} />
				)}
			</Container>
		);
	}

	if (!variant) return null;

	return (
		<Tooltip.Root>
			<Tooltip.Trigger as={Slot}>
				<Container
					onClick={onClick}
					css={{ backgroundColor: variant.colorCodes[0] }}
					disabled={disabled}
					selected={selected}
					pointerEvents={pointerEvents}
					data-tip
					data-delay-show={300}
					data-for={`Color_Circle_${variant.color}`}
					small={small}
				>
					{variant.colorCodes.length > 1 && (
						<StyledHalfColor small={small} css={{ backgroundColor: variant.colorCodes[1] }} />
					)}
					{selected && <StyledCheckIcon css={{ color: invertColor(variant.colorCodes[0]) }} />}
				</Container>
			</Tooltip.Trigger>
			{variant.color && (
				<Tooltip.Content>
					<Text.Body variant="caption" weight="bold" css={{ color: '$textPrimaryMainInverted' }}>
						{variant.color}
					</Text.Body>
				</Tooltip.Content>
			)}
		</Tooltip.Root>
	);
}
