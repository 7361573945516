import { EditorState } from '../enums';
import { Template } from './TemplateServerResponse';
import { ThreadOption } from './Threads';
import { UserAssetsResponse } from './User';

export type Clip = {
	width: number;
	height: number;
	top: number;
	left: number;
};

export type Bleed = {
	top: number;
	right: number;
	left: number;
	bottom: number;
};

export type productData = {
	title?: string;
	basePrice?: number;
};

export enum Vendors {
	GOOTEN = 'gooten',
	PRINTFUL = 'printful',
}

export type ThreadOptions = {
	selectedThreads?: ThreadOption[];
};

interface EditorOptions {
	state: EditorState;
	defaultWidth?: number;
	defaultHeight?: number;
	allowDownload?: boolean;
	allowCanvasResize?: boolean;
	allowHistory?: boolean;
	allowSnapping?: boolean;
	allowExport?: boolean;
	allowZoom?: boolean;
	canvasId?: string | null;
	onExportCanvas?: (canvasJson: fabric.Canvas) => void;
	onSaveCanvas?: (itemId?: string, callback?: boolean) => void;
	clip?: Clip;
	bleed?: Bleed;
	backgroundImage: string;
	image?: string | null;
	darkOverlayImage?: string | null;
	color?: string;
	invertedColor?: string;
	backgroundColor?: string;
	productColors?: string[][];
	onAssetsChange?: () => void;
	onProductSizeChange?: (size: string) => void;
	onProductColorChange?: (color: string[]) => void;
	fonts?: string[];
	productData?: any;
	assets?: UserAssetsResponse;
	template?: Template;
	grid?: boolean;
}

export default EditorOptions;
