import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ActionBlock } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import { ColorPicker } from '../ColorPicker';

export default function FillController() {
	const { activeCanvas } = useActiveCanvas();

	const [color, setColor] = useState('');
	const [value] = useDebounce(color, 250);

	useEffect(() => {
		if (activeCanvas) setObjProp(activeCanvas, 'fill', value || '');
	}, [value]);

	useEffect(() => {
		if (!activeCanvas?.canvas || !activeCanvas?.selectedObject) return;

		setColor(activeCanvas?.selectedObject?.get('fill')?.toString() || '');
	}, [activeCanvas?.canvas, activeCanvas?.selectedObject]);

	return (
		<ActionBlock clearVerticalSpacing>
			<ColorPicker items={[]} value={value} onChange={setColor} />
		</ActionBlock>
	);
}
