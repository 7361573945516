import * as THREE from 'three';
import { ResourceTracker } from '.';

export const objectVisualizer = function () {
	let octx = {};
	let resTracker = (window.resss = new ResourceTracker());
	const track = resTracker.track.bind(resTracker);

	const userRotation = new THREE.Vector3();
	const userZoom = new THREE.Vector3();

	let lastMousePosition;
	let renderer;
	let scene;
	let camera;
	let animMesh;
	let userPastedImage;
	let loadedGeometry;

	function mergeObject(a, b) {
		if (b)
			for (var v in b) {
				a[v] = b[v];
			}
	}
	function clamp(val, min, max) {
		return Math.min(Math.max(val, min ? min : 0), max ? max : 1);
	}
	function lerp(va, vb, vv) {
		return va + (vb - va) * vv;
	}

	const initGraph = () => {
		const canvas = document.getElementById('viewport');
		if (!canvas) return;
		renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas });
		renderer.setClearColor(0xdddddd, 1);

		//Set up the scene and camera
		scene = new THREE.Scene();
		camera = new THREE.PerspectiveCamera(
			35, // Field of view
			800 / 600, // Aspect ratio
			0.1, // Near plane
			10000, // Far plane
		);
		camera.position.set(0, 0, 7);
		camera.lookAt(scene.position);

		userZoom.z = camera.position.z;

		const lightIntensity = 0.52;
		//Add 2 point lights and an ambient light...
		const lightDist = 100;
		const lightColor = 0xffffff;
		const light = new THREE.DirectionalLight(lightColor, lightIntensity);
		light.position.set(-lightDist * 2.0, 0, lightDist);
		scene.add(light);

		const light1 = new THREE.DirectionalLight(lightColor, lightIntensity);
		light1.position.set(lightDist * 2.0, lightDist * 0.25, lightDist);
		scene.add(light1);

		const light2 = new THREE.AmbientLight(); // soft white light
		light2.color.setRGB(1.0 * lightIntensity, 1.0 * lightIntensity, 1.0 * lightIntensity);
		scene.add(light2);

		//Make the sky/env box
		const geometry = new THREE.BoxGeometry(90, 90, 90);
		const material = new THREE.MeshLambertMaterial({
			color: 0, //0x808080,//0xdddddd,//0x808080,//0xdddddd,
			side: THREE.DoubleSide,
		});
		const skycube = new THREE.Mesh(geometry, material);
		scene.add(skycube);
	};

	const addEventListeners = () => {
		const canvas = document.getElementById('viewport');
		if (!canvas) return;

		canvas.addEventListener(
			'webglcontextlost',
			function (event) {
				event.preventDefault();
			},
			false,
		);

		canvas.addEventListener(
			'webglcontextrestored',
			function (event) {
				octx.initApp();
			},
			false,
		);

		var mouseDown = false;
		canvas.addEventListener('mousedown', function () {
			mouseDown = true;
		});
		canvas.addEventListener('mouseup', function () {
			mouseDown = false;
		});

		canvas.addEventListener('mousemove', function (evt) {
			if (!lastMousePosition) lastMousePosition = new THREE.Vector2(evt.clientX, evt.clientY);
			if (mouseDown) {
				userRotation.y = clamp(userRotation.y + (evt.clientX - lastMousePosition.x) * 0.01, -Math.PI * 5, Math.PI * 5);
				userRotation.x = clamp(
					userRotation.x + (evt.clientY - lastMousePosition.y) * 0.01,
					-Math.PI * 0.5,
					Math.PI * 0.5,
				);
			}

			lastMousePosition.set(evt.clientX, evt.clientY);
		});

		canvas.addEventListener('mousewheel', function (evt) {
			userZoom.z = clamp(userZoom.z + evt.wheelDelta * -0.01, 3, 15);
		});

		let lastTouch;
		canvas.addEventListener(
			'touchmove',
			function (event) {
				if (event.targetTouches.length === 1) {
					let touch = event.targetTouches[0];
					if (!lastTouch) lastTouch = new THREE.Vector2(touch.pageX, touch.pageY);
					userRotation.y = clamp(userRotation.y + (touch.pageX - lastTouch.x) * 0.01, -Math.PI * 5, Math.PI * 5);
					userRotation.x = clamp(userRotation.x + (touch.pageY - lastTouch.y) * 0.01, -Math.PI * 0.5, Math.PI * 0.5);

					lastTouch.set(touch.pageX, touch.pageY);
					event.preventDefault();
				}
			},
			false,
		);
	};

	const buildMesh = (options) => {
		const params = { color: 0xffffffff, shininess: 3000 };
		mergeObject(params, options);

		const prevMesh = scene.getObjectByName('PRODUCT');

		if (prevMesh) {
			scene.remove(prevMesh);
			resTracker.dispose(prevMesh);
		}

		THREE.ImageUtils.loadTexture(params.texPath, THREE.UVMapping, function (texture) {
			track(texture);
			texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
			const material = new THREE.MeshPhongMaterial({
				color: params.color,
				shininess: params.shininess,
				side: THREE.DoubleSide,
			});

			track(material);

			const cdim = 2048;
			const canvas = document.createElement('canvas');
			canvas.width = canvas.height = cdim;

			const compositeTexture = new THREE.Texture(canvas);
			compositeTexture.wrapS = compositeTexture.wrapT = THREE.RepeatWrapping;

			function rebuildTexture() {
				const ctx = canvas.getContext('2d');
				ctx.globalCompositeOperation = 'source-over';
				ctx.fillStyle = 'white';

				ctx.fillRect(0, 0, cdim, cdim);

				const img = userPastedImage || texture.image;
				let iwid = img.width;
				let ihite = img.height;
				const max = Math.max(iwid, ihite);
				const scl = (cdim / max) * 0.38;
				iwid *= scl;
				ihite *= scl;

				ctx.drawImage(img, 70, 657, iwid * 2.425, ihite * 2.6);

				compositeTexture.needsUpdate = true;
				material.map = compositeTexture;
				material.needsUpdate = true;
			}

			rebuildTexture(material, canvas);

			const mesh = new THREE.Mesh(loadedGeometry, material);
			mesh.rebuildTexture = rebuildTexture;
			mesh.name = 'PRODUCT';
			animMesh = mesh;
			scene.add(mesh);

			resTracker.dispose();
		});
	};

	const loadObject = (options) => {
		const loader = new THREE.JSONLoader();

		loader.load(options.meshPath, function (geometry) {
			loadedGeometry = geometry;
			buildMesh(options);
		});
	};

	function renderFrame() {
		if (!document.getElementById('viewport')) return;
		window.requestAnimationFrame(renderFrame);
		const lerpyness = 0.1;
		const width = 600;
		const height = 600;
		renderer.setSize(width, height);
		camera.aspect = width / height;
		camera.updateProjectionMatrix();
		camera.position.lerp(userZoom, lerpyness);

		if (animMesh) {
			animMesh.rotation.x = lerp(animMesh.rotation.x, userRotation.x, lerpyness);
			animMesh.rotation.y = lerp(animMesh.rotation.y, userRotation.y, lerpyness);
		}
		renderer.render(scene, camera);
		resTracker.dispose();
	}

	octx.initApp = (url) => {
		if (!document.getElementById('viewport')) return;
		const opts = { meshPath: '/3dmodels/mug.json', texPath: url };

		initGraph();
		loadObject(opts);
		renderFrame();
		addEventListeners();
	};

	octx.reinit = (data) => {
		var image = new Image();
		image.onload = function () {
			animMesh?.rebuildTexture();
		};
		image.src = data;
		userPastedImage = image;
	};
	return octx;
};
