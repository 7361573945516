import { useCallback, useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { styled, Text } from '@streamelements/frontend-ui';
import { smallIcon, whiteIcon } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import IMAGE_QUALITY from '../../enums/ImageQuality';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext } from '../../store';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import { CustomFabricObject, CanvasAction } from '../../types';
import { ExclamationMarkicon } from '../Icons';
import BadImageQualityTooltip from './BadImageQualityTooltip';

const DPIControllerContainer = styled('div', {
	borderRadius: '$base',
	py: 'calc($base * 0.5)',
	px: '$base',
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	right: '$base',
	zIndex: 9,
	display: 'grid',
	gridAutoFlow: 'column',
	columnGap: 'calc($base * 0.5)',

	variants: {
		quality: {
			default: {},
			high: {
				background: '$uiSuccessMain',
			},
			medium: {
				background: '$uiAttentionMain',
			},
			low: {
				background: '$uiErrorMain',
			},
		},
	},
});

const tooltipStyle = {
	width: '250px',
};

const ImageQuality = [
	{
		name: IMAGE_QUALITY.HIGH,
		label: 'Excellent',
		threshold: (dpi: number) => dpi >= 300,
	},
	{
		name: IMAGE_QUALITY.MEDIUM,
		label: 'Average',
		threshold: (dpi: number) => dpi >= 150 && dpi <= 299,
	},
	{
		name: IMAGE_QUALITY.LOW,
		label: 'Poor',
		threshold: (dpi: number) => dpi <= 149,
	},
];

export default function ImageDPIController() {
	const { activeCanvas } = useActiveCanvas();
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const [dpi, setDpi] = useState<number>(300);

	const getDPILevel = useCallback(() => ImageQuality.find((q) => q.threshold(dpi)) || ImageQuality[0], [dpi]);

	const calculateDpi = useCallback(() => {
		const selectedObject = activeCanvas?.selectedObject;

		if (!activeCanvas || !selectedObject || !activeCanvas.canvas?.viewportTransform) return;

		const activeObject = activeCanvas.canvas.getActiveObject();

		if (!activeObject) return;

		selectedObject.setCoords();

		const clipLayer = activeCanvas.canvas
			.getObjects()
			?.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP);

		if (!clipLayer) return;

		const originalClipWidth = (activeCanvas.exportData?.width / activeCanvas.exportData?.dpi) * 300 || 1;
		const currentClipWidth = clipLayer?.get('width') || 1;
		const clipScale = currentClipWidth / originalClipWidth;
		const originalImageWidth = selectedObject.get('width') || 1;
		const fullResolutionScaledImageSize = originalImageWidth * clipScale;
		const currentImageWidth = selectedObject.getScaledWidth();
		const currentImageScale = currentImageWidth / fullResolutionScaledImageSize;

		setDpi(Math.round(300 / currentImageScale));
	}, [activeCanvas]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		calculateDpi();

		canvas.on('object:scaling', calculateDpi);

		return () => {
			if (!canvas) return;
			canvas.off('object:scaling', calculateDpi);
		};
	}, [activeCanvas, activeCanvas?.selectedObject, calculateDpi]);

	useEffect(() => {
		if (!activeCanvas) return;

		setObjProp(activeCanvas, 'errored', getDPILevel().name === IMAGE_QUALITY.LOW);
		setObjProp(activeCanvas, 'dpi', dpi);

		ReactTooltip.rebuild();
	}, [dpi, activeCanvas, getDPILevel]);

	useEffect(() => {
		if (getDPILevel().name === IMAGE_QUALITY.LOW && !canvasState?.error) {
			canvasDispatch({ type: CanvasAction.SET_ERROR, payload: getDPILevel().name === IMAGE_QUALITY.LOW });
		}

		if (getDPILevel().name !== IMAGE_QUALITY.LOW && canvasState?.error) {
			canvasDispatch({ type: CanvasAction.SET_ERROR, payload: getDPILevel().name === IMAGE_QUALITY.LOW });
		}
	}, [dpi, canvasDispatch, getDPILevel]);

	return (
		<DPIControllerContainer quality={getDPILevel().name}>
			{getDPILevel().name !== IMAGE_QUALITY.HIGH && (
				<>
					<ExclamationMarkicon
						data-tip
						data-delay-show={300}
						data-for="ImageQualityTag"
						data-place="top"
						className={[smallIcon, whiteIcon].join(' ')}
					/>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore */}
					<ReactTooltip id="ImageQualityTag" className={tooltipStyle}>
						<BadImageQualityTooltip dpi={dpi} />
					</ReactTooltip>
				</>
			)}
			<Text.Body variant="caption" weight="bold" style={{ color: '#fff' }}>
				{getDPILevel().label}
			</Text.Body>
			<Text.Body variant="caption" style={{ color: '#fff' }}>
				- {dpi}dpi
			</Text.Body>
		</DPIControllerContainer>
	);
}
