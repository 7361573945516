import { fabric } from 'fabric';
import { useContext, useEffect, useState } from 'react';
import { HotKeysController } from '../../components/Controllers';
import { CanvasObjectType } from '../../enums';
import { useActiveCanvas, useProduct } from '../../hooks';
import { CanvasesContext, UserContext, EditorsContext } from '../../store';
import { addImage, addImageRaw } from '../../store/ActionCreators/CanvasActionCreator';
import { CanvasAction, CanvasObject, CustomFabricObject, EditorStateAction } from '../../types';
import { objectToPattern } from '../../utils/CanvasUtils';

const initialCanvasState = {
	selectedObject: null,
	layers: [],
	selectedThreads: [],
	visibleLayersCount: 0,
	error: false,
};

export function Canvas({ canvasRef }: { canvasRef: React.RefObject<HTMLDivElement> }) {
	const [prevGraphicsLoaded, setPrevGraphicsLoaded] = useState<boolean>(false);
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { state: editorState } = useContext(EditorsContext);
	const { state: userState } = useContext(UserContext);
	const { product } = useProduct();
	const { activeCanvas } = useActiveCanvas();

	useEffect(() => {
		if (!canvasState.template || !userState || canvasState.canvases.length) return;

		const canvases: CanvasObject[] = [];
		const canvasPositions = Object.keys(canvasState.template.vendorPlacements);

		canvasPositions.forEach((position) => {
			const templateData = canvasState.variants[0].templates[position];
			const exportData = canvasState.variants[0].placementFiles[position];

			let canvasH = templateData.templateHeight;
			let canvasW = templateData.templateWidth;

			if (canvasRef.current) {
				const boundingBox = canvasRef.current.getBoundingClientRect();
				const scale = Math.min(boundingBox.height / canvasH, boundingBox.width / canvasW);

				canvasH = canvasH * scale;
				canvasW = canvasW * scale;
			}

			const canvas = new fabric.Canvas(position, {
				height: canvasH,
				width: canvasW,
				backgroundColor: 'white',
				controlsAboveOverlay: true,
				uniformScaling: true,
			});

			canvas.renderAll();

			const canvasObject = {
				...initialCanvasState,
				width: templateData.templateWidth,
				height: templateData.templateHeight,
				clip: {
					left: templateData.printAreaLeft,
					width: templateData.printAreaWidth,
					top: templateData.printAreaTop,
					height: templateData.printAreaHeight,
				},
				bleed: {
					bottom: templateData.bleedPaddingBottom,
					left: templateData.bleedPaddingLeft,
					right: templateData.bleedPaddingRight,
					top: templateData.bleedPaddingTop,
				},
				exportData: {
					height: exportData?.height || templateData.printAreaHeight || 0,
					width: exportData?.width || templateData.printAreaWidth || 0,
					dpi: exportData?.dpi || 300,
				},
				isEmbroidery: !!canvasState?.template?.vendorPlacements[position].options,
				position,
				canvas,
			};

			if (canvasState.preloadCanvases) {
				const parsedItem = userState.store?.items.find((item) => item.id === canvasState.preloadId);

				if (!parsedItem) return;

				if (!parsedItem.placementsAssets[position]?.canvas) {
					canvases.push(canvasObject);

					if (canvases.length === canvasPositions.length) {
						canvasDispatch({ type: CanvasAction.SET_CANVASES, payload: canvases });
					}

					return;
				}

				const parsedCanvas = JSON.parse(parsedItem.placementsAssets[position].canvas);

				canvas.loadFromJSON(parsedCanvas, () => {
					let newItemsScale = 1;

					if (parsedCanvas.originalCanvasHeight) {
						const { originalCanvasHeight: prevH, originalCanvasWidth: prevW } = parsedCanvas;
						const cW = canvas.getWidth();
						const cH = canvas.getHeight();
						newItemsScale = Math.min(cH / prevH, cW / prevW);
					}

					canvas.getObjects().forEach((o: CustomFabricObject) => {
						if (o.get('objectType') === CanvasObjectType.CLIP) {
							canvas.remove(o);
						} else {
							fabric.util.clearFabricFontCache();
							o.set('clipPath', undefined);
							if (newItemsScale !== 1) {
								o.set({
									left: Number(o.get('left')) * newItemsScale,
									top: Number(o.get('top')) * newItemsScale,
									scaleX: Number(o.get('scaleX')) * newItemsScale,
									scaleY: Number(o.get('scaleY')) * newItemsScale,
								}).setCoords();
							}
							if (o.get('objectType') === CanvasObjectType.TEXT) {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								o.initDimensions();
							}
						}
					});

					canvas.renderAll();

					setTimeout(() => {
						canvas.getObjects().forEach((o: CustomFabricObject) => {
							if (o.get('objectType') === CanvasObjectType.PATTERN) {
								canvas.remove(o);
								const patternSettings = parsedCanvas.patterns[0];
								const sourceImage = patternSettings.patternSourceImage;

								if (!sourceImage) return;

								fabric.Image.fromURL(
									sourceImage,
									(image) => {
										addImageRaw(
											canvasState,
											canvasObject,
											image,
											'Pattern',
											true,
											(imgObj) => {
												objectToPattern(canvasObject, imgObj, patternSettings, patternSettings.patternType);
											},
											canvas,
										);
									},
									{ crossOrigin: 'anonymous' },
								);
							}
						});
					}, 200);

					canvases.push(canvasObject);

					if (canvases.length === canvasPositions.length) {
						canvasDispatch({ type: CanvasAction.SET_CANVASES, payload: canvases });
					}
				});
			} else {
				canvases.push(canvasObject);

				if (canvases.length === canvasPositions.length) {
					canvasDispatch({ type: CanvasAction.SET_CANVASES, payload: canvases });
				}
			}
		});
	}, [canvasState.ready, canvasState.template]);

	useEffect(() => {
		const placement = product?.placementsAssets[Object.keys(product?.placementsAssets)[0]];
		const shouldNotPreload = placement?.canvas && placement.canvas.length > 0;

		if (!activeCanvas || prevGraphicsLoaded || shouldNotPreload || !canvasState.ready) return;

		const oldLoadedObj = activeCanvas.canvas?.getObjects().find((o: CustomFabricObject) => o.get('oldObject'));

		if (product && !oldLoadedObj && editorState.state === EditorStateAction.EDIT && !prevGraphicsLoaded) {
			setTimeout(() => {
				const imgObj = new Image();

				setPrevGraphicsLoaded(true);

				imgObj.crossOrigin = 'anonymous';
				imgObj.src = product.placementsAssets[Object.keys(product.placementsAssets)[0]].url + `?dc=${Date.now()}`;
				imgObj.onload = () => addImage(canvasState, activeCanvas, imgObj, 'Logo', true);
			}, 250);
		}
	}, [activeCanvas?.canvas, canvasState.ready, product]);

	return (
		<>
			<div style={{ position: 'relative', overflow: 'hidden', height: 'calc(100% - 80px)' }} ref={canvasRef}>
				<div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
					<HotKeysController>
						{canvasState.printSides.map(({ value }) => (
							<div
								style={{ display: canvasState.selectedPrintSide === value ? 'block' : 'none', width: '100%' }}
								key={value}
							>
								<canvas id={value}></canvas>
							</div>
						))}
						<div style={{ display: 'none' }}>
							<canvas id="canvasHiddenDup"></canvas>
						</div>
					</HotKeysController>
				</div>
			</div>
		</>
	);
}
