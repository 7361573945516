import { ActionBlock, ActionTitle, ActionVerticalDivider, lightGreyText } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import CustomFabricObject from '../../types/CustomFabricObject';
import AlignController from './AlignController';
import FillController from './FillController';
import FontFaceController from './FontFaceController';
import FontSizeController from './FontSizeController';
import FontStyleController from './FontStyleController';
import FontWeightController from './FontWeightController';
import StrokeController from './StrokeController';
import TextFormatController from './TextFormatController';

export function TextController() {
	const { activeCanvas } = useActiveCanvas();
	const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;

	if (!activeCanvas || !activeObject) return null;

	return activeCanvas.selectedObject && activeObject.get('objectType') === CanvasObjectType.TEXT ? (
		<>
			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					FONT
				</ActionTitle>
			</ActionBlock>
			<FillController />
			<FontFaceController />
			<FontSizeController />
			<FontWeightController />
			<FontStyleController />
			<StrokeController />
			<ActionVerticalDivider />
			<TextFormatController />
			<ActionVerticalDivider />
			<AlignController />
		</>
	) : null;
}
