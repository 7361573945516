import { Ref, PropsWithChildren, ReactNode, useEffect, useState, useCallback } from 'react';
import { Dropdown, Slot, styled, Text } from '@streamelements/frontend-ui';
import { ActionBlock, ActionVerticalDivider, mediumIcon, smallIcon } from '../../Editor.style';
import Placements from '../../config/Placements.json';
import { useActiveCanvas, useTemplate } from '../../hooks';
import {
	AlignHorizontalLeft,
	AlignHorizontalMiddle,
	AlignHorizontalRight,
	AlignVerticalBottom,
	AlignVerticalMiddle,
	AlignVerticalTop,
	getBoundsAndEdges,
} from '../../store/ActionCreators/ObjectAlignActionCreator';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import { CanvasObject } from '../../types';
import {
	HorizontalDotsIcon,
	FlipVerticalIcon,
	FlipHorizontalIcon,
	AlignVerticalCenterIcon,
	AlignTopIcon,
	AlignRightIcon,
	AlignLeftIcon,
	AlignHorizontalCenterIcon,
	AlignBottomIcon,
} from '../Icons';
import PlacementsIcon from '../Icons/PlacementsIcon';
import ThemedSelect, { ThemedSelectOption } from '../ThemesSelect';

const Trigger = styled(Dropdown.Trigger, {
	border: 0,
	bgc: 'transparent',
	fontWeight: 'black',
	cursor: 'pointer',
	padding: 0,
});

const StyledMoreListItem = styled('div', {
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	columnGap: '$base',
	alignItems: 'center',
	padding: 'calc($base * 1.5)',

	'&:hover': {
		cursor: 'pointer',
		borderRadius: '$base',
		backgroundColor: '$uiPrimary16',
	},
});

interface AlignOption {
	name: string;
	label: ReactNode;
	action: (state: CanvasObject) => void;
}

type Option = {
	label: ReactNode;
	value: AlignOption;
};

const alignOptions: AlignOption[] = [
	{
		name: 'Align Left',
		label: (
			<ThemedSelectOption>
				<AlignLeftIcon className={smallIcon} />
				<Text.Body>Align Left</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignHorizontalLeft,
	},
	{
		name: 'Align Right',
		label: (
			<ThemedSelectOption>
				<AlignRightIcon className={smallIcon} />
				<Text.Body>Align Right</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignHorizontalRight,
	},
	{
		name: 'Align Top',
		label: (
			<ThemedSelectOption>
				<AlignTopIcon className={smallIcon} />
				<Text.Body>Align Top</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignVerticalTop,
	},
	{
		name: 'Align Bottom',
		label: (
			<ThemedSelectOption>
				<AlignBottomIcon className={smallIcon} />
				<Text.Body>Align Bottom</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignVerticalBottom,
	},
	{
		name: 'Vertical Center',
		label: (
			<ThemedSelectOption>
				<AlignVerticalCenterIcon className={smallIcon} />
				<Text.Body>Align Center</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignHorizontalMiddle,
	},
	{
		name: 'Horizontal Center',
		label: (
			<ThemedSelectOption>
				<AlignHorizontalCenterIcon className={smallIcon} />
				<Text.Body>Horizontal Center</Text.Body>
			</ThemedSelectOption>
		),
		action: AlignVerticalMiddle,
	},
];

export default function AlignController() {
	const [options, setOptions] = useState<Option[] | undefined>();
	const { activeCanvas } = useActiveCanvas();
	const { template } = useTemplate();

	const MoreListItem = (props: PropsWithChildren<{ onClick?: (e: any) => void; ref?: Ref<HTMLDivElement> }>) => {
		return (
			<StyledMoreListItem onClick={props.onClick} ref={props?.ref}>
				{props.children}
			</StyledMoreListItem>
		);
	};

	const getOptions = useCallback(() => {
		if (!template) return;

		const extendedOptions = [...alignOptions];

		Placements.forEach((p) => {
			if (template.name && !p.items.includes(template?.name)) {
				return;
			}

			const extraPlacements = p.placements;

			extraPlacements.forEach((t) => {
				extendedOptions.unshift({
					name: t.name,
					label: (
						<ThemedSelectOption>
							<PlacementsIcon className={smallIcon} />
							<Text.Body>{t.name}</Text.Body>
						</ThemedSelectOption>
					),
					action: (state: CanvasObject) => {
						const canvas = activeCanvas?.canvas;
						const selectedObject = activeCanvas?.selectedObject;

						if (!canvas || !selectedObject) return;

						const rect = getBoundsAndEdges(canvas);
						const activeObject = canvas.getActiveObject();
						const { width: ew } = activeObject.getBoundingRect();

						if (t.boundingRect.right) {
							activeObject.set('left', rect.left + (rect.width - ew) - t.boundingRect.right);
						}

						if (t.boundingRect.top) {
							activeObject.set('top', rect.top + t.boundingRect.top);
						}

						if (t.boundingRect.centerV) {
							AlignVerticalMiddle(state);
						}

						canvas.renderAll();
					},
				});
			});
		});

		const selectOpts = extendedOptions.map((o) => ({
			label: o.label,
			value: o,
		}));

		return selectOpts;
	}, [template, activeCanvas?.selectedObject]);

	useEffect(() => {
		setOptions(getOptions());
	}, [getOptions]);

	return (
		<>
			<ActionBlock clearVerticalSpacing>
				<ThemedSelect
					placeholder="Align"
					borderless
					value={null}
					onChange={(data: { value: AlignOption }) => activeCanvas && data.value.action(activeCanvas)}
					defaultValue={options && options[0]}
					options={options}
				/>
			</ActionBlock>
			<ActionVerticalDivider />
			<ActionBlock clearVerticalSpacing>
				<Dropdown.Root>
					<Trigger color="neutral">
						<HorizontalDotsIcon className={mediumIcon} />
					</Trigger>
					<Dropdown.Content>
						<Dropdown.Item as={Slot}>
							<MoreListItem
								onClick={() =>
									activeCanvas &&
									setObjProp(activeCanvas, 'flipX', !activeCanvas.canvas?.getActiveObject().get('flipX'))
								}
							>
								<FlipHorizontalIcon className={smallIcon} />
								<Text.Body>Flip Horizontal</Text.Body>
							</MoreListItem>
						</Dropdown.Item>
						<Dropdown.Item as={Slot}>
							<MoreListItem
								onClick={() =>
									activeCanvas &&
									setObjProp(activeCanvas, 'flipY', !activeCanvas.canvas?.getActiveObject().get('flipY'))
								}
							>
								<FlipVerticalIcon className={smallIcon} />
								<Text.Body>Flip Vertical</Text.Body>
							</MoreListItem>
						</Dropdown.Item>
					</Dropdown.Content>
				</Dropdown.Root>
			</ActionBlock>
		</>
	);
}
