import { useContext, useCallback, useState } from 'react';
import { useTemplate } from '.';
import { saveProduct, updateProduct } from '../services/merchCatalog';
import { EditorsContext, UserContext, CanvasesContext } from '../store';
import { SavePlacementsAssets, EditorStateAction } from '../types';

export function useSaveProduct() {
	const { state: editorState } = useContext(EditorsContext);
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: userState } = useContext(UserContext);
	const { template } = useTemplate();

	const [saveError, setSaveError] = useState<null | unknown>(null);
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

	const executeSaveProduct = useCallback(
		async (placementAssets: SavePlacementsAssets[], published: boolean) => {
			async function handleProductSave() {
				const user = userState.user;

				if (placementAssets.length && user && canvasState.preloadId && template) {
					const placements = {};

					placementAssets.forEach((placement) => {
						const placementObj = {
							[placement.position]: {
								canvas: JSON.stringify(placement.canvas),
								threadColors: placement.threadColors,
								assetURL: placement.assetUrl,
							},
						};

						Object.assign(placements, placementObj);
					});

					const body = {
						catalogProductID: template.id,
						colors: canvasState.activeVariants,
						price: editorState.product.price,
						customDescription: editorState.product.description,
						itemName: editorState.product.name,
						published,
						placements,
					};

					const res =
						editorState.state === EditorStateAction.EDIT
							? await updateProduct(user._id, body, canvasState.preloadId)
							: await saveProduct(user._id, body);

					if (!res.ok) {
						setSaveError(res.status);
					}
				}
			}

			try {
				await handleProductSave();

				setSaveSuccess(true);
			} catch (e) {
				setSaveError(400);
			}
		},
		[canvasState.preloadId, canvasState.activeVariants, editorState.product, userState.user],
	);

	return { executeSaveProduct, saveError, saveSuccess };
}
