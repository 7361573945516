import { uniqBy } from 'lodash';
import { CanvasState, CanvasActions, CanvasAction, CanvasObject } from '../../types';

export const canvasesInitialState = {
	defaultWidth: 800,
	defaultHeight: 600,
	ready: false,
	template: null,
	preloadCanvases: false,
	preloadId: null,
	grid: false,
	canvases: [],
	variants: [],
	activeVariants: [],
	selectedVariant: null,
	printSides: [],
	selectedPrintSide: null,
	error: false,
} as CanvasState;

export function CanvasesReducer(state: CanvasState = canvasesInitialState, action: CanvasActions): CanvasState {
	switch (action.type) {
		case CanvasAction.SET_ACTIVE_TEMPLATE: {
			if (!action.payload.template) return state;

			const { variants, vendorPlacements } = action.payload.template;
			const uniqueVariants = uniqBy(variants, 'color');
			const activeVariants = action.payload.activeVariants;
			const printSideOrder = variants[0].placementKeys;
			const printSides = Object.keys(vendorPlacements)
				.map((key) => ({
					value: key,
					price: vendorPlacements[key].additionalPrice,
				}))
				.sort((a, b) => printSideOrder.indexOf(a.value) - printSideOrder.indexOf(b.value));

			return {
				...state,
				template: action.payload.template,
				preloadCanvases: action.payload.preloadCanvases,
				preloadId: action.payload.preloadId,
				variants: uniqueVariants,
				activeVariants: activeVariants,
				selectedVariant: activeVariants[0],
				selectedPrintSide: printSideOrder ? printSideOrder[0] : Object.keys(vendorPlacements)[0],
				printSides,
			};
		}
		case CanvasAction.SET_ACTIVE_PRINTSIDE: {
			const activeCanvas = state.canvases.find((c) => c.position === action.payload);

			return {
				...state,
				defaultWidth: activeCanvas?.width || state.defaultWidth,
				defaultHeight: activeCanvas?.height || state.defaultHeight,
				selectedPrintSide: action.payload,
			};
		}
		case CanvasAction.SET_ACTIVE_VARIANT:
			return {
				...state,
				selectedVariant: action.payload,
			};
		case CanvasAction.SET_ACTIVE_VARIANTS:
			return {
				...state,
				activeVariants: action.payload,
			};
		case CanvasAction.SET_CANVASES:
			return {
				...state,
				defaultWidth: action.payload[0].width || state.defaultWidth,
				defaultHeight: action.payload[0].height || state.defaultHeight,
				canvases: action.payload,
				ready: true,
			};
		case CanvasAction.SET_GRID_ENABLED:
			return {
				...state,
				grid: action.payload,
			};
		case CanvasAction.SET_LAYERS: {
			const activeCanvas = state.canvases.find((c) => c.position === state.selectedPrintSide);
			const filteredCanvases = state.canvases.filter((c) => c.position !== state.selectedPrintSide);

			let canvases = [] as CanvasObject[];

			if (!activeCanvas) return state;

			if (filteredCanvases.length) canvases = filteredCanvases;

			return {
				...state,
				canvases: [
					...canvases,
					{
						...activeCanvas,
						layers: action.payload.layers,
						visibleLayersCount: action.payload.count,
					},
				],
			};
		}
		case CanvasAction.SET_SELECTED_OBJECT: {
			const activeCanvas = state.canvases.find((c) => c.position === state.selectedPrintSide);
			const filteredCanvases = state.canvases.filter((c) => c.position !== state.selectedPrintSide);

			let canvases = [] as CanvasObject[];

			if (!activeCanvas) return state;

			if (filteredCanvases.length) canvases = filteredCanvases;

			return {
				...state,
				canvases: [
					...canvases,
					{
						...activeCanvas,
						selectedObject: action.payload,
					},
				],
			};
		}
		case CanvasAction.SET_THREADS: {
			const activeCanvas = state.canvases.find((c) => c.position === state.selectedPrintSide);
			const filteredCanvases = state.canvases.filter((c) => c.position !== state.selectedPrintSide);

			let canvases = [] as CanvasObject[];

			if (!activeCanvas) return state;

			if (filteredCanvases.length) canvases = filteredCanvases;

			return {
				...state,
				canvases: [
					...canvases,
					{
						...activeCanvas,
						selectedThreads: action.payload,
					},
				],
			};
		}
		case CanvasAction.SET_ERROR:
			return {
				...state,
				error: action.payload,
			};
		default:
			throw new Error();
	}
}
