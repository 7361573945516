import { useEffect, useState } from 'react';
import { Modal, Button, Text, Checkbox, FormControlLabel, styled } from '@streamelements/frontend-ui';
import { StyledModalContent, StyledModalOverlay } from './Modal.styles';
import { Badge } from '../Badge';
import { useScience } from '../../hooks';
import { ScienceEvent, ScienceSource } from '../../enums';

const StyledImageList = styled('ul', {
  display: 'grid',
  alignItems: 'center',
  textAlign: 'center',
  my: '2rem',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridRowGap: '20px',
  gridColumnGap: '10px',

  li: {
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center',
  },

  h6: {
    color: '$textAlwaysDark70',
    minHeight: '45px',
  },

  img: {
    margin: '0 auto 10px',
  },

  'img:first-child': {
    height: '64px',
  }
});

const MODAL_IMAGES_BASE_URL = 'https://cdn.streamelements.com/merch/product-editor/embroidery-modal';

const embroideryData = [
  {
    number: 1,
    src: `${MODAL_IMAGES_BASE_URL}/bold-emoji.png`,
    caption: <Text.Subtitle weight='bold'>Keep it bold and simple</Text.Subtitle>,
    error: false,
  },
  {
    number: 2,
    src: `${MODAL_IMAGES_BASE_URL}/colors-rainbow.png`,
    caption: <Text.Subtitle weight='bold'>Max: 6 Colors</Text.Subtitle>,
    error: false,
  },
  {
    number: 3,
    src: `${MODAL_IMAGES_BASE_URL}/love-icon.png`,
    caption: <Text.Subtitle weight='bold'>Stick to large, bold fonts</Text.Subtitle>,
    error: false,
  },
  {
    number: 4,
    src: `${MODAL_IMAGES_BASE_URL}/negative.png`,
    caption: <Text.Subtitle weight='bold'>No negative space (cut outs)</Text.Subtitle>,
    error: true,
  },
  {
    number: 5,
    src: `${MODAL_IMAGES_BASE_URL}/gradient-textures.png`,
    caption: <Text.Subtitle weight='bold'>Avoid textures and gradients</Text.Subtitle>,
    error: true,
  },
  {
    number: 6,
    src: `${MODAL_IMAGES_BASE_URL}/photo.png`,
    caption: <Text.Subtitle weight='bold'>Don't upload photographs</Text.Subtitle>,
    error: true,
  },
];

export function EmbroideryModal() {
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(true);
  const [hasAccepted, setHasAccepeted] = useState(false);
  const { trackEvent } = useScience();

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      
      trackEvent({
        name: ScienceEvent.INFO_BANNER_APPEAR,
        source: ScienceSource.EDITOR_PANEL,
      });
    }
  }, [loaded, setLoaded, trackEvent]);

  return (
    <Modal.Root defaultOpen={true} open={open}>
      <StyledModalOverlay />
      <StyledModalContent css={{ maxWidth: 525, overflowY: 'auto' }}>
        <Text.Heading as="h5" level="5" weight="bold" css={{ mb: '$base' }}>
          Embroidery
          <Badge css={{ display: 'inline-block' }} type="beta">beta</Badge>
        </Text.Heading>
        <Text.Body css={{ textAlign: 'center', padding: '0 1.5rem' }}>
          For best results, please consider the following while preparing your artwork and product for embroidery:
        </Text.Body>
        <StyledImageList>
          {embroideryData.map(({ src, caption, number, error }) => (
            <li key={`tip-${number}`}>
              <img src={src} alt={ error ? 'Do not do this' : 'do this'} />
              {error && <img src={`${MODAL_IMAGES_BASE_URL}/error-icon.png`} alt="Don't do this" />}
              {caption}
            </li>
          ))}
        </StyledImageList>
        <FormControlLabel css={{ padding: '0 2rem 2rem', color: '$textAlwaysDark70', cursor: 'pointer' }}>
          <Checkbox css={{ marginRight: '1rem' }} onCheckedChange={(e) => setHasAccepeted(e.target.checked)} />
          <Text.Body>
            I have read all the <a
              href="https://support.streamelements.com/hc/en-us/articles/10474807174290-SE-Merch-Embroidery-Guidelines"
              title="Embroidery Guidelines"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'inline' }}
            >Embroidery Guidelines</a> and understand that, if I don't follow them, my design may be placed in pending status.
          </Text.Body>
        </FormControlLabel>
        <Button onClick={() => setOpen(!open)} disabled={!hasAccepted} css={{ padding: '0 2.5rem' }}>Let's Go</Button>
      </StyledModalContent>
    </Modal.Root>
  );
};
