import { useCallback, useContext, useEffect, useState } from 'react';
import { CloseRounded } from '@streamelements/frontend-icons';
import { styled, Modal, TextField, Text, Button } from '@streamelements/frontend-ui';
import { halfGreyText } from '../../Editor.style';
import { ScienceSource, ScienceEvent } from '../../enums';
import { useProduct, useScience, useTemplate } from '../../hooks';
import { EditorsContext } from '../../store';
import { EditorAction } from '../../types';
import { StyledModalContent, StyledModalOverlay, StyledModalClose, StyledButtonGroup } from './Modal.styles';

const MAX_PRICE = 99;

const SaveModalContent = styled(StyledModalContent, {
	display: 'flex',
	flexDirection: 'column',
	width: '550px',
});

const PriceContainer = styled('div', {
	display: 'grid',
	gridTemplateColumns: '1fr 100px',
	gap: 'calc($base * 2)',
});

interface Props {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onSave: (publish: boolean) => void;
}

export function SaveModal({ open, onOpenChange, onSave }: Props) {
	const { state: editorState, dispatch: editorsDispatch } = useContext(EditorsContext);
	const { template } = useTemplate();
	const { product } = useProduct();
	const [name, setName] = useState<string>('');
	const [price, setPrice] = useState<number>(editorState.product.recommendedPrice);
	const [description, setDescription] = useState<string>('');
	const { trackEvent } = useScience();

	const [nameError, setNameError] = useState<string>();
	const [priceError, setPriceError] = useState<string>();

	const onSaveClicked = useCallback(
		(publish: boolean) => {
			setNameError(undefined);
			setPriceError(undefined);

			let hasError = false;

			if (name.length > 50 || name.length < 3) {
				setNameError('Name should be between 3 - 50 characters');
				hasError = true;
			}

			if ((template && price < editorState.product.basePrice) || price > MAX_PRICE) {
				setPriceError('Price should be between 0 - 99');
				hasError = true;
			}

			if (!hasError) {
				trackEvent({
					name: publish ? ScienceEvent.SAVE_AND_PUBLISH : ScienceEvent.SAVE,
					source: ScienceSource.SAVING_POPUP_WINDOW,
					placement: publish ? 'save_and_publish_button' : 'save_button',
				});

				onSave(publish);
			}
		},
		[description, editorsDispatch, name, onSave, price, editorState.product.basePrice, trackEvent],
	);

	useEffect(() => {
		if (!template && !product) return;

		setName(product ? product.title : template?.name || ' ');
		setDescription(product ? product.description : ' ');
	}, [template, product]);

	useEffect(() => {
		setPrice(editorState.product.recommendedPrice);
	}, [editorState.product.recommendedPrice]);

	useEffect(() => {
		if (name) editorsDispatch({ type: EditorAction.SET_PRODUCT_NAME, payload: name });
	}, [name]);

	useEffect(() => {
		if (description) editorsDispatch({ type: EditorAction.SET_PRODUCT_DESCRIPTION, payload: description });
	}, [description]);

	useEffect(() => {
		editorsDispatch({ type: EditorAction.SET_PRODUCT_PRICE, payload: price });
	}, [price]);

	return (
		<Modal.Root open={open} onOpenChange={onOpenChange}>
			<StyledModalOverlay />
			<SaveModalContent>
				<StyledModalClose>
					<CloseRounded />
				</StyledModalClose>
				<Text.Heading level={5} weight="bold" style={{ textAlign: 'center' }}>
					Product Details
				</Text.Heading>
				<Text.Subtitle style={{ textAlign: 'center' }} className={halfGreyText}>
					Name it, price it, and let’s get it into your store
				</Text.Subtitle>
				<span />

				<TextField.Root
					value={name}
					onChange={(e) => setName(e.target.value)}
					label="Item name"
					error={!!nameError}
					css={{ width: '100%', mt: '1rem' }}
					maxLength={50}
				>
					<TextField.HelperText>{nameError}</TextField.HelperText>
					<TextField.CharLimitIndicator />
				</TextField.Root>

				<TextField.Root
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					label="Creator Note (optional)"
					placeholder="Your personal note will appear above the product details for this item in your store"
					multiline
					maxLength={250}
					css={{ width: '100%', mt: '1rem' }}
					inputProps={{ rows: 4 }}
				>
					<TextField.CharLimitIndicator />
				</TextField.Root>

				<PriceContainer css={{ width: '100%', mt: '1rem' }}>
					<TextField.Root
						value={price}
						error={!!priceError}
						onChange={(e) => setPrice(Number(e.target.value))}
						label={`Price (minimum: $${editorState.product.basePrice.toFixed(2)})`}
						type="number"
					>
						<TextField.Adornment position="start">$</TextField.Adornment>
						<TextField.HelperText>{priceError}</TextField.HelperText>
					</TextField.Root>
					<Text.Body
						variant="caption"
						weight="bold"
						css={{
							textAlign: 'center',
							lineHeight: '2.25rem',
							color: '$uiPrimaryMain',
						}}
					>
						${(Number(price) - Number(editorState.product.basePrice)).toFixed(2)} profit
					</Text.Body>
				</PriceContainer>

				<StyledButtonGroup>
					<Button variant="outlined" onClick={() => onSaveClicked(false)}>
						Save
					</Button>
					<Button onClick={() => onSaveClicked(true)}>Save & Publish</Button>
				</StyledButtonGroup>
			</SaveModalContent>
		</Modal.Root>
	);
}
