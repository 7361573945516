/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import { useEffect, useState } from 'react';
import { Slider } from '@streamelements/frontend-ui';
import { ActionBlock, ActionTitle, lightGreyText } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import CustomFabricObject from '../../types/CustomFabricObject';
import { IObjectBoundingRect } from '../../types/IObjectBoundingRect';
import { PatternType } from '../../types/PatternType';

export default function PatternLayerController() {
	const { activeCanvas } = useActiveCanvas();
	const [patternType, setPatternType] = useState<PatternType>('FullDrop');
	const [imageWidth, setImageWidth] = useState<number>(100);
	const [imagePadding, setImagePadding] = useState<number>(0);
	const [originGB, setOriginGB] = useState<IObjectBoundingRect>();
	const [originImageB, setOriginImageB] = useState<IObjectBoundingRect[]>([]);
	const supportedTypes: Array<CanvasObjectType | undefined> = [CanvasObjectType.PATTERN];

	useEffect(() => {
		if (!activeCanvas?.selectedObject) return;

		const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;
		const activeObjectFill = activeObject?.fill as fabric.Pattern;
		// @ts-ignore
		const sourceImage = activeObjectFill.sourceImage as fabric.Group;

		if (!sourceImage) return;

		setOriginGB({
			width: Math.floor(Number(sourceImage.getScaledWidth())),
			height: Math.floor(Number(sourceImage.getScaledHeight())),
			top: 0,
			left: 0,
		});

		sourceImage.getObjects().forEach((img, i) => {
			setOriginImageB((p) => {
				p[i] = img.getBoundingRect();
				return p;
			});
		});
	}, [activeCanvas?.selectedObject]);

	useEffect(() => {
		if (!activeCanvas?.selectedObject) return;

		const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;
		// @ts-ignore
		const patternDefaultSettings = activeObject.defaultSettings;

		if (patternDefaultSettings) {
			setPatternType(patternDefaultSettings.patternType);
			patternDefaultSettings.patternWidth && setImageWidth(Number(patternDefaultSettings.patternWidth));
			patternDefaultSettings.patternPadding && setImagePadding(Number(patternDefaultSettings.patternPadding));
		}
	}, [activeCanvas?.selectedObject]);

	useEffect(() => {
		if (!imageWidth || !originGB || !activeCanvas?.canvas || !activeCanvas.selectedObject) return;

		const activeObject = activeCanvas?.canvas?.getActiveObject() as CustomFabricObject;
		const activeObjectFill = activeObject?.fill as fabric.Pattern;

		// @ts-ignore
		const sourceCanvas = activeObjectFill.sourceCanvas as fabric.Canvas;
		// @ts-ignore
		const sourceImage = activeObjectFill.sourceImage as fabric.Group;

		if (sourceCanvas && sourceImage) {
			// @ts-ignore
			const patternSettings = activeObject.defaultSettings;

			if (
				patternSettings.patternWidth === imageWidth &&
				patternSettings.patternPadding === imagePadding &&
				patternSettings.patternType === patternType &&
				patternSettings.init
			) {
				return;
			}

			patternSettings.init = true;

			// @ts-ignore
			activeObject.defaultSettings.patternWidth = imageWidth;
			// @ts-ignore
			activeObject.defaultSettings.patternPadding = imagePadding;
			// @ts-ignore
			activeObject.defaultSettings.patternType = patternType;

			sourceImage.getObjects().forEach((img, i) => {
				const boundRect = originImageB[i];
				const scaledPadding = imagePadding * (Number(sourceImage.get('width')) / sourceImage.getScaledWidth());
				img.scaleToWidth((imageWidth / 100) * boundRect.width);

				switch (i) {
					case 0:
						img.set({
							top: Number(boundRect.top),
						});
						break;

					case 1:
						if (patternType === 'HalfDrop') {
							img.set({
								top: Number(boundRect.top) - scaledPadding,
								left: Number(boundRect.left) + scaledPadding,
							});
						} else {
							img.set({
								top: Number(boundRect.top) + scaledPadding,
								left: Number(boundRect.left) - scaledPadding,
							});
						}
						break;

					case 2:
						if (patternType === 'HalfDrop') {
							img.set({
								top: Number(boundRect.top) + scaledPadding,
								left: Number(boundRect.left) + scaledPadding,
							});
						} else {
							img.set({
								top: Number(boundRect.top) + scaledPadding,
								left: Number(boundRect.left) + scaledPadding,
							});
						}
						break;
				}
			});

			sourceCanvas.setDimensions({
				width: sourceImage.getScaledWidth() + imagePadding * 2,
				height: sourceImage.getScaledHeight() + imagePadding * 2,
			});

			sourceCanvas.renderAll();
			activeCanvas?.canvas.renderAll();
		}
	}, [
		imageWidth,
		activeCanvas?.canvas,
		imagePadding,
		originGB,
		originImageB,
		activeCanvas?.selectedObject,
		patternType,
	]);

	return activeCanvas?.selectedObject && supportedTypes.includes(activeCanvas?.selectedObject.get('objectType')) ? (
		<>
			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					Size
				</ActionTitle>
			</ActionBlock>
			<ActionBlock>
				<Slider.Root
					min={50}
					max={200}
					value={[imageWidth]}
					style={{ width: 40 }}
					onValueChange={(v) => setImageWidth(v[0])}
				/>
			</ActionBlock>

			<ActionBlock>
				<ActionTitle variant="caption" weight="bold" className={lightGreyText}>
					Spacing
				</ActionTitle>
			</ActionBlock>
			<ActionBlock>
				<Slider.Root
					min={-25}
					max={150}
					value={[imagePadding]}
					style={{ width: 40 }}
					onValueChange={(v) => setImagePadding(v[0])}
				/>
			</ActionBlock>
		</>
	) : null;
}
