import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useActiveCanvas } from '../../hooks';
import { clearProperties } from '../../utils';

interface Props {
	onHistoryChanged?: (historyLength: number, mods: number) => void;
}

export function HistoryController(props: PropsWithChildren<Props>) {
	const { activeCanvas } = useActiveCanvas();
	const [history, setHistory] = useState<string[]>([]);
	const [mods, setMods] = useState<number>(0);
	const [locked, setLocked] = useState<boolean>(false);

	const getCanvasState = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return '';

		return canvas.toDatalessJSON(clearProperties);
	}, [activeCanvas?.canvas]);

	const historyChanged = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas || locked) return;

		setMods(0);
		setHistory((prevHistory) => [...prevHistory, getCanvasState()]);
	}, [activeCanvas?.canvas, getCanvasState, locked]);

	function clear() {
		setHistory([]);
		setMods(0);
		setLocked(false);
		historyChanged();
	}

	const undo = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		setLocked(true);

		if (mods < history.length) {
			canvas.loadFromJSON(history[history.length - 1 - mods - 1], () => {
				canvas?.renderAll();
				setMods((prevMods) => prevMods + 1);
				setLocked(false);
			});
		}
	}, [activeCanvas?.canvas, mods, history]);

	const redo = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		setLocked(true);

		if (mods > 0) {
			canvas.loadFromJSON(history[history.length - 1 - mods + 1], () => {
				canvas?.renderAll();
				setMods((prevMods) => prevMods - 1);
				setLocked(false);
			});
		}
	}, [activeCanvas?.canvas, mods, history]);

	const bindEvents = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		canvas.on('objects:loaded', historyChanged);
		canvas.on('object:manualChanged', historyChanged);
		canvas.on('object:added', historyChanged);
		canvas.on('object:removed', historyChanged);
		canvas.on('object:modified', historyChanged);

		canvas.on('history:clear', clear);
		canvas.on('history:undo', undo);
		canvas.on('history:redo', redo);
	}, [activeCanvas?.canvas, historyChanged, clear, undo, redo]);

	const unbindEvents = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;
		canvas.off('objects:loaded', historyChanged);
		canvas.off('object:manualChanged', historyChanged);
		canvas.off('object:added', historyChanged);
		canvas.off('object:removed', historyChanged);
		canvas.off('object:modified', historyChanged);

		canvas.off('history:clear', clear);
		canvas.off('history:undo', undo);
		canvas.off('history:redo', redo);
	}, [activeCanvas?.canvas, historyChanged, clear, undo, redo]);

	useEffect(() => {
		bindEvents();

		return unbindEvents;
	}, [bindEvents, unbindEvents]);

	useEffect(() => {
		props.onHistoryChanged && props.onHistoryChanged(history.length, mods);
	}, [props, history, mods]);

	return null;
}
