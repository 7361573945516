import { ActionVerticalDivider } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import CustomFabricObject from '../../types/CustomFabricObject';
import AlignController from './AlignController';
import ImageFiltersController from './ImageFiltersController';
import PatternController from './PatternController';
import PatternLayerController from './PatternLayerController';

export default function ImageController() {
	const { activeCanvas } = useActiveCanvas();

	if (!activeCanvas?.canvas) return null;

	const activeObject = activeCanvas.canvas?.getActiveObject() as CustomFabricObject;
	const supportedTypes: Array<CanvasObjectType | undefined> = [
		CanvasObjectType.IMAGE,
		CanvasObjectType.SVG,
		CanvasObjectType.PATTERN,
	];

	if (!activeObject) return null;

	return activeCanvas.selectedObject && supportedTypes.includes(activeObject.get('objectType')) ? (
		<>
			<PatternController />
			{activeObject.get('objectType') === CanvasObjectType.PATTERN && <PatternLayerController />}
			<ActionVerticalDivider />
			<ImageFiltersController />
			<ActionVerticalDivider />
			<AlignController />
		</>
	) : null;
}
