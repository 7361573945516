import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { CloseRounded } from '@streamelements/frontend-icons';
import { styled, keyframes, Text, Modal, Button } from '@streamelements/frontend-ui';
import { useErrorState, useInterval } from '../../../hooks';
import { ErrorTypes } from '../../../types';
import { StyledModalClose } from '../../Modals/Modal.styles';

const overlayShow = keyframes({
	'0%': { opacity: 0 },
	'100%': { opacity: 1 },
});

const contentShow = keyframes({
	'0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
	'100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const StyledErrorOverlay = styled(Modal.Overlay, {
	position: 'fixed',
	inset: 0,

	'@media (prefers-reduced-motion: no-preference)': {
		animation: `${overlayShow} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
});

const StyledErrorContent = styled(Modal.Content, {
	padding: '2rem 3.125rem',
	boxSizing: 'border-box',
	width: '550px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',

	'@media (prefers-reduced-motion: no-preference)': {
		animation: `${contentShow} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
});

const StyledText = styled(Text.Body, {
	color: '$textAlwaysDark70',
	textAlign: 'center',
	letterSpacing: '0.1px',
	lineHeight: '140%',
	fontSize: '1rem',

	span: {
		fontWeight: 'bold',
	},
});

const StyledButtonContainer = styled('div', {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	'button, a': {
		margin: '0 0.4rem',
	},
});

export function ErrorController({ openSaveModal }: { openSaveModal: Dispatch<SetStateAction<boolean>> }) {
	const { error, setErrorState } = useErrorState();
	const [timeout, setTimeout] = useState(0);

	useEffect(() => {
		if (error.timeout) setTimeout(error.timeout);
	}, [error.timeout]);

	useInterval(
		() => {
			setTimeout(timeout - 1);
		},
		timeout ? 1000 : null,
	);

	return (
		<Modal.Root open={error.state} onOpenChange={() => setErrorState()}>
			<StyledErrorOverlay />
			<StyledErrorContent>
				<StyledModalClose css={{ padding: '0.75rem' }}>
					<CloseRounded />
				</StyledModalClose>
				<Text.Heading as="h4" weight="bold" css={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
					{error.title}
				</Text.Heading>
				<StyledText css={{ margin: error.timeout ? '$base 0 0' : '$base 0 2rem' }}>{error.message}</StyledText>
				{error.timeout && (
					<StyledText css={{ margin: '0 0 2rem' }}>
						Please try again in <span>{timeout} seconds</span>.
					</StyledText>
				)}
				<StyledButtonContainer>
					<Button as="a" href={error.link} target="_blank" rel="nofollow" variant="outlined">
						Contact Support
					</Button>
					{ErrorTypes.TOO_MANY_REQUESTS ? (
						<Modal.Close disabled={timeout !== 0} onClick={() => openSaveModal(true)} as={Button}>
							Retry
						</Modal.Close>
					) : (
						<Modal.Close as={Button}>Edit Design</Modal.Close>
					)}
				</StyledButtonContainer>
			</StyledErrorContent>
		</Modal.Root>
	);
}
