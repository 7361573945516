import { PropsWithChildren } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Dropdown, styled, TextField } from '@streamelements/frontend-ui';
import { extraTinyIcon } from '../Editor.style';
import { ChevronDownIcon } from './Icons';

interface ColorPickerProps {
	value?: string | undefined;
	onChange: (v: string) => void;
	items?: { name: string; value: string }[];
}

const Trigger = styled(Dropdown.Trigger, {
	border: 'none',
	background: 'transparent',
	padding: 0,
	display: 'grid',
	gridAutoFlow: 'column',
	columnGap: 'calc($base * 0.5)',
	placeItems: 'center',
	cursor: 'pointer',
});

const SelectedColorCircle = styled('div', {
	border: '1px solid #ededed',
	width: 18,
	height: 18,
	borderRadius: '10em',
});

const Content = styled(Dropdown.Content, {
	display: 'grid',
	gridRowGap: '$base',
	width: 176,
	marginTop: '$base',
});

export function ColorPicker(props: PropsWithChildren<ColorPickerProps>) {
	const onInputChange = (value: string) => props.onChange('#' + value);

	return (
		<Dropdown.Root>
			<Trigger>
				<SelectedColorCircle style={{ backgroundColor: props.value }} />
				<ChevronDownIcon className={extraTinyIcon} />
			</Trigger>
			<Content>
				<HexColorPicker style={{ width: '100%' }} color={props.value} onChange={props.onChange} />
				<TextField.Root
					maxLength={7}
					value={props.value?.toUpperCase().replace('#', '')}
					onChange={(e) => onInputChange(e.target.value)}
				>
					<TextField.Adornment position="start">#</TextField.Adornment>
				</TextField.Root>
			</Content>
		</Dropdown.Root>
	);
}
