import { useContext } from 'react';
import { styled } from '@streamelements/frontend-ui';
import { ProductActions, HelpButton } from '../../components';
import { SaveModal, EmbroideryModal, ProductPreviewModal, MultiSideSaveModal } from '../../components/Modals';
import { ScienceEvent, ScienceSource } from '../../enums';
import { useActiveCanvas, useTemplate, useCanvasErrorState, useScience } from '../../hooks';
import { CanvasesContext, EditorsContext } from '../../store';

const ActionWrap = styled('section', {
	display: 'flex',
	position: 'absolute',
	alignItems: 'center',
	top: 'calc($base * 2)',
	right: 'calc($base * 3)',
	zIndex: 5,
	gap: 'calc($base * 2)',
});

interface ProductActionsContainerProps {
	onSave: (publish: boolean) => void;
	saveModal: {
		setSaveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
		saveModalOpen: boolean;
	};
	previewModal: {
		setPreviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
		previewModalOpen: boolean;
	};
	multiSideSaveModal: {
		setMultiSideSaveOpen: React.Dispatch<React.SetStateAction<boolean>>;
		multiSideSaveOpen: boolean;
	};
}

export function ProductActionsContainer({
	onSave,
	saveModal,
	previewModal,
	multiSideSaveModal,
}: ProductActionsContainerProps) {
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: editorState } = useContext(EditorsContext);
	const { activeCanvas } = useActiveCanvas();
	const { template } = useTemplate();
	const { error } = useCanvasErrorState();
	const { trackEvent } = useScience();

	const isMultiside = canvasState.printSides.length > 1;

	if (!activeCanvas || !template || !canvasState) return null;

	function handleSaveButtonClicked() {
		console.log(canvasState);
		if (activeCanvas?.isEmbroidery) {
			previewModal.setPreviewModalOpen(true);

			return;
		}

		if (isMultiside) {
			multiSideSaveModal.setMultiSideSaveOpen(true);

			return;
		}

		saveModal.setSaveModalOpen(true);

		trackEvent({
			name: ScienceEvent.SAVE_ITEM,
			source: ScienceSource.EDITOR_PANEL,
			placement: 'next_button',
		});
	}

	const saveData = {
		onSave: handleSaveButtonClicked,
		disabled: !!error || editorState.product.placements.length === 0,
		placements: editorState.product.placements,
	};

	return (
		<ActionWrap>
			<HelpButton />

			<SaveModal open={saveModal.saveModalOpen} onOpenChange={saveModal.setSaveModalOpen} onSave={onSave} />

			{activeCanvas?.isEmbroidery && (
				<>
					<EmbroideryModal />
					<ProductPreviewModal
						open={previewModal.previewModalOpen}
						onOpenChange={previewModal.setPreviewModalOpen}
						onSave={saveModal.setSaveModalOpen}
					/>
				</>
			)}

			{isMultiside && (
				<>
					<MultiSideSaveModal
						open={multiSideSaveModal.multiSideSaveOpen}
						onOpenChange={multiSideSaveModal.setMultiSideSaveOpen}
						onSave={saveModal.setSaveModalOpen}
					/>
				</>
			)}

			{template.productType && activeCanvas.canvas && (
				<ProductActions
					isEmbroidery={activeCanvas.isEmbroidery}
					isMultiside={canvasState.printSides.length > 1}
					productType={template.productType}
					canvasObject={activeCanvas}
					clip={activeCanvas?.clip}
					saveData={saveData}
				/>
			)}
		</ActionWrap>
	);
}
