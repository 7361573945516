import { Modal, styled, keyframes } from '@streamelements/frontend-ui';

const overlayShow = keyframes({
	'0%': { opacity: 0 },
	'100%': { opacity: 1 },
});

const contentShow = keyframes({
	'0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
	'100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const StyledModalContent = styled(Modal.Content, {
	padding: '2rem 1.5rem',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	boxSizing: 'border-box',

	'@media (prefers-reduced-motion: no-preference)': {
		animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
});

export const StyledModalClose = styled(Modal.Close, {
	position: 'absolute',
	top: 0,
	right: 0,
	background: 'none',
	border: 'none',
	padding: '1rem',
	cursor: 'pointer',
	color: '$textAlwaysDark50',
	transition: '0.2s color',

	'&:hover': {
		color: '$textAlwaysDark',
	},
});

export const StyledModalOverlay = styled(Modal.Overlay, {
	position: 'fixed',
	inset: 0,
	'@media (prefers-reduced-motion: no-preference)': {
		animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
});

export const StyledButtonGroup = styled('div', {
	display: 'flex',
	mt: '1.5rem',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',

	button: {
		mx: '0.5rem',
	},
});
