import { uniq } from 'lodash';
import { createContext, useReducer, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { canvasesInitialState, CanvasesReducer } from '.';
import { UserContext, TemplatesContext } from '../';
import { CanvasState, CanvasActions, CanvasAction } from '../../types';

export const CanvasesContext = createContext<{
	state: CanvasState;
	dispatch: React.Dispatch<CanvasActions>;
}>({
	state: canvasesInitialState,
	dispatch: () => null,
});

export const CanvasesProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(CanvasesReducer, canvasesInitialState);
	const { state: userState } = useContext(UserContext);
	const { state: templateState } = useContext(TemplatesContext);

	const location = useLocation();
	const query = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location]);
	const templateId = query.get('id');

	useEffect(() => {
		if (templateState.list.length && templateId) {
			const activeTemplate = userState?.store?.items?.find((item) => item.id === templateId);

			const template =
				templateState.list.find((template) => template.id === (activeTemplate?.catalogProductId || templateId)) || null;
			const activeVariants = activeTemplate
				? uniq(activeTemplate?.variants.map((variant) => variant.color))
				: uniq(template?.variants.filter((variant) => variant.bootstrap).map((variant) => variant.color) || []);

			dispatch({
				type: CanvasAction.SET_ACTIVE_TEMPLATE,
				payload: {
					template,
					activeVariants,
					preloadCanvases: activeTemplate ? true : false,
					preloadId: templateId,
				},
			});
		}
	}, [userState, templateState]);

	return <CanvasesContext.Provider value={{ state, dispatch }}>{children}</CanvasesContext.Provider>;
};
