import { PropsWithChildren } from 'react';
import { styled, Button } from '@streamelements/frontend-ui';
import { smallIconButton } from '../Editor.style';
import { useActiveCanvas } from '../hooks';
import { addShape } from '../store/ActionCreators/CanvasActionCreator';
import SvgIcon from './SvgIcon';

const Container = styled('div', {
	padding: '$base',
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gap: '$base',
});

const availableShapes = [
	{
		name: 'Circle',
		link: '/svg-objects/circle.svg',
	},
	{
		name: 'Rect',
		link: '/svg-objects/rect.svg',
	},
	{
		name: 'RectSkew',
		link: '/svg-objects/rectangleSkew.svg',
	},
	{
		name: 'Triangle',
		link: '/svg-objects/triangle.svg',
	},
	{
		name: 'Polygon',
		link: '/svg-objects/polygon.svg',
	},
	{
		name: 'HalfCircle',
		link: '/svg-objects/halfCircle.svg',
	},
	{
		name: 'HighRect',
		link: '/svg-objects/highRect.svg',
	},
	{
		name: 'Star',
		link: '/svg-objects/star.svg',
	},
	{
		name: 'ArrowDown',
		link: '/svg-objects/arrowDown.svg',
	},
	{
		name: 'Diamond',
		link: '/svg-objects/diamond.svg',
	},
];

interface Props {
	onSelect?: () => void;
}

export function ShapeChoosePartial(props: PropsWithChildren<Props>) {
	const { activeCanvas } = useActiveCanvas();

	if (!activeCanvas) return null;

	return (
		<Container>
			{availableShapes.map((s) => (
				<Button
					variant="ghost"
					color="neutral"
					iconButton
					className={smallIconButton}
					key={s.name}
					onClick={() => {
						addShape(activeCanvas, s.link, s.name);
						props.onSelect && props.onSelect();
					}}
				>
					<SvgIcon icon={s.link} alt={s.name} height={20} />
				</Button>
			))}
		</Container>
	);
}
