/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import type { IBaseFilter } from 'fabric/fabric-impl';
import { ReactNode } from 'react';
import { Text } from '@streamelements/frontend-ui';
import { smallIcon } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import BlackAndWhiteIcon from '../Icons/BlackAndWhiteIcon';
import InvertIcon from '../Icons/InvertIcon';
import ThemedSelect, { ThemedSelectOption } from '../ThemesSelect';

type FilterType = 'invert' | 'grayscale';

interface FilterOption {
	label: ReactNode;
	value: FilterType;
	applier: IBaseFilter;
}

const availableFilters: FilterOption[] = [
	{
		label: (
			<ThemedSelectOption>
				<InvertIcon className={smallIcon} />
				<Text.Body>Invert</Text.Body>
			</ThemedSelectOption>
		),
		value: 'invert',
		applier: new fabric.Image.filters.Invert(),
	},
	{
		label: (
			<ThemedSelectOption>
				<BlackAndWhiteIcon className={smallIcon} />
				<Text.Body>Black and white</Text.Body>
			</ThemedSelectOption>
		),
		value: 'grayscale',
		applier: new fabric.Image.filters.Grayscale(),
	},
];

export default function ImageFiltersController() {
	const { activeCanvas } = useActiveCanvas();

	const toggleFilter = (filter: FilterOption) => {
		const activeObject = activeCanvas?.canvas?.getActiveObject() as fabric.Image;

		if (!activeObject || !activeCanvas) return;

		if (!activeObject.filters?.length) activeObject.filters = [];

		// @ts-ignore
		if (activeObject.filters.map((f) => f.type.toLowerCase()).includes(filter.value)) {
			// @ts-ignore
			activeObject.filters = activeObject.filters.filter((f) => f.type.toLowerCase() !== filter.value);
		} else {
			activeObject.filters.push(filter.applier);
		}

		activeObject.applyFilters();
		activeCanvas.canvas?.renderAll();
	};

	return (
		<ThemedSelect
			bigValue={true}
			placeholder="Filter"
			options={availableFilters}
			onChange={(o: FilterOption) => toggleFilter(o)}
			borderless
		/>
	);
}
