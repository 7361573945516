import { useState, useEffect } from 'react';
import { Button } from '@streamelements/frontend-ui';
import * as S from '../../Editor.style';
import { CanvasObjectType } from '../../enums';
import { groupObjects, ungroupObjects } from '../../store/ActionCreators/CanvasActionCreator';
import { CanvasObject } from '../../types';
import { HistoryController } from '../Controllers';
import { UndoIcon, RedoIcon, UngroupIcon, GroupIcon } from '../Icons';

export function LayerActions({ activeCanvas }: { activeCanvas: CanvasObject }) {
	const [historyLength, setHistoryLength] = useState<number>(0);
	const [historyMods, setHistoryMods] = useState<number>(0);
	const canvas = activeCanvas.canvas;

	if (!canvas) return null;

	return (
		<S.SidebarActionsBlock>
			<HistoryController
				onHistoryChanged={(historyLength, mods) => {
					setHistoryLength(historyLength);
					setHistoryMods(mods);
				}}
			/>
			<S.ActionVerticalDivider dynamicHeight />
			<Button
				iconButton
				color="neutral"
				variant="ghost"
				className={S.smallIconButton}
				onClick={() => canvas.fire('history:undo')}
				disabled={historyMods === historyLength}
			>
				<UndoIcon />
			</Button>
			<Button
				iconButton
				color="neutral"
				variant="ghost"
				className={S.smallIconButton}
				onClick={() => canvas.fire('history:redo')}
				disabled={historyMods === 0}
			>
				<RedoIcon />
			</Button>
			<S.ActionVerticalDivider dynamicHeight />
			<Button
				iconButton
				color="neutral"
				variant="ghost"
				className={S.smallIconButton}
				onClick={() => ungroupObjects(activeCanvas)}
				disabled={(() => {
					const activeObject = canvas.getActiveObject();
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return !activeObject || (activeObject && activeObject.get('objectType') !== CanvasObjectType.GROUP);
				})()}
			>
				<UngroupIcon />
			</Button>
			<Button
				iconButton
				color="neutral"
				variant="ghost"
				className={S.smallIconButton}
				onClick={() => groupObjects(activeCanvas)}
				disabled={(canvas.getActiveObjects().length || 1) === 1}
			>
				<GroupIcon />
			</Button>
		</S.SidebarActionsBlock>
	);
}
