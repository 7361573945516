import { useEffect, useContext, useReducer, useRef, useCallback, useState } from 'react';
import { CloseRounded } from '@streamelements/frontend-icons';
import { Modal, Button, Text, Toast, styled } from '@streamelements/frontend-ui';
import { ScienceEvent, ScienceSource } from '../../../enums';
import { useActiveCanvas, useScience, useTemplate } from '../../../hooks';
import { generatePreview } from '../../../services/merchCatalog';
import { CanvasesContext, UserContext } from '../../../store';
import { duplicateAndClearCanvas, dataURItoBlob } from '../../../utils';
import { LoaderIcon } from '../../Icons/LoaderIcon';
import { StyledModalContent, StyledModalOverlay, StyledModalClose, StyledButtonGroup } from '../Modal.styles';
import { createPreviewReducer, initialState, Action, PreviewErrorTypes } from './ProductPreviewReducer';
import { createSocketConnection } from './createSocketConntection';

const StyledProductPreview = styled('div', {
	display: 'flex',
	width: '100%',
	height: '550px',
	backgroundColor: '#F7F7F7',
	borderRadius: '1rem',
	textAlign: 'center',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	my: '1rem',
	fontSize: '1rem',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain',
	backgroundPosition: 'center',
});

const StyledPreviewButton = styled('button', {
	color: '#000000',
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontFamily: 'Nunito-sans',
});

type PreviewRes = {
	eventStatus: string;
	previewId: string;
	previews: {
		previewURL: string;
	}[];
};

interface PreviewModal {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onSave: (open: boolean) => void;
}

export function ProductPreviewModal({ open, onOpenChange, onSave }: PreviewModal) {
	const disconnectTimer = useRef<NodeJS.Timeout | null>(null);
	const [retryCount, setRetryCount] = useState<number>(0);
	const [previewState, previewDispatch] = useReducer(createPreviewReducer, initialState);
	const { activeCanvas } = useActiveCanvas();
	const { template } = useTemplate();
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: userState } = useContext(UserContext);
	const { trackEvent } = useScience();

	const onBadRequest = useCallback(
		(statusCode = 400) => {
			if (!retryCount) {
				previewDispatch({
					type: Action.GENERATE_PREVIEW_ERROR,
					payload: {
						type: statusCode === 429 ? PreviewErrorTypes.TOO_MANY_REQUESTS : PreviewErrorTypes.BAD_REQUEST,
					},
				});

				if (statusCode === 429) {
					let timestamp = Date.now() + 1000 * 60 - Date.now();

					setRetryCount(120);

					const countdownInterval = setInterval(() => {
						timestamp--;

						const count = Math.floor(timestamp / 1) % 120;

						setRetryCount(count);

						if (count === 0) clearInterval(countdownInterval);
					}, 1000);
				}
			}
		},
		[retryCount],
	);

	function onBackButton() {
		trackEvent({
			name: ScienceEvent.EDIT_ITEM,
			source: ScienceSource.PRODUCT_PREVIEW,
			placement: 'back_button',
		});
	}

	function onFullPreview() {
		window.open(previewState.preview, '_blank');

		trackEvent({
			name: ScienceEvent.VIEW_FULL_PREVIEW,
			source: ScienceSource.PRODUCT_PREVIEW,
			placement: 'save_button',
		});
	}

	function onModalSave() {
		onSave(true);

		trackEvent({
			name: ScienceEvent.SAVE_ITEM,
			source: ScienceSource.PRODUCT_PREVIEW,
			placement: 'save_button',
		});
	}

	const clearDisconnectTimer = useCallback(() => {
		if (disconnectTimer.current) {
			clearTimeout(disconnectTimer.current);

			disconnectTimer.current = null;
		}
	}, []);

	useEffect(() => {
		async function generatePreviewRequest() {
			const channelId = userState.user?._id;

			if (!activeCanvas || !channelId || !template) return;

			previewDispatch({ type: Action.GENERATE_PREVIEW });

			try {
				const image = await duplicateAndClearCanvas([activeCanvas]);
				const [{ canvas, width, height, position, threadColors }] = image;

				const file = dataURItoBlob(
					canvas.toDataURL({
						width,
						height,
						format: 'png',
					}),
				);

				if (canvasState.selectedPrintSide) {
					const result = await generatePreview(file, position, channelId as string, {
						catalogProductId: template.id || '',
						color: canvasState.selectedVariant || '',
						placements: {
							[position]: {
								threadColors,
							},
						},
					});

					if (typeof result.ok === 'boolean' && !result.ok) {
						onBadRequest(result.status);
					}

					trackEvent({
						name: ScienceEvent.ITEM_PREVIEW_START,
						source: ScienceSource.PRODUCT_PREVIEW,
						placement: 'inner_banner',
					});

					previewDispatch({
						type: Action.GENERATE_PREVIEW_SUCCESS,
						payload: { previewId: result.previewId },
					});
				}
			} catch (e) {
				onBadRequest();
			}
		}

		if (open && !previewState.preview && !retryCount && !previewState.error) {
			generatePreviewRequest();
		}
	}, [
		onBadRequest,
		open,
		previewState.error,
		previewState.preview,
		retryCount,
		activeCanvas,
		trackEvent,
		userState.user?._id,
	]);

	useEffect(() => {
		if (previewState.websocket.generating) {
			const socket = createSocketConnection();

			previewDispatch({ type: Action.OPEN_SOCKET, payload: { socket } });

			socket.on('semerch:item-preview-generated', (res: PreviewRes) => {
				if (res.eventStatus === 'failed') onBadRequest();

				if (previewState.previewId === res.previewId) {
					trackEvent({
						name: ScienceEvent.ITEM_PREVIEW_END,
						source: ScienceSource.PRODUCT_PREVIEW,
						placement: 'inner_banner',
					});

					previewDispatch({
						type: Action.RECIEVED_PREVIEW,
						payload: { preview: res.previews[0].previewURL },
					});

					clearDisconnectTimer();
					socket.disconnect();
				}
			});

			socket.on('unauthorized', () => {
				onBadRequest();
				socket.disconnect();
			});

			socket.on('disconnect', () => previewDispatch({ type: Action.CLOSE_SOCKET }));
		}
	}, [clearDisconnectTimer, onBadRequest, previewState.previewId, previewState.websocket.generating, trackEvent]);

	useEffect(() => {
		if (!disconnectTimer.current && open && previewState.websocket.socket) {
			disconnectTimer.current = setTimeout(() => {
				previewState.websocket.socket?.disconnect();

				onBadRequest();
			}, 90000);
		}

		return () => {
			clearDisconnectTimer();
		};
	}, [clearDisconnectTimer, disconnectTimer, onBadRequest, open, previewState.websocket.socket]);

	useEffect(() => {
		if (!open) {
			previewDispatch({ type: Action.RESET_PREVIEW });

			if (previewState.websocket.socket) previewState.websocket.socket.disconnect();
		}
	}, [open, previewState.websocket]);

	return (
		<Modal.Root open={open} onOpenChange={onOpenChange}>
			<StyledModalOverlay onClick={() => onBackButton()} />
			<StyledModalContent css={{ width: previewState.error ? 550 : 750 }}>
				<StyledModalClose>
					<CloseRounded />
				</StyledModalClose>
				<Text.Heading as="h5" level="5" weight="bold" css={{ marginBottom: '0.5rem' }}>
					Product preview
				</Text.Heading>
				{previewState.error ? (
					<>
						<Text.Subtitle>We were unable to generate a preview.</Text.Subtitle>
						<Text.Subtitle>
							Please try again
							{retryCount > 0 && <span style={{ fontWeight: 'bold' }}> in {retryCount > 0 && retryCount} seconds</span>}
							.
						</Text.Subtitle>
					</>
				) : (
					<>
						<Text.Subtitle>This is a digital mockup of how your design may look.</Text.Subtitle>
						<Text.Subtitle>Consider ordering a sample to see exactly how your design will turn out.</Text.Subtitle>
					</>
				)}

				{!previewState.error && (
					<StyledProductPreview css={{ backgroundImage: `url(${previewState.preview})` }}>
						{previewState.loading && (
							<>
								<Text.Subtitle weight="bold" css={{ mb: '2rem' }}>
									Generating Preview
								</Text.Subtitle>
								<LoaderIcon />
								<Text.Subtitle css={{ mt: '2rem' }}>Sit tight, this may take up to 90 seconds.</Text.Subtitle>
								<Text.Subtitle>Please do not refresh this page while your mockup is being generated.</Text.Subtitle>
							</>
						)}
					</StyledProductPreview>
				)}

				{previewState.error && previewState.errorType === PreviewErrorTypes.TOO_MANY_REQUESTS && (
					<Toast.Root
						color="attention"
						css={{ boxShadow: 'none', width: '100%', marginTop: '1.5rem', boxSizing: 'border-box' }}
					>
						<Toast.Text css={{ width: '100%', textAlign: 'center' }}>
							<span style={{ fontWeight: 'bold' }}>NOTE:</span> You can only generate 2 previews per minute
						</Toast.Text>
					</Toast.Root>
				)}

				{previewState.preview.length > 0 && !previewState.error && (
					<StyledPreviewButton css={{ cursor: 'pointer' }} onClick={() => onFullPreview()}>
						<Text.Subtitle css={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '-10px' }}>
							View Full Preview
						</Text.Subtitle>
					</StyledPreviewButton>
				)}

				<StyledButtonGroup>
					<Modal.Close as={Button} onClick={() => onBackButton()} variant="outlined">
						Back
					</Modal.Close>
					{previewState.error ? (
						<Button onClick={() => previewDispatch({ type: Action.RETRY_PREVIEW })} disabled={retryCount > 0}>
							Retry
						</Button>
					) : (
						<Modal.Close as={Button} onClick={() => onModalSave()}>
							Save
						</Modal.Close>
					)}
				</StyledButtonGroup>
			</StyledModalContent>
		</Modal.Root>
	);
}
