import { PropsWithChildren, CSSProperties } from 'react';
import { styled, Text } from '@streamelements/frontend-ui';

type BadgeTypes = 'beta';
type BadgeSizes = 'compact' | 'default';

interface BadgeProps {
  type: BadgeTypes,
  css: CSSProperties,
  size?: BadgeSizes,
}

const StyledBadge = styled('span', {
	display: 'block',
	marginLeft: '$base',
	borderRadius: '1rem',
	textTransform: 'uppercase',
	padding: '0 $base',
	verticalAlign: 'middle',

	variants: {
		type: {
			beta: {
				backgroundColor: '$uiSuccessMain',

				p: {
					color: '$textAlwaysDarkMain',
				},
			},
		},
    size: {
      default: {
        p: {
          fontSize: '0.75rem',
          lineHeight: '1.375rem',
        },
      },
      compact: {
        p: {
          fontSize: '0.6rem',
          lineHeight: '1rem',
        },
      },
    },
	},
});

export function Badge({ type, children, size = 'default', css }: PropsWithChildren<BadgeProps>) {
  return (
    <StyledBadge type={type} style={css} size={size}>
      <Text.Body weight="bold">{children}</Text.Body>
    </StyledBadge>
  )
}
