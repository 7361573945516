import { useEffect, useCallback } from 'react';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { SnappingLines } from '../../enums/SnappingLines';
import { useActiveCanvas } from '../../hooks';
import {
	AlignHorizontalLeft,
	AlignHorizontalMiddle,
	AlignHorizontalRight,
	AlignVerticalBottom,
	AlignVerticalMiddle,
	AlignVerticalTop,
	getBoundsAndEdges,
} from '../../store/ActionCreators/ObjectAlignActionCreator';
import CustomFabricObject from '../../types/CustomFabricObject';

export function SnappingController() {
	const { activeCanvas } = useActiveCanvas();

	const markSnappingController = useCallback(
		(lines?: SnappingLines[]) => {
			const canvas = activeCanvas?.canvas;

			if (!canvas) return;

			let changeHappened = false;
			const canvasObjects = canvas.getObjects();

			if (!canvasObjects.length) return;

			const snapGroupLayer = canvasObjects.find(
				(o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.SNAP,
			) as fabric.Group;

			if (!snapGroupLayer) return;

			const snapGroupObjects = snapGroupLayer.getObjects();

			if (!snapGroupObjects.length) return;

			if (lines?.length) {
				changeHappened = true;
				snapGroupObjects.forEach((o: CustomFabricObject) => {
					const layerName = o.get('layerName') as SnappingLines;
					const layerStrokeColor = o.get('stroke');
					const layerIsTouching = lines.includes(layerName);
					if (layerIsTouching && layerStrokeColor === 'transparent') {
						o.set({ stroke: 'limeGreen' });
						changeHappened = true;
					} else if (!layerIsTouching && layerStrokeColor !== 'transparent') {
						o.set({ stroke: 'transparent' });
						changeHappened = true;
					}
				});
			} else {
				snapGroupObjects.forEach((o) => {
					if (o.get('stroke') !== 'transparent') {
						o.set({ stroke: 'transparent' });
						changeHappened = true;
					}
				});
			}

			changeHappened && canvas.renderAll();
		},
		[activeCanvas],
	);

	const handleObjectSnap = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const selObj = canvas.getActiveObject();
		const SNAP_OFFSET = 10;

		markSnappingController();
		selObj && selObj.setCoords();

		const objBoundingBox = selObj.getBoundingRect();

		const Selection = {
			...objBoundingBox,
			bottom: 0,
			right: 0,
			centerH: 0,
			centerV: 0,
		};

		Selection.bottom = Selection.top + Selection.height;
		Selection.right = Selection.left + Selection.width;
		Selection.centerH = Selection.height / 2 + Selection.top;
		Selection.centerV = Selection.width / 2 + Selection.left;

		const Canvas = getBoundsAndEdges(canvas);

		const markSnappingLines = [];

		// Snap Left
		if (Selection.left <= Canvas.left + SNAP_OFFSET && Selection.left >= Canvas.left - SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.Left);
			AlignHorizontalLeft(activeCanvas);
		}

		// Snap Top
		if (Selection.top <= Canvas.top + SNAP_OFFSET && Selection.top >= Canvas.top - SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.Top);
			AlignVerticalTop(activeCanvas);
		}

		// Snap Right
		if (Selection.right >= Canvas.right - SNAP_OFFSET && Selection.right <= Canvas.right + SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.Right);
			AlignHorizontalRight(activeCanvas);
		}

		// Snap Bottom
		if (Selection.bottom >= Canvas.bottom - SNAP_OFFSET && Selection.bottom <= Canvas.bottom + SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.Bottom);
			AlignVerticalBottom(activeCanvas);
		}

		// Snap S:CenterVertical => CenterVertical
		if (Selection.centerV >= Canvas.centerV - SNAP_OFFSET && Selection.centerV <= Canvas.centerV + SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.VerticalCenter);
			AlignVerticalMiddle(activeCanvas);
		}

		// Snap S:CenterHorizontal => CenterHorizontal
		if (Selection.centerH >= Canvas.centerH - SNAP_OFFSET && Selection.centerH <= Canvas.centerH + SNAP_OFFSET) {
			markSnappingLines.push(SnappingLines.HorizontalCenter);
			AlignHorizontalMiddle(activeCanvas);
		}

		markSnappingLines.length && markSnappingController(markSnappingLines);
		selObj && selObj.setCoords();
	}, [activeCanvas, markSnappingController]);

	useEffect(() => {
		activeCanvas?.canvas?.on('object:moving', handleObjectSnap);
		document.addEventListener('mouseup', () => markSnappingController());

		return () => {
			activeCanvas?.canvas?.off('object:moving', handleObjectSnap);
			document.removeEventListener('mouseup', () => markSnappingController());
		};
	}, [activeCanvas?.canvas, handleObjectSnap, markSnappingController]);

	return null;
}
