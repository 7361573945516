import { Dropdown, Text, Badge, Modal, styled } from "@streamelements/frontend-ui";
import { useState } from "react";
import { HelpRounded, BugRounded, KeyboardRounded } from "@streamelements/frontend-icons";
import { KeyboardShortcutsModal } from "./KeyboardShortcutsModal";
import { useScience } from '../hooks';
import { ScienceEvent, ScienceSource, SciencePrefix } from '../enums';

const StyledDropdownItem = styled('div', {
	display: 'grid',
	padding: 'calc($base * 2)',
	gridTemplateColumns: 'max-content 1fr max-content',
	alignItems: 'center',
	columnGap: '$base',
	cursor: 'pointer'
});

const StyledDropdownTrigger = styled(Dropdown.Trigger, {
	background: 'none',
	border: 'none',
	cursor: 'pointer',
	opacity: 0.5,
	transition: '0.2s opacity',

	'svg': {
		borderRadius: '24px',
		transition: '0.2s color',
	},

	'&:hover': {
		opacity: 1,

		'svg': {
			color: '$brandPrimaryMain',
		}
	}
});	

export function HelpButton() {
	const [ helpModalOpen, setHelpModalOpen ] = useState<boolean>(false);
  const { trackEvent } = useScience();
  
  function onBugSelect () {
    trackEvent({
      name: ScienceEvent.REPORT_BUG,
      source: ScienceSource.EDITOR_PANEL,
      prefix: SciencePrefix.DASHBOARD,
      placement: 'question_mark_dropdown',
    });

    window.open('https://strms.net/merch-support', '_blank')
  }
	
	return (
		<>
			<Dropdown.Root>
				<StyledDropdownTrigger>
					<HelpRounded />
				</StyledDropdownTrigger>

				<Dropdown.Content>
					<StyledDropdownItem onClick={() => onBugSelect()}>
						<BugRounded />
						<Text.Body>
							Report a Bug
						</Text.Body>
						<Badge 
							color="success"
							appearance="translucent"
							css={{ textTransform: 'uppercase' }}
						>
							Beta
						</Badge>
					</StyledDropdownItem>
					<Dropdown.Separator/>
					
					<StyledDropdownItem onClick={() => setHelpModalOpen(true)}>
						<KeyboardRounded />
						<Text.Body>
							Keyboard Shortcuts
						</Text.Body>
					</StyledDropdownItem>
				</Dropdown.Content>
			</Dropdown.Root>
			
			<Modal.Root open={helpModalOpen} onOpenChange={(val) => setHelpModalOpen(val)}>
				<Modal.Overlay/>
				<KeyboardShortcutsModal/>
			</Modal.Root>
		</>
	);
}
