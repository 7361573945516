import { PropsWithChildren, useEffect, useState } from "react";
import { Text } from '@streamelements/frontend-ui';
import AssetItem from "../AssetItem";
import AssetItemBundle from "../../../types/AssetItemBundle";

interface Props {
    onItemClick: (img: string, fileName?: string) => void,
    filterBy?: string
}

export default function SEGallery({ onItemClick, filterBy = '' }: PropsWithChildren<Props>) {
    const [ items, setItems ] = useState<string[][]>([]);

    const getFilteresItems = () => items.filter(item => item[0].toLowerCase().includes(filterBy));

    useEffect(() => {
        fetch('/bundles/bundles.json')
            .then(res => res.json())
            .then(res => {
                const newItems: string[][] = [];
                const bundles: AssetItemBundle[] = res.bundles;
                bundles.forEach(b => {
                    b.images.forEach(image => {
                        newItems.push([image.name, image.path, image.thumbnail, b.name])
                    })
                });

                setItems(newItems)
            })
    }, []);

    return (
        <>
         {!!getFilteresItems().length && getFilteresItems().map(item => (
            <AssetItem
                itemId={item[0]}
                backgroundImage={item[1]}
                thumbnail={item[2]}
                onClick={onItemClick}
                key={item[0]}
                name={item[0]}
                size={0}
                type={'image/png'}
                createdAt={''}
                bundle={item[3]}
            />
        ))}
        {!getFilteresItems().length && <Text.Body>No Items Found</Text.Body>}
        </>
    );
}