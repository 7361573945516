import { useContext, useEffect, useState } from 'react';
import { Modal, Button, Text, styled } from '@streamelements/frontend-ui';
import { ScienceEvent, ScienceSource } from '../../../enums';
import { useScience, useTemplate } from '../../../hooks';
import { EditorsContext } from '../../../store';
import { EditorAction } from '../../../types';
import { StyledModalContent, StyledModalOverlay, StyledButtonGroup } from '../Modal.styles';
import { SwitchController } from './SwitchController';

interface MultiSideModal {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onSave: (open: boolean) => void;
}

interface Sides {
	title: string;
	price: number;
	free: boolean;
}

const StyledPriceHead = styled('div', {
	display: 'flex',
	width: '100%',
	borderBottom: '1px solid rgba(0,0,0, 0.1)',
	padding: '0 $base calc($base * 2)',
	marginBottom: 'calc($base * 2)',
	boxSizing: 'border-box',

	p: {
		fontSize: '0.75rem',
	},

	'p:last-child': {
		marginLeft: 'auto',
	},
});

const StyledPriceSummary = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	borderTop: '1px solid rgba(0,0,0, 0.1)',
	padding: 'calc($base * 2) $base 0',
	marginTop: '$base',
	boxSizing: 'border-box',

	div: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 'calc($base * 2)',
	},
});

const StyledSidePrice = styled(Text.Subtitle, {
	fontSize: '0.75rem',
	margin: 'auto 0 auto auto',

	variants: {
		free: {
			true: {
				textDecoration: 'line-through',
			},
		},
	},
});

export function MultiSideSaveModal({ open, onOpenChange, onSave }: MultiSideModal) {
	const [sides, setSides] = useState<Sides[]>([]);
	const { template } = useTemplate();
	const { trackEvent } = useScience();
	const { state: editorState, dispatch: editorDispatch } = useContext(EditorsContext);

	if (!template || !editorState) return null;

	const { product } = editorState;
	const { vendorPlacements } = template;

	function onModalSave() {
		onSave(true);

		trackEvent({
			name: ScienceEvent.SAVE_ITEM,
			source: ScienceSource.PRODUCT_PREVIEW,
			placement: 'save_button',
		});
	}

	useEffect(() => {
		function generateSides() {
			if (!template) return [];

			const sortingArr = Object.keys(template.vendorPlacements);
			const maxSidePrice = Math.max(...product.savePlacements.map((s) => template.vendorPlacements[s].additionalPrice));

			let hasSetFreeSide = false;

			return product.savePlacements
				.map((side) => {
					const placement = template.vendorPlacements[side];
					const free = placement.additionalPrice === maxSidePrice && !hasSetFreeSide;

					if (free) hasSetFreeSide = true;

					return {
						title: placement.title,
						type: placement.type,
						price: placement.additionalPrice,
						free,
					};
				})
				.sort((a, b) => sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type))
				.sort((a, b) => (a.free === b.free ? 0 : a.free ? -1 : 1));
		}

		const sides = generateSides();

		let additionalCost = 0;

		sides.forEach((s) => {
			if (!s.free) additionalCost = additionalCost + s.price;
		});

		editorDispatch({
			type: EditorAction.SET_BASE_PRICE,
			payload: additionalCost + template.basePrice,
		});

		editorDispatch({
			type: EditorAction.SET_RECOMMENDED_PRICE,
			payload: additionalCost + template.recommendedPrice,
		});

		setSides(sides);
	}, [product.savePlacements, open]);

	return (
		<Modal.Root open={open} onOpenChange={onOpenChange}>
			<StyledModalOverlay />
			<StyledModalContent css={{ width: 550, alignItems: 'flex-start', overflowY: 'auto' }}>
				<Text.Subtitle css={{ fontWeight: '900' }}>Product Details</Text.Subtitle>
				<Text.Body css={{ marginBottom: '1.75rem' }}>Name it, price it, and let’s get it into your store.</Text.Body>

				<StyledPriceHead>
					<Text.Body weight="bold">Print Side</Text.Body>
					<Text.Body weight="bold">Additional Cost*</Text.Body>
				</StyledPriceHead>

				{Object.keys(vendorPlacements).map((placement) => (
					<SwitchController
						key={placement}
						placement={placement}
						vendorPlacements={vendorPlacements}
						placements={product.placements}
					/>
				))}

				<StyledPriceSummary>
					<div>
						<Text.Body weight="bold" css={{ display: 'flex', flexDirection: 'column' }}>
							Product <span style={{ fontSize: '0.75rem', fontWeight: '400' }}>* Most expensive side is free</span>
						</Text.Body>
						<Text.Subtitle css={{ margin: 'auto 0 auto auto' }}>${template.basePrice.toFixed(2)}</Text.Subtitle>
					</div>
					<Text.Body weight="bold" css={{ margin: '$base 0' }}>
						Side(s)
					</Text.Body>
					{sides.map((side) => (
						<div style={{ marginBottom: '4px' }} key={side.title}>
							<Text.Body css={{ fontSize: '0.75rem' }}>
								{side.title} <strong style={{ fontWeight: 'bold', opacity: 0.8 }}>{side.free && '(free)'}</strong>
							</Text.Body>
							<StyledSidePrice free={side.free}>${side.price.toFixed(2)}</StyledSidePrice>
						</div>
					))}
					<div style={{ margin: '16px 0 4px', paddingTop: '16px', borderTop: '1px solid rgba(0,0,0, 0.1)' }}>
						<Text.Body css={{ display: 'flex', flexDirection: 'column' }}>Total cost</Text.Body>
						<Text.Subtitle css={{ margin: 'auto 0 auto auto' }}>${product.basePrice.toFixed(2)}</Text.Subtitle>
					</div>
					<div>
						<Text.Body weight="bold" css={{ display: 'flex', flexDirection: 'column', fontSize: '1rem' }}>
							Recommended cost
						</Text.Body>
						<Text.Subtitle weight="bold" css={{ margin: 'auto 0 auto auto' }}>
							${product.recommendedPrice.toFixed(2)}
						</Text.Subtitle>
					</div>
				</StyledPriceSummary>
				<StyledButtonGroup>
					<Modal.Close as={Button} variant="outlined">
						Back
					</Modal.Close>
					<Modal.Close as={Button} onClick={() => onModalSave()}>
						Save
					</Modal.Close>
				</StyledButtonGroup>
			</StyledModalContent>
		</Modal.Root>
	);
}
