import chroma from 'chroma-js';
import { useState, useEffect } from 'react';
import type { StylesConfig } from 'react-select';
import { TrashRounded } from '@streamelements/frontend-icons';
import { Select, Button, styled } from '@streamelements/frontend-ui';
import { ThreadOption } from '../../../types/Threads';
import { invertColor } from '../../../utils/ColorUtils';
import { ColorCircle } from '../../ColorCircle';

const customStyles: StylesConfig<ThreadOption> = {
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
	option: (styles, { data, isSelected }) => {
		const color = chroma(data.value);

		return {
			...styles,
			fontFamily: '"Nunito Sans", sans-serif;',
			color: isSelected ? invertColor(color) : data.value === '#FFFFFF' ? '#CCCCCC' : data.value,
			backgroundColor: isSelected ? (data.value === '#FFFFFF' ? '#CCCCCC' : data.value) : '#FFFFFF',
			cursor: 'pointer',
			fontSize: '0.875rem',
			fontWeight: 700,
			transition: '0.2s all',

			'&:hover, &:focus': {
				backgroundColor: isSelected ? data.value : color.alpha(0.3).css(),
			},
		};
	},
};

const ThreadSelectorContainer = styled('div', {
	display: 'grid',
	gridTemplateColumns: '1.5rem 5fr 1.75rem',
	mb: '0.75rem',
	gridGap: '0.875rem',
	alignItems: 'center',
	justifyContent: 'center',

	'&:last-child': {
		mb: 0,
	},
});

const ColorsContainer = styled('div', {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$uiMonochrome30',
	width: 28,
});

const StyledIconButton = styled(Button, {
	padding: '0',
	opacity: 0.5,
	transition: '0.2s all',
	width: '1.75rem',
	height: '1.75rem',
	minHeight: '1.75rem',

	svg: {
		fontSize: '1.2rem',
		opacity: 0.6,
	},

	'&:hover': {
		opacity: 1,
		color: 'red',
	},
});

interface ThreadSelectorProps {
	selectedThread: ThreadOption;
	position: number;
	threads?: { [key: string]: string };
	setSelectedThreads: (value: ThreadOption, index: number) => void;
	removeThread: (position: number) => void;
	disableDelete: boolean;
}

export function ThreadSelector({
	selectedThread,
	position,
	threads = {},
	setSelectedThreads,
	removeThread,
	disableDelete,
}: ThreadSelectorProps) {
	const [threadOptions, setThreadOptions] = useState<ThreadOption[] | null>(null);

	useEffect(() => {
		function buildOptions() {
			return Object.keys(threads).map((key) => {
				return {
					label: threads[key],
					value: key,
				};
			});
		}

		if (!threadOptions?.length) {
			const availableThreads = buildOptions();

			setThreadOptions(availableThreads);
		}
	}, [selectedThread.value, threadOptions, threadOptions?.length, threads]);

	return (
		<ThreadSelectorContainer>
			{threadOptions && selectedThread && (
				<>
					<ColorsContainer>
						<ColorCircle color={selectedThread.value} />
					</ColorsContainer>
					<Select
						styles={customStyles}
						menuPortalTarget={document.body}
						value={selectedThread}
						onChange={(value: ThreadOption) => setSelectedThreads(value, position)}
						options={threadOptions}
					/>
					<StyledIconButton
						iconButton
						variant="ghost"
						color="neutral"
						onClick={() => removeThread(position)}
						disabled={disableDelete}
					>
						<TrashRounded />
					</StyledIconButton>
				</>
			)}
		</ThreadSelectorContainer>
	);
}
