import prettyBytes from 'pretty-bytes';
import {
	ChangeEvent,
	createRef,
	Fragment,
	MouseEvent,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Modal, Text, Button, Input, styled } from '@streamelements/frontend-ui';
import { ActionVerticalDivider, CustomDivider, midGreyText, primaryText } from '../../Editor.style';
import { useActiveCanvas, useErrorState, useRefetchUserAssets } from '../../hooks';
import { deleteUserAsset, uploadImage } from '../../services/MerchAPI';
import { CanvasesContext, UserContext } from '../../store';
import { addImage } from '../../store/ActionCreators/CanvasActionCreator';
import { ErrorTypes, UploadsEntity, UserAssetsResponse } from '../../types';
import { numberOrZero } from '../../utils/NumberUtils';
import { UploadIcon } from '../Icons';
import {
	ModalContainer,
	Toolbar,
	ToolbarActions,
	ToolbarFilters,
	Content,
	SortByWrapper,
	ContentInner,
	DragAndDropContainer,
	uploadIconStyle,
} from './AssetManager.style';
import SEGallery from './Tabs/SEGallery';
import UserGallery from './Tabs/UserGallery';

const TabsKeys = [
	{
		key: 1,
		name: 'SE.Gallery',
	},
	{
		key: 2,
		name: 'Your Uploads',
	},
];

const TabsHeadersContainer = styled('div', {
	display: 'grid',
	gridAutoFlow: 'column',
	columnGap: 'calc($base * 2)',
});

const TabHeader = styled('div', {
	display: 'grid',
	gridAutoFlow: 'column',
	padding: '$base',

	'&:hover': {
		cursor: 'pointer',
	},

	variants: {
		selected: {
			true: {
				borderBottom: '4px solid $uiPrimaryMain',
			},
		},
	},
});

function forceDownload(url: string, fileName: string){
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        const imageUrl = URL.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

interface AssetManagerProps {
	open: boolean;
	defaultTab?: number;
	setLoading: (v: boolean) => void;
	onOpenChange: (open: boolean) => void;
}

export function AssetManager(props: PropsWithChildren<AssetManagerProps>) {
	const fileInputRef = createRef<HTMLInputElement>();
	const dropZoneRef = createRef<HTMLDivElement>();
	const { activeCanvas } = useActiveCanvas();
	const { state: canvasState } = useContext(CanvasesContext);
	const { state: userState } = useContext(UserContext);

	const [selectedTab, setSelectedTab] = useState<number>(1);
	const [fileDrag, setFileDrag] = useState<boolean>(false);
	const [draggedOver, setDraggedOver] = useState<boolean>(false);
	const [assetsData, setAssetsData] = useState<UserAssetsResponse>();
	const [items, setItems] = useState<UploadsEntity[]>([]);
	const [search, setSearch] = useState<string>('');

	const { setErrorState } = useErrorState();
	const { refetchUserAssets } = useRefetchUserAssets();

	const getFilteresItems = () => items.filter((item) => item.name.toLowerCase().includes(search));

	const handleUploadFiles = useCallback(
		(files: FileList) => {
			const file = files[0];
			const fileName = file.name;

			if (userState.user && userState.user._id) {
				props.setLoading(true);

				uploadImage(file).then((res) => {
					if (!res.url) {
						props.setLoading(false);
						props.onOpenChange(false);
						setErrorState(true, ErrorTypes.IMAGE_UPLOAD_FAILED);
						return;
					}

					const imgObj = new Image();

					imgObj.crossOrigin = 'anonymous';
					imgObj.src = res.url;
					imgObj.onload = () => {
						props.setLoading(false);
						activeCanvas && addImage(canvasState, activeCanvas, imgObj, fileName);
						props.onOpenChange(false);
					};

					refetchUserAssets();
				});
			}
		},
		[userState.user, props, canvasState, setErrorState],
	);

	const onImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
		if (!event.target || !event.target.files) return;
		handleUploadFiles(event.target.files);
		event.target.files = null;
	};

	const onItemClick = (img: string, fileName?: string) => {
		const elem = new Image();
		elem.crossOrigin = 'anonymous';
		elem.src = img + `?dc=${Date.now()}`;
		elem.onload = () => {
			props.onOpenChange(false);
			activeCanvas && addImage(canvasState, activeCanvas, elem, fileName || 'Graphics');
		};
	};

	const onItemDelete = (e: MouseEvent<HTMLDivElement>, assetId: string) => {
		e.stopPropagation();
		if (!userState.user) return;
		setItems((prevItems) => {
			return prevItems.filter((i: UploadsEntity) => i._id !== assetId);
		});
		deleteUserAsset(userState.user._id, assetId);
	};

	const onItemDownload = (e: MouseEvent<HTMLDivElement>, assetId: string) => {
		e.stopPropagation();
		const item = items.find((i: UploadsEntity) => i._id === assetId);
		if (!item) return;
		forceDownload(item.url, item.name);
	};

	const onTabClick = (key: number) => setSelectedTab(key);

	useEffect(() => {
		const assets = userState.assets;

		if (!assets) return;

		setItems(assets.uploads || []);
		setAssetsData(assets);
	}, [userState.assets]);

	const handleDrag = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			setFileDrag(true);
		},
		[setFileDrag],
	);

	const handleDocumentDragLeave = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setFileDrag(false);
	};

	const handleDocumentDragDrop = useCallback(
		(e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();
			if (e.dataTransfer?.files) {
				handleUploadFiles(e.dataTransfer.files);
			}
			setFileDrag(false);
		},
		[setFileDrag, handleUploadFiles],
	);

	useEffect(() => {
		const docElem = document.documentElement;
		docElem.addEventListener('dragenter', handleDrag);
		docElem.addEventListener('dragstart', handleDrag);
		docElem.addEventListener('dragover', handleDrag);

		docElem.addEventListener('dragleave', handleDocumentDragLeave);
		docElem.addEventListener('drop', handleDocumentDragDrop, false);

		dropZoneRef.current?.addEventListener('dragenter', () => setDraggedOver(true));
		dropZoneRef.current?.addEventListener('dragstart', () => setDraggedOver(true));
		dropZoneRef.current?.addEventListener('dragover', () => setDraggedOver(true));
		dropZoneRef.current?.addEventListener('dragleave', () => setDraggedOver(false));
		dropZoneRef.current?.addEventListener('drop', () => setDraggedOver(false));

		return () => {
			const docElem = document.documentElement;
			docElem.removeEventListener('dragenter', handleDrag);
			docElem.removeEventListener('dragstart', handleDrag);
			docElem.removeEventListener('dragover', handleDrag);
			docElem.removeEventListener('dragleave', handleDocumentDragLeave);
			docElem.removeEventListener('drop', handleDocumentDragDrop);
		};
	}, [dropZoneRef, handleUploadFiles, handleDrag, userState.user, handleDocumentDragDrop]);

	useEffect(() => {
		if (props.open) {
			setFileDrag(false);
		}
	}, [props.open]);

	useEffect(() => {
		props.defaultTab && setSelectedTab(props.defaultTab);
	}, [props.defaultTab]);

	return (
		<Modal.Root open={props.open} onOpenChange={props.onOpenChange}>
			<input type="file" hidden ref={fileInputRef} onChange={onImageUpload} accept=".jpg,.jpeg,.png,.svg,.webp" />
			<Modal.Overlay />
			<ModalContainer ref={dropZoneRef}>
				<Toolbar>
					<ToolbarActions>
						<TabsHeadersContainer>
							{TabsKeys.map((t, i) => (
								<Fragment key={t.key}>
									<TabHeader onClick={() => onTabClick(t.key)} key={t.key} selected={selectedTab === t.key}>
										<Text.Body weight="bold" className={selectedTab !== t.key ? midGreyText : primaryText}>
											{t.name}
										</Text.Body>
									</TabHeader>
									{i < TabsKeys.length - 1 && <ActionVerticalDivider key={`divider_${i}`} />}
								</Fragment>
							))}
						</TabsHeadersContainer>
						<div />
						{selectedTab === 2 && (
							<>
								<Text.Body variant="caption">
									{prettyBytes(numberOrZero(assetsData?.totalSize))} out of{' '}
									{prettyBytes(numberOrZero(assetsData?.totalStorage))} (
									{((numberOrZero(assetsData?.totalSize) / numberOrZero(assetsData?.totalStorage)) * 100).toFixed(2)}%)
									in use
								</Text.Body>
								<Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
									<UploadIcon />
									UPLOAD NEW
								</Button>
							</>
						)}
					</ToolbarActions>
					<CustomDivider />
					<ToolbarFilters>
						<SortByWrapper />
						<Input
							value={search}
							onChange={(e) => setSearch(e.target.value.toLowerCase())}
							placeholder="Search"
							style={{ width: 256 }}
							type="search"
						/>
					</ToolbarFilters>
					<CustomDivider />
				</Toolbar>
				<Content>
					<ContentInner draggingState={fileDrag}>
						{fileDrag ? (
							<DragAndDropContainer dragOver={draggedOver}>
								<div>
									<Text.Heading level={5} weight="black" className={primaryText}>
										<UploadIcon className={uploadIconStyle} />
										<br />
										DRAG & DROP
										<br />
										YOUR FILES HERE
									</Text.Heading>
								</div>
							</DragAndDropContainer>
						) : (
							<>
								{selectedTab === 1 && <SEGallery filterBy={search} onItemClick={onItemClick} />}
								{selectedTab === 2 && (
									<UserGallery
										items={getFilteresItems()}
										onItemDelete={onItemDelete}
										onItemDownload={onItemDownload}
										onItemClick={onItemClick}
									/>
								)}
							</>
						)}
					</ContentInner>
				</Content>
			</ModalContainer>
		</Modal.Root>
	);
}
