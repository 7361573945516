import { useContext } from 'react';
import { Text, styled, Divider } from '@streamelements/frontend-ui';
import * as S from '../Editor.style';
import { halfGreyText } from '../Editor.style';
import { ColorCircle } from '../components';
import { CanvasesContext } from '../store';
import { CanvasAction } from '../types';

const EditContainer = styled('div', {
	display: 'grid',
});

const InputValueContainer = styled('div', {
	display: 'grid',
	gridAutoFlow: 'column',
	columnGap: '$base',
	alignItems: 'center',
	mt: '$base',
	gridAutoColumns: '1fr',

	variants: {
		circles: {
			true: {
				gridAutoFlow: 'row',
				rowGap: '$base',
				columnGap: '$base',
				gridTemplateColumns: 'repeat(auto-fill, 28px)',
				justifyContent: 'flex-start',
			},
		},
		withHelper: {
			true: {
				mb: 'calc($base * 0.5)',
			},
		},
	},
});

export function ProductDetails() {
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const { activeVariants, variants } = canvasState;

	function onselect(color: string) {
		let updatedVariants = [];

		if (activeVariants.includes(color)) {
			updatedVariants = canvasState.activeVariants.filter((activeColor) => activeColor !== color);
		} else {
			updatedVariants = [...canvasState.activeVariants, color];
		}

		canvasDispatch({
			type: CanvasAction.SET_ACTIVE_VARIANTS,
			payload: updatedVariants,
		});
	}

	return (
		<>
			<S.Sidebar>
				<S.SidebarBlock clearBottom>
					<Text.Subtitle weight="black">Product details</Text.Subtitle>
				</S.SidebarBlock>
				<Divider />
				<S.SidebarBlock css={{ mt: '$base' }}>
					<EditContainer>
						<Text.Body weight="bold">Color variations</Text.Body>
						<Text.Body variant="caption" css={{ marginBottom: '$base' }} className={halfGreyText}>
							Select a maximum of 5
						</Text.Body>
						<InputValueContainer circles>
							{variants?.map(({ color }) => (
								<ColorCircle
									key={color}
									onClick={() => onselect(color)}
									color={color}
									pointerEvents={
										(activeVariants.length === 1 && activeVariants.includes(color)) ||
										(activeVariants.length > 4 && !activeVariants.includes(color))
									}
									disabled={activeVariants.length > 4 && !activeVariants.includes(color)}
									selected={activeVariants.includes(color)}
								/>
							))}
						</InputValueContainer>
					</EditContainer>
				</S.SidebarBlock>
			</S.Sidebar>
		</>
	);
}
