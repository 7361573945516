import { useState, useContext } from 'react';
import { FormControlLabel, Switch, Text, styled } from '@streamelements/frontend-ui';
import { EditorsContext } from '../../../store';
import { VendorPlacements, EditorAction } from '../../../types';

interface SwitchControllerProps {
	placement: string;
	vendorPlacements: VendorPlacements;
	placements: string[];
}

const StyledFormControl = styled(FormControlLabel, {
	display: 'flex',
	padding: '$base',
	width: '100%',
	marginBottom: 'calc($base / 2)',
	boxSizing: 'border-box',

	variants: {
		disabled: {
			true: {
				opacity: 0.5,
			},
		},
	},
});

export function SwitchController({ placement, vendorPlacements, placements }: SwitchControllerProps) {
	const { state: editorsState, dispatch: editorDispatch } = useContext(EditorsContext);
	const [checked, setIsChecked] = useState(editorsState.product.savePlacements.includes(placement));

	function updateChecked() {
		if (editorsState.product.savePlacements.length <= 1 && checked) return null;

		setIsChecked(!checked);

		editorDispatch({
			type: EditorAction.SET_SAVE_PLACEMENTS,
			payload: !checked
				? [...editorsState.product.savePlacements, placement]
				: editorsState.product.savePlacements.filter((p) => p !== placement),
		});
	}

	return (
		<StyledFormControl key={placement} disabled={!placements.includes(placement)}>
			<Switch disabled={!placements.includes(placement)} checked={checked} onCheckedChange={updateChecked} />
			<Text.Body weight="bold" css={{ textTransform: 'capitalize' }}>
				{vendorPlacements[placement].type.replace('_', ' ')}
			</Text.Body>
			<span style={{ marginLeft: 'auto' }}>+ ${vendorPlacements[placement].additionalPrice}</span>
		</StyledFormControl>
	);
}
