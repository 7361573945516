import { Card, styled, css, Text, Modal, Divider } from '@streamelements/frontend-ui';

export const EditorRoot = css({
	display: 'grid',
})();

export const CanvasBlock = styled('canvas', {
	variants: {
		hidden: {
			true: {
				display: 'none',
			},
		},
	},
});

export const EditorActionsContainer = styled('div', {
	display: 'grid',
	gridTemplateColumns: '320px auto',
	columnGap: 'calc($base * 3)',

	variants: {
		embroidery: {
			true: {
				gridTemplateColumns: '320px auto 320px',
			},
		},
	},
});

export const SidebarContainer = styled('div', {
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	rowGap: 'calc($base * 2)',
});

export const Sidebar = styled(Card.Root, {
	padding: 0,
	height: '100%',
	display: 'grid',
	gridAutoRows: 'max-content max-content 1fr max-content max-content',
	boxShadow: '$2',
	border: '1px solid rgba(0, 0, 0, 0.1)',
});

export const SidebarBlock = styled('div', {
	position: 'relative',
	margin: 'calc($base * 2)',

	variants: {
		clearBottom: {
			true: {
				marginBottom: '$base',
			},
		},
	},
});

export const SidebarActionsBlock = styled('div', {
	borderTop: '1px solid $uiDisabled25',
	display: 'grid',
	gridAutoFlow: 'column',
	justifyContent: 'end',
	px: '$base',
});

export const BottomBarContainer = styled('div', {
	display: 'grid',
	position: 'absolute',
	bottom: 'calc($base * 3)',
	right: 'calc($base * 3)',
	justifyItems: 'end',
	zIndex: 2,
});

export const Floater = styled(Card.Root, {
	borderRadius: 'calc($base * 2)',
	display: 'flex',
	justifyContent: 'space-evenly',
	py: 'calc($base * 2.5)',
	px: 0,
	maxWidth: 240,
	zIndex: 9,
	boxShadow: '$2',
});

export const FloatingActionsBar = styled(Card.Root, {
	display: 'flex',
	flexWrap: 'wrap',
	position: 'absolute',
	padding: 0,
	borderRadius: '$base',
	maxWidth: 630,
	zIndex: 2147483647,
	boxShadow: '$2',

	'@media (max-width: 1366px)': {
		maxWidth: 560,
	},
});

export const ActionBlock = styled('div', {
	display: 'grid',
	gridAutoFlow: 'column',
	gridAutoColumns: '1fr',
	margin: 'calc($base * 1.5)',
	placeItems: 'center',

	variants: {
		withIcon: {
			true: {
				gridAutoColumns: '2fr',
			},
		},
		clearVerticalSpacing: {
			true: {
				margin: '0 calc($base * 0.5)',
			},
		},
		clearSpacing: {
			true: {
				margin: 0,
			},
		},
	},
});

export const ActionVerticalDivider = styled('hr', {
	border: 0,
	borderLeft: '1px solid $uiDisabled25',
	height: 40,
	margin: '0 calc($base * 0.5)',

	variants: {
		dynamicHeight: {
			true: {
				height: '100%',
			},
		},
	},
});

export const ActionTitle = styled(Text.Body, {
	margin: 0,
});

export const smallIconButton = css({
	paddingLeft: '1rem',
	paddingRight: '1rem',
})();

export const bigIcon = css({
	fontSize: '2rem !important',
})();

export const tinyIconButton = css({
	paddingLeft: '0.5rem',
	paddingRight: '0.5rem',
	width: 20,
	height: 20,
})();

export const goBackIcon = css({
	fontSize: '1.25rem !important',
})();

export const StyledModalContent = styled(Modal.Content, {
	padding: 'calc($base * 2)',
});

export const CustomDivider = styled(Divider, {
	margin: 0,
});

export const SaveModalContent = styled(Modal.Content, {
	px: 'calc($base * 3)',
	pb: 'calc($base * 4)',
	pt: 'calc($base * 7)',
	display: 'grid',
	rowGap: 'calc($base * 2)',
	width: 507,
});

export const FullWidthTextField = css({
	width: '100%',
});

export const CloseIconContainer = styled('div', {
	position: 'absolute',
	top: 'calc($base * 1.5)',
	right: 'calc($base * 1.5)',
});

export const LoadingModalContent = styled(Modal.Content, {
	padding: 'calc($base * 3)',
	minWidth: 'initial',
});

export const lightGreyText = css({ color: '$textPrimary30' })();
export const midGreyText = css({ color: '$textPrimary50' })();
export const halfGreyText = css({ color: '$textPrimary70' })();
export const primaryText = css({ color: '$uiPrimaryMain' })();
export const errorText = css({ color: '$uiErrorMain' })();

export const tooltipStyle = css({ zIndex: 11 })();
export const extraTinyIcon = css({ fontSize: '0.5rem !important' })();
export const tinyIcon = css({ fontSize: '0.75rem !important' })();
export const smallIcon = css({ fontSize: '1.125rem !important' })();
export const mediumIcon = css({ fontSize: '1.25rem !important' })();
export const whiteIcon = css({ color: '#fff !important' })();
export const clickableIcon = css({
	opacity: 0.7,
	transition: '0.2s opacity',

	'&:hover': {
		opacity: 1,
	},
})();

export const dropdownItemStyle = css({
	display: 'grid',
	padding: 'calc($base * 1.5)',
	pb: 'calc($base * 0.5)',
	gridTemplateColumns: 'max-content 1fr max-content',
	alignItems: 'center',
	columnGap: '$base',
	cursor: 'pointer',
})();
