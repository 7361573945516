import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

type CustomEnvs = 'production' | 'staging' | 'development' | 'test';

interface TokenData {
	channel: string;
	provider: string;
}

const env = process.env.REACT_APP_DEV_ENV as CustomEnvs;
const TOKEN_COOKIE_KEY = 'se-token';

export const token = Cookies.get(TOKEN_COOKIE_KEY);

const localhostUrls = {
	MerchCatalog: 'http://localhost:45233/v1',
	KappaApi: 'http://localhost:9000/kappa/v2',
	Base: 'http://localhost:9000',
	RealTime: 'http://localhost:6700',
};

const stagingUrls = {
	MerchCatalog: 'tbc',
	KappaApi: 'https://api.staging.streamelements.com/kappa/v2',
	Base: 'https://api.staging.streamelements.com',
	RealTime: 'https://realtime.staging.streamelements.com',
};

const prodUrls = {
	MerchCatalog: 'https://merch-catalog-api.streamelements.com/v1',
	KappaApi: 'https://api.streamelements.com/kappa/v2',
	Base: 'https://api.streamelements.com',
	RealTime: 'https://realtime.streamelements.com',
};

const getMap = () => {
	switch (env) {
		default:
		case 'development':
			return localhostUrls;

		case 'staging':
			return stagingUrls;

		case 'production':
			return prodUrls;
	}
};

/**
 * decodes the current jwt token and returns values
 *
 * @returns {TokenData} returns the channelId and provider
 */
export const decodeToken = () => {
	if (!token) return false;

	const decodedToken = jwt_decode(token as string) as TokenData;

	return {
		channelId: decodedToken.channel,
		provider: decodedToken.provider,
	};
};

export default getMap();
