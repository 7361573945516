/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TemplateVariant } from '../types/TemplateServerResponse';
import { numberOrZero } from './NumberUtils';

const DOWNSCALE_DEFAULT_USER_ASSET_FACTORS_MAP = {};

const productsWithOverlay = ['hoodie', 'deskmat', 'tanktop', 'phonecase-iphone', 'phonecase-samsung', 'minky-blanket'];

interface Sides {
	left: number;
	right: number;
	top: number;
	bottom: number;
}

interface BoundingRect {
	width: number;
	height: number;
	top: number;
	left: number;
}

interface BoundingRectShort {
	width?: number;
	height?: number;
	x?: number;
	y?: number;
	gravity?: string;
}

const extendSafeZone = ['minky-blanket', 'sticker'];
const PRODUCT_IDS = {
	MousePad: '62',
	KissCutSticker: '208',
	PullOverHoodie: '85',
	TShirt: '40',
	Mug: '61',
};

export const convertImageType = (url: string): string => {
	if (url.match(/\.webp$/)) {
		return url.substring(0, url.length - '.webp'.length) + '.png';
	}
	return url;
};

export const calcCropFragment = (
	cropNorthSouth: BoundingRectShort,
	cropEastWest: BoundingRectShort,
	imageWidth: number,
	imageHeight: number,
) => {
	const northSouthCropFragment = cropNorthSouth.gravity
		? `,y_${cropNorthSouth.y},h_${cropNorthSouth.height}`
		: `,h_${imageHeight}`;
	const eastWestCropFragment = cropEastWest.gravity
		? `,x_${cropEastWest.x},w_${cropEastWest.width}`
		: `,w_${imageWidth}`;
	return `c_crop${northSouthCropFragment}${eastWestCropFragment}/`;
};

export function bleedAreaExtension(
	productRefId: string,
	safeZonePadding: Sides,
	fullPrintAreaDimensions: BoundingRect,
): string {
	if (extendSafeZone.includes(productRefId)) {
		return (
			`c_lpad,w_${fullPrintAreaDimensions.width + safeZonePadding.left + safeZonePadding.right}` +
			`,h_${fullPrintAreaDimensions.height + safeZonePadding.top + safeZonePadding.bottom},x_0,y_0/`
		);
	} else {
		return '';
	}
}

export function isAssetInPrintArea(
	xTransform: number,
	yTransform: number,
	printAreaDimensions: BoundingRect,
	imageWidth: number,
	imageHeight: number,
): boolean {
	return (
		xTransform >= 0 &&
		yTransform >= 0 &&
		xTransform * printAreaDimensions.width <= printAreaDimensions.width - imageWidth &&
		yTransform * printAreaDimensions.height <= printAreaDimensions.height - imageHeight
	);
}

export function variantScaleFactor(productId: string, variant: { size: string }): number {
	return productId === PRODUCT_IDS.TShirt && variant.size === 'S' ? 0.8 : 1;
}

export function exactCrop(cropNorthSouth: BoundingRectShort, cropEastWest: BoundingRectShort) {
	return `x_${cropEastWest.x},y_${cropNorthSouth.y},w_${cropEastWest.width},h_${cropNorthSouth.height},c_crop`;
}

export function calcCrop(
	xTrans: number,
	yTrans: number,
	printAreaDims: BoundingRect,
	imageWidth: number,
	imageHeight: number,
): BoundingRectShort[] {
	const cropNorthSouth =
		yTrans < 0
			? {
					y: Math.round(-yTrans * printAreaDims.height),
					height: Math.max(
						0,
						Math.min(Math.round(imageHeight + yTrans * printAreaDims.height), Math.round(printAreaDims.height)),
					),
					gravity: 'north',
			  }
			: yTrans * printAreaDims.height > printAreaDims.height - imageHeight
			? {
					y: 0,
					height: Math.max(0, Math.round((1 - yTrans) * printAreaDims.height)),
					gravity: 'south',
			  }
			: {
					y: 0,
					height: Math.round(imageHeight),
			  };
	const cropEastWest =
		xTrans < 0
			? {
					x: Math.round(-xTrans * printAreaDims.width),
					width: Math.max(
						0,
						Math.min(Math.round(imageWidth + xTrans * printAreaDims.width), Math.round(printAreaDims.width)),
					),
					gravity: 'west',
			  }
			: xTrans * printAreaDims.width > printAreaDims.width - imageWidth
			? {
					x: 0,
					width: Math.max(0, Math.round((1 - xTrans) * printAreaDims.width)),
					gravity: 'east',
			  }
			: {
					x: 0,
					width: Math.round(imageWidth),
			  };
	return [cropNorthSouth, cropEastWest];
}

export function calcUserAssetDefaultDimensions(
	imageWidth: number,
	imageHeight: number,
	scalingFactor: number,
	printAreaDimensions: BoundingRect,
	productId: string,
	productReferenceId: string,
) {
	const scaledWidth = imageWidth * scalingFactor;
	const scaledHeight = imageHeight * scalingFactor;
	const imageAspectRatio = imageWidth / imageHeight;

	const printAreaAspectRatio = printAreaDimensions.width / printAreaDimensions.height;

	const isHeightBased = printAreaAspectRatio > imageAspectRatio;

	const [width, height, resizeFactor] = (() => {
		// @ts-ignore
		const downscaleFactor = DOWNSCALE_DEFAULT_USER_ASSET_FACTORS_MAP[productReferenceId] || 1.0;

		if (isHeightBased && scaledHeight > printAreaDimensions.height) {
			return [
				printAreaDimensions.height * imageAspectRatio * downscaleFactor,
				printAreaDimensions.height * downscaleFactor,
				(printAreaDimensions.height / scaledHeight) * downscaleFactor,
			];
		} else if (!isHeightBased && scaledWidth > printAreaDimensions.width) {
			return [
				printAreaDimensions.width * downscaleFactor,
				(printAreaDimensions.width / imageAspectRatio) * downscaleFactor,
				(printAreaDimensions.width / scaledWidth) * downscaleFactor,
			];
		} else {
			return [scaledWidth * downscaleFactor, scaledHeight * downscaleFactor, 1 * downscaleFactor];
		}
	})();

	const left = printAreaDimensions.left + (printAreaDimensions.width - width) / 2;

	//TODO Replace this with default alignment setting in template, which currently doesn't exist.
	const top =
		productId === PRODUCT_IDS.TShirt || productId === PRODUCT_IDS.PullOverHoodie
			? printAreaDimensions.top
			: printAreaDimensions.top + Math.max((printAreaDimensions.height - height) / 2, 0);

	return {
		width,
		height,
		left,
		top,
		resizeFactor,
	};
}

export function effectiveFullPrintAreaDims(productId: string, variant: TemplateVariant, printAreaDims: any) {
	if (productId === PRODUCT_IDS.TShirt && variant.size === 'S') {
		return { ...printAreaDims, height: 4560 };
	} else {
		return printAreaDims;
	}
}

export const calcPrintAreaDimensions = (
	printArea: { printAreaWidth: number; printAreaTop: number; printAreaLeft: number; printAreaHeight: number },
	scalingFactor: number,
) => {
	const width = Math.round(printArea.printAreaWidth * scalingFactor);
	const height = Math.round(printArea.printAreaHeight * scalingFactor);
	const left = Math.round(printArea.printAreaLeft * scalingFactor);
	const top = Math.round(printArea.printAreaTop * scalingFactor);

	return {
		width,
		height,
		left,
		top,
	};
};

export const getCloudinaryResources = (
	productRefId: string,
	variant: { id: string; color: string; previewId: string },
) => {
	const previewMasks: Object = {
		deskmat: 'previews:Deskmat-18x36_mask.png',
	};

	const result = {
		background: `previews:${variant.previewId}_background.png`,
		overlay: `previews:${variant.previewId}_overlay.png`,
		// @ts-ignore
		mask: previewMasks[productRefId] || `previews:${variant.previewId}_mask.png`,
	};

	if (['phonecase-iphone', 'phonecase-samsung'].includes(productRefId)) {
		result.background = `previews:${variant.id}_background-${variant.color.replace(' ', '')}.png`;
		result.overlay = `previews:${variant.id}_overlay.png`;
		result.mask = `previews:${variant.id}_mask.png`;
	}

	return result;
};

export const prepareVariantImages = async (
	variants: TemplateVariant[],
	colors: string[],
	productData: any,
	transformUrl: string,
	imageDimensions: { imageWidth: number; imageHeight: number },
	spaces: any,
) => {
	const brokenUrl = convertImageType(transformUrl).match(/(.+\/upload\/)(.+)/);
	if (!brokenUrl) return;

	const urlPrefix = brokenUrl[1];
	const urlSuffix = brokenUrl[2];

	const STORE_IMAGE_WIDTH = 1024;
	const STORE_IMAGE_HEIGHT = 1024;

	const productId = productData.id;
	const productRefId = productData.name;
	const previewImageAspectRatio = STORE_IMAGE_WIDTH / STORE_IMAGE_HEIGHT;
	const defaultPositionAlignTop = ['tshirt', 'hoodie', 'tanktop'];

	return variants
		.filter((t: TemplateVariant) => colors.some((a) => a === t.color))
		.map((variant: TemplateVariant) => {
			const templateDims = variant.templates.centerFront;
			const templateWidth = templateDims.templateWidth;
			const templateHeight = templateDims.templateHeight;
			const previewImageResizeFactor = STORE_IMAGE_WIDTH / Math.max(templateWidth, templateHeight);
			const templateAspectRatio = templateWidth / templateHeight;
			const scaledTemplateWidth = Math.min(STORE_IMAGE_WIDTH, Math.round(templateWidth * previewImageResizeFactor));
			const scaledTemplateHeight = Math.min(STORE_IMAGE_HEIGHT, Math.round(templateHeight * previewImageResizeFactor));
			const { background, overlay, mask } = getCloudinaryResources(productRefId, variant);

			const safeZonePadding = {
				bottom: templateDims.bleedPaddingBottom,
				left: templateDims.bleedPaddingLeft,
				right: templateDims.bleedPaddingRight,
				top: templateDims.bleedPaddingTop,
			};

			const printArea = {
				printAreaHeight: templateDims.printAreaHeight,
				printAreaLeft: templateDims.printAreaLeft,
				printAreaTop: templateDims.printAreaTop,
				printAreaWidth: templateDims.printAreaWidth,
			};

			const printAreaDims = calcPrintAreaDimensions(printArea, previewImageResizeFactor);
			const { imageWidth, imageHeight } = imageDimensions;
			const underlaySize =
				previewImageAspectRatio > templateAspectRatio ? `h_${STORE_IMAGE_HEIGHT}` : `w_${STORE_IMAGE_WIDTH}`;
			const fullPrintAreaDims = calcPrintAreaDimensions(printArea, 1);
			const effectivePrintAreaDims = effectiveFullPrintAreaDims(productId, variant, fullPrintAreaDims);
			const printAssetDims = calcUserAssetDefaultDimensions(
				imageWidth,
				imageHeight,
				1,
				fullPrintAreaDims,
				productId,
				productRefId,
			);

			if (spaces && spaces.front) {
				const { assetTransform } = spaces.front;
				const rotation = `a_${Math.round(-assetTransform.rotation)}`;
				const width = Math.round(imageWidth * assetTransform.scale * previewImageResizeFactor); //asset scaled width
				const height = Math.round(imageHeight * assetTransform.scale * previewImageResizeFactor); //asset scaled height

				const printAreaCenterXOffset = scaledTemplateWidth / 2 - printAreaDims.left - printAreaDims.width / 2;
				const printAreaCenterYOffset = scaledTemplateHeight / 2 - printAreaDims.top - printAreaDims.height / 2;

				const xTrans = assetTransform.translation.x;
				const yTrans = assetTransform.translation.y;

				const assetIsInPreviewPrintArea = isAssetInPrintArea(xTrans, yTrans, printAreaDims, width, height);
				const [previewCropNorthSouth, previewCropEastWest] = calcCrop(xTrans, yTrans, printAreaDims, width, height);
				const previewCropFragment = assetIsInPreviewPrintArea
					? ''
					: exactCrop(previewCropNorthSouth, previewCropEastWest);

				const previewCropEastWestWidth = numberOrZero(previewCropEastWest.width);
				const previewCropNorthSouthHeight = numberOrZero(previewCropNorthSouth.height);

				const imageXOffset = `x_${
					(!previewCropEastWest.gravity
						? Math.round((assetTransform.centerPoint.x - 0.5) * printAreaDims.width * -1)
						: previewCropEastWest.gravity === 'west'
						? Math.round(printAreaDims.width / 2 - previewCropEastWestWidth / 2 - 0.5)
						: Math.round((printAreaDims.width / 2 - previewCropEastWestWidth / 2 - 0.5) * -1)) +
					Math.round(printAreaCenterXOffset)
				}`;

				const imageYOffset = `y_${
					(!previewCropNorthSouth.gravity
						? Math.round((assetTransform.centerPoint.y - 0.5) * printAreaDims.height * -1)
						: previewCropNorthSouth.gravity === 'north'
						? Math.round(printAreaDims.height / 2 - previewCropNorthSouthHeight / 2 - 0.5)
						: Math.round((printAreaDims.height / 2 - previewCropNorthSouthHeight / 2 - 0.5) * -1)) +
					Math.round(printAreaCenterYOffset)
				}`;

				const underlayFragment = `u_${background},${underlaySize}`;
				const overlayFragment = productsWithOverlay.includes(productRefId) ? `l_${overlay},${underlaySize}/` : '';
				const maskFragment = `l_${mask},${underlaySize}/`;
				const previewImage =
					`${urlPrefix}w_${width},x_200,y_200/${rotation}/${previewCropFragment}/${maskFragment}` +
					`e_cut_out,fl_layer_apply,${imageXOffset},${imageYOffset}/${underlayFragment}/` +
					`${overlayFragment}${urlSuffix}`;

				const printImageWidth = Math.round(imageWidth * assetTransform.scale * variantScaleFactor(productId, variant));
				const printImageHeight = Math.round(
					imageHeight * assetTransform.scale * variantScaleFactor(productId, variant),
				);
				const xOffset = Math.round(
					assetTransform.centerPoint.x * effectivePrintAreaDims.width - effectivePrintAreaDims.width / 2,
				);
				const yOffset = Math.round(
					assetTransform.centerPoint.y * effectivePrintAreaDims.height - effectivePrintAreaDims.height / 2,
				);
				const assetIsInPrintArea = isAssetInPrintArea(
					xTrans,
					yTrans,
					effectivePrintAreaDims,
					printImageWidth,
					printImageHeight,
				);
				const [cropNorthSouth, cropEastWest] = calcCrop(
					xTrans,
					yTrans,
					effectivePrintAreaDims,
					printImageWidth,
					printImageHeight,
				);
				const cropFragment = assetIsInPrintArea
					? ''
					: calcCropFragment(cropNorthSouth, cropEastWest, printImageWidth, printImageHeight);
				const lpadGravity =
					cropNorthSouth.gravity || cropEastWest.gravity
						? `,g${cropNorthSouth.gravity ? `_${cropNorthSouth.gravity}` : ''}${
								cropEastWest.gravity ? `_${cropEastWest.gravity}` : ''
						  }`
						: '';
				const lpadXy =
					`${!cropNorthSouth.gravity ? `,y_${yOffset}` : ''}` + `${!cropEastWest.gravity ? `,x_${xOffset}` : ''}`;
				const lpadFragment = assetIsInPrintArea
					? `c_lpad,w_$aw,h_$ah,x_${xOffset},y_${yOffset}/`
					: `c_lpad,w_$aw,h_$ah${lpadGravity}${lpadXy}/`;
				// TODO add rotation when a solution is found to the limitation in Cloudinary
				const bleedExtend = bleedAreaExtension(productRefId, safeZonePadding, fullPrintAreaDims);
				const backgroundColorFragment = variant.setAssetBackground
					? `b_rgb:${variant.colorCodes[0].substring(1)}/`
					: '';
				const printImage =
					`${urlPrefix}$aw_${fullPrintAreaDims.width},$ah_${fullPrintAreaDims.height}/${backgroundColorFragment}` +
					`w_${printImageWidth}/${cropFragment}${lpadFragment}${bleedExtend}${urlSuffix}`;

				return {
					[variant.id]: {
						previewImage,
						printImage,
					},
					[variant.previewId]: {
						previewImage,
						printImage,
					},
				};
			} else {
				const assetDimensions = calcUserAssetDefaultDimensions(
					imageWidth,
					imageHeight,
					previewImageResizeFactor,
					printAreaDims,
					productId,
					productRefId,
				);
				const centerOffsetLeft = -Math.round(
					-scaledTemplateWidth / 2 + assetDimensions.left + assetDimensions.width / 2,
				);
				const centerOffsetTop = -Math.round(
					-(scaledTemplateHeight / 2) + assetDimensions.top + assetDimensions.height / 2,
				);

				const printAssetOffsetTop = defaultPositionAlignTop.includes(productRefId)
					? Math.round(-fullPrintAreaDims.height / 2 + printAssetDims.height / 2)
					: 0; //Top

				const bleedExtend = bleedAreaExtension(productRefId, safeZonePadding, fullPrintAreaDims);
				const underlayFragment = `u_${background},x_${centerOffsetLeft},y_${centerOffsetTop},${underlaySize}`;
				const overlayFragment = productsWithOverlay.includes(productRefId) ? `l_${overlay},${underlaySize}/` : ''; //TODO ugly, but temporary.
				const previewImage = `${urlPrefix}w_${Math.round(
					assetDimensions.width,
				)}/${underlayFragment}/${overlayFragment}${urlSuffix}`;
				const cropFragment = `c_lpad,w_${fullPrintAreaDims.width},h_${fullPrintAreaDims.height},y_${printAssetOffsetTop}`;
				const backgroundColorFragment = variant.setAssetBackground
					? `b_rgb:${variant.colorCodes[0].substring(1)}/`
					: '';
				const printImage =
					`${urlPrefix}w_${Math.floor(printAssetDims.width)}/${backgroundColorFragment}` +
					`${cropFragment}/${bleedExtend}${urlSuffix}`;

				return {
					[variant.id]: {
						previewImage,
						printImage,
					},
					[variant.previewId]: {
						previewImage,
						printImage,
					},
				};
			}
		})
		.reduce((acc, cur) => Object.assign(acc, cur), {});
};
