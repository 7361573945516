import { PropsWithChildren } from "react";
import { SvgIcon } from "@streamelements/frontend-icons";
import SvgIconProps from "../../types/SvgIconProps";

export default function FlipHorizontalIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M11.2501 10.4999C11.2501 10.086 11.586 9.74997 12 9.74997C12.4141 9.74997 12.75 10.086 12.75 10.4999L12.75 19.5001C12.75 19.9141 12.4141 20.2502 12 20.2502C11.586 20.2502 11.2501 19.9141 11.2501 19.5001L11.2501 10.4999Z" />
            <path d="M21 9.93729L21 20.0624C21 20.5792 20.5792 21 20.0624 21C19.7963 21 19.5428 20.889 19.3725 20.6999L14.49 15.4417C14.3355 15.2677 14.2499 15.0449 14.2499 14.8124C14.2499 14.5664 14.3481 14.3324 14.5799 14.1073L19.3274 9.36201C19.3372 9.35088 19.3927 9.28634 19.4039 9.27576C19.5825 9.09806 19.8165 8.99987 20.0624 8.99987C20.5792 8.99987 21 9.42051 21 9.93729Z" />
            <path d="M9.39014 14.0781C9.40127 14.0878 9.4643 14.1426 9.47405 14.1531C9.81985 14.5012 9.83715 15.0748 9.50262 15.4499L4.63355 20.6932C4.46106 20.8852 4.20082 21 3.93756 21C3.42078 21 3 20.5792 3 20.0624L3 9.93729C3 9.42051 3.42078 8.99987 3.93756 8.99987C4.18503 8.99987 4.41904 9.09806 4.64248 9.32987L9.39014 14.0781Z" />
            <path d="M15.665 4.53884L17.0397 3.16471C17.2003 3.0043 17.4425 2.95555 17.6525 3.04248C17.8625 3.12955 17.9998 3.335 17.9998 3.56228L17.9998 7.6873C17.9998 7.9978 17.7478 8.24981 17.4373 8.24981L13.3121 8.24981C13.085 8.24981 12.8794 8.11261 12.7925 7.90263C12.7639 7.83287 12.7496 7.76008 12.7496 7.6873C12.7496 7.54104 12.8066 7.39712 12.9147 7.28986L14.597 5.60755C13.6257 4.9025 12.4579 4.49984 11.2497 4.49984C9.51342 4.49984 7.88439 5.28881 6.77956 6.66432C6.52001 6.98677 6.04759 7.03854 5.725 6.77913C5.54056 6.63053 5.44457 6.41314 5.44457 6.19409C5.44457 6.02902 5.49854 5.86326 5.6095 5.72456C7.0008 3.99281 9.05665 2.99977 11.2497 2.99977C12.8569 2.99977 14.4057 3.56159 15.665 4.53884V4.53884Z" />
        </SvgIcon>
    )
}