import { SvgIcon } from "@streamelements/frontend-icons";
import { PropsWithChildren } from "react";
import SvgIconProps from "../../types/SvgIconProps";

export default function EyeIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M12 6C7.90909 6 4.41545 8.54455 3 12.1364C4.41545 15.7282 7.90909 18.2727 12 18.2727C16.0909 18.2727 19.5845 15.7282 21 12.1364C19.5845 8.54455 16.0909 6 12 6ZM12 16.2273C9.74182 16.2273 7.90909 14.3945 7.90909 12.1364C7.90909 9.87818 9.74182 8.04545 12 8.04545C14.2582 8.04545 16.0909 9.87818 16.0909 12.1364C16.0909 14.3945 14.2582 16.2273 12 16.2273ZM12 9.68182C10.6418 9.68182 9.54545 10.7782 9.54545 12.1364C9.54545 13.4945 10.6418 14.5909 12 14.5909C13.3582 14.5909 14.4545 13.4945 14.4545 12.1364C14.4545 10.7782 13.3582 9.68182 12 9.68182Z" />
        </SvgIcon>
    )
}