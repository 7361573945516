import { ErrorState } from '.';

export interface ProductDataState {
	name: string;
	description: string;
	price: number;
	recommendedPrice: number;
	basePrice: number;
	placements: string[];
	savePlacements: string[];
}

export enum EditorStateAction {
	CREATE = 'CREATE',
	EDIT = 'EDIT',
	NEW_STORE = 'NEW_STORE',
}

export interface EditorState {
	fonts: string[];
	error: ErrorState;
	product: ProductDataState;
	state: EditorStateAction;
}

export enum EditorAction {
	SET_PRODUCT_NAME = 'SET_PRODUCT_NAME',
	SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION',
	SET_BASE_PRICE = 'SET_BASE_PRICE',
	SET_RECOMMENDED_PRICE = 'SET_RECOMMENDED_PRICE',
	SET_PLACEMENTS = 'SET_PLACEMENTS',
	SET_SAVE_PLACEMENTS = 'SET_SAVE_PLACEMENTS',
	SET_PRODUCT_PRICE = 'SET_PRODUCT_PRICE',
	SET_ERROR_STATE = 'SET_ERROR_STATE',
	SET_EDITOR_STATE = 'SET_EDITOR_STATE',
}

export type EditorActions =
	| { type: EditorAction.SET_PRODUCT_NAME; payload: string }
	| { type: EditorAction.SET_PRODUCT_DESCRIPTION; payload: string }
	| { type: EditorAction.SET_BASE_PRICE; payload: number }
	| { type: EditorAction.SET_RECOMMENDED_PRICE; payload: number }
	| { type: EditorAction.SET_PRODUCT_PRICE; payload: number }
	| { type: EditorAction.SET_PLACEMENTS; payload: string[] }
	| { type: EditorAction.SET_SAVE_PLACEMENTS; payload: string[] }
	| { type: EditorAction.SET_ERROR_STATE; payload: ErrorState }
	| { type: EditorAction.SET_EDITOR_STATE; payload: EditorStateAction };
