import { PropsWithChildren } from "react";
import { SvgIcon } from "@streamelements/frontend-icons";
import SvgIconProps from "../../types/SvgIconProps";

export default function PlacementsIcon(props: PropsWithChildren<SvgIconProps>) {
    return (
        <SvgIcon {...props}>
            <path d="M17.9045 8.50231L18.5953 6.97207L20.1256 6.28127C20.4666 6.12388 20.4666 5.64294 20.1256 5.48555L18.5953 4.79475L17.9045 3.25577C17.7471 2.91474 17.2662 2.91474 17.1088 3.25577L16.418 4.78601L14.879 5.4768C14.538 5.6342 14.538 6.11513 14.879 6.27253L16.4093 6.96332L17.1001 8.50231C17.2575 8.84333 17.7471 8.84333 17.9045 8.50231ZM10.9441 9.81394L9.5538 6.75346C9.24775 6.07141 8.2684 6.07141 7.96235 6.75346L6.57202 9.81394L3.51154 11.2043C2.82949 11.5191 2.82949 12.4897 3.51154 12.7957L6.57202 14.1861L7.96235 17.2465C8.27714 17.9286 9.24775 17.9286 9.5538 17.2465L10.9441 14.1861L14.0046 12.7957C14.6867 12.4809 14.6867 11.5103 14.0046 11.2043L10.9441 9.81394ZM17.1001 15.4977L16.4093 17.0279L14.879 17.7187C14.538 17.8761 14.538 18.3571 14.879 18.5145L16.4093 19.2052L17.1001 20.7442C17.2575 21.0853 17.7384 21.0853 17.8958 20.7442L18.5866 19.214L20.1256 18.5232C20.4666 18.3658 20.4666 17.8849 20.1256 17.7275L18.5953 17.0367L17.9045 15.4977C17.7471 15.1567 17.2575 15.1567 17.1001 15.4977Z" />
        </SvgIcon>
    )
}