import { fabric } from 'fabric';
// eslint-disable-next-line import/no-unresolved
import { IEvent } from 'fabric/fabric-impl';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, styled, Text } from '@streamelements/frontend-ui';
import { ActionVerticalDivider, tinyIcon } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { getBoundsAndEdges } from '../../store/ActionCreators/ObjectAlignActionCreator';
import { MinusIcon, PlusIcon } from '../Icons';
import { GridController } from './GridController';

const ZoomContainer = styled(Card.Root, {
	display: 'grid',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	padding: '0 $base',
	br: '$base',
	justifySelf: 'end',
	alignSelf: 'end',
	placeItems: 'center',
	boxShadow: '$2',
	border: '1px solid rgba(0, 0, 0, 0.1)',
});

const FitToScreenButton = styled('button', {
	cursor: 'pointer',
	transition: 'opacity 0.2s ease-in-out',
	opacity: 0.9,
	background: 'none',
	border: 'none',
	height: '100%',
	margin: 0,
	padding: '0 calc($base * 2)',

	'&:hover': {
		opacity: 1,
	},
});

const availableZoomJumps = [1, 1.166, 1.33, 1.66, 1.99];

const MIN_ZOOM = availableZoomJumps[0];
const MAX_ZOOM = availableZoomJumps[availableZoomJumps.length - 1];

export function ZoomController() {
	const { activeCanvas } = useActiveCanvas();
	const [zoom, setZoom] = useState<number>(1);
	const [zoomJump, setZoomJump] = useState<number>(1);

	const zoomIn = () => setZoomJump(availableZoomJumps[zoomJump + 1] ? zoomJump + 1 : availableZoomJumps.length - 1);
	const zoomOut = () => setZoomJump(availableZoomJumps[zoomJump - 1] ? zoomJump - 1 : 0);

	const resetViewport = useCallback(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		setZoom(1);
		setZoomJump(1);
		canvas?.setViewportTransform([1, 0, 0, 1, 0, 0]);
	}, [activeCanvas?.canvas]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		setZoom(canvas.getZoom());
	}, [activeCanvas?.canvas, setZoom]);

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const zoomTo = getBoundsAndEdges(canvas);

		canvas.zoomToPoint(new fabric.Point(zoomTo.centerV, zoomTo.centerH), zoom);
		canvas.fire('canvas:zooming');

		return () => {
			if (!canvas) return;
		};
	}, [activeCanvas?.canvas, zoom]);

	useEffect(() => {
		setZoom(availableZoomJumps[zoomJump]);
	}, [zoomJump]);

	return (
		<ZoomContainer>
			<Text.Body variant="caption" weight="bold" css={{ opacity: 0.9, ml: '10px' }}>
				Zoom
			</Text.Body>
			<Button
				variant="ghost"
				color="neutral"
				onClick={zoomIn}
				disabled={zoom >= MAX_ZOOM}
				css={{
					padding: '0 $base',
					opacity: 1,

					'&:disabled': {
						opacity: 0.2,
					},
				}}
			>
				<PlusIcon className={tinyIcon} />
			</Button>
			<Button
				variant="ghost"
				color="neutral"
				onClick={zoomOut}
				disabled={zoom <= MIN_ZOOM}
				css={{
					padding: '0 $base',
					opacity: 1,
					mr: '$base',

					'&:disabled': {
						opacity: 0.2,
					},
				}}
			>
				<MinusIcon className={tinyIcon} />
			</Button>
			<ActionVerticalDivider />
			<GridController />
			<ActionVerticalDivider />
			<FitToScreenButton onClick={resetViewport}>
				<Text.Body variant="caption" weight="bold">
					Fit To Screen
				</Text.Body>
			</FitToScreenButton>
		</ZoomContainer>
	);
}
