type PreviewMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export enum Action {
  GENERATE_PREVIEW = 'GENERATE_PREVIEW',
  GENERATE_PREVIEW_SUCCESS = 'GENERATE_PREVIEW_SUCCESS',
  GENERATE_PREVIEW_ERROR = 'GENERATE_PREVIEW_ERROR',
  OPEN_SOCKET = 'OPEN_SOCKET',
  CLOSE_SOCKET = 'CLOSE_SOCKET',
  RECIEVED_PREVIEW = 'RECIEVED_PREVIEW',
  RESET_PREVIEW = 'RESET_PREVIEW',
  RETRY_PREVIEW = 'RETRY_PREVIEW',
};

export enum PreviewErrorTypes {
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  BAD_REQUEST = 'BAD_REQUEST',
}

type PreviewPayload = {
  [Action.GENERATE_PREVIEW]: undefined;
  [Action.GENERATE_PREVIEW_SUCCESS]: {
    previewId: string;
  };
  [Action.GENERATE_PREVIEW_ERROR]: {
    type: PreviewErrorTypes;
  };
  [Action.RESET_PREVIEW]: undefined;
  [Action.RETRY_PREVIEW]: undefined;
  [Action.OPEN_SOCKET]: {
    socket: SocketIOClient.Socket;
  };
  [Action.CLOSE_SOCKET]: undefined;
  [Action.RECIEVED_PREVIEW]: {
    preview: string;
  };  
}

export type PreviewActions = PreviewMap<PreviewPayload>[keyof PreviewMap<PreviewPayload>];

interface InitialState {
  loading: boolean;
  error: boolean;
  previewId: string;
  preview: string;
  errorType: PreviewErrorTypes | null,
  websocket: {
      generating: boolean;
      open: boolean;
      socket?: SocketIOClient.Socket;
  };
}

export const initialState = {
  loading: true,
  error: false,
  errorType: null,
  preview: '',
  previewId: '',
  websocket: {
    generating: false,
    open: false,
  },
};

export function createPreviewReducer (state: InitialState, action: PreviewActions): InitialState {
  switch (action.type) {
    case Action.GENERATE_PREVIEW:
      return state;
    case Action.GENERATE_PREVIEW_SUCCESS:
      return {
        ...state,
        previewId: action.payload.previewId,
        websocket: {
          ...state.websocket,
          generating: true,
        }
      };
    case Action.GENERATE_PREVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: action.payload.type,
      };
    case Action.OPEN_SOCKET:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          open: true,
          socket: action.payload.socket,
        }
      };
    case Action.RECIEVED_PREVIEW:
      return {
        ...state,
        loading: false,
        previewId: '',
        preview: action.payload.preview,
        websocket: {
          ...state.websocket,
          generating: false,
        }
      };
    case Action.CLOSE_SOCKET:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          socket: undefined,
          open: false,
        }
      };
    case Action.RESET_PREVIEW:
      return {
        ...initialState,
        error: state.error,
        errorType: state.errorType,
      }
    case Action.RETRY_PREVIEW:
      return initialState;
    default:
      throw new Error();
  }
}


