import { useContext } from 'react';
import { getUserAssets } from '../services/MerchAPI';
import { UserContext } from '../store';
import { UserAction } from '../types';

export function useRefetchUserAssets() {
	const { dispatch: userDispatch } = useContext(UserContext);

	async function refetchUserAssets() {
		userDispatch({ type: UserAction.GET_USER_ASSETS });

		try {
			const assets = await getUserAssets();

			userDispatch({
				type: UserAction.GET_USER_ASSETS_SUCCESS,
				payload: assets,
			});
		} catch {
			userDispatch({ type: UserAction.GET_USER_ASSETS_ERROR });
		}
	}

	return { refetchUserAssets };
}
