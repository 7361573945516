import { Template, TemplateVariant, ThreadOption } from '.';

export type PatternType = 'FullDrop' | 'HalfDrop' | 'Brick' | 'None';

export enum CanvasObjectType {
	SHAPE,
	TEXT,
	IMAGE,
	GROUP,
	SVG,
	CLIP,
	CLIP_BORDER,
	OVERLAY,
	BLEED,
	SNAP,
	PATTERN,
	GRID,
	BACKGROUND_IMAGE,
	PRODUCT_IMAGE,
}

export interface CustomFabricObject extends fabric.Object {
	id?: number | undefined;
	layerName?: string;
	objectType?: CanvasObjectType;
	layerLocked?: boolean;
	hiddenLayer?: boolean;
	fontWeight?: string | number | undefined;
	fontStyle?: '' | 'normal' | 'italic' | 'oblique' | undefined;
	underline?: boolean;
	fontFamily?: string;
	textAlign?: string;
	oldObject?: boolean;
	verticalText?: boolean;
	capitalize?: boolean;
	text?: string;
	errored?: boolean;
	dpi?: number;
	_objects?: CustomFabricObject[];
	patternSourceImage?: string;
	patternType?: PatternType;
	patternWidth?: number;
	patternOffsetX?: number;
	patternOffsetY?: number;
	patternAngle?: number;
	patternPadding?: number;
}

export interface CanvasObject {
	position?: string;
	isEmbroidery: boolean;
	canvas: fabric.Canvas | null;
	selectedObject: CustomFabricObject | null;
	layers: CustomFabricObject[];
	visibleLayersCount?: number;
	selectedThreads: ThreadOption[];
	height?: number;
	width?: number;
	exportData: {
		width: number;
		height: number;
		dpi: number;
	};
	clip?: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
	bleed?: {
		top: number;
		left: number;
		bottom: number;
		right: number;
	};
}

export interface CanvasState {
	defaultWidth: number;
	defaultHeight: number;
	preloadCanvases: boolean;
	preloadId: string | null;
	template: Template | null;
	grid: boolean;
	canvases: CanvasObject[];
	printSides: PrintSide[];
	selectedPrintSide: string | null;
	variants: TemplateVariant[];
	activeVariants: string[];
	selectedVariant: string | null;
	ready: boolean;
	error: boolean;
}

export enum CanvasAction {
	SET_CANVASES = 'SET_CANVASES',
	SET_ERROR = 'SET_ERROR',
	SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE',
	SET_ACTIVE_PRINTSIDE = 'SET_ACTIVE_PRINTSIDE',
	SET_ACTIVE_VARIANT = 'SET_ACTIVE_VARIANT',
	SET_ACTIVE_VARIANTS = 'SET_ACTIVE_VARIANTS',
	SET_LAYERS = 'SET_LAYERS',
	SET_THREADS = 'SET_THREADS',
	SET_SELECTED_OBJECT = 'SET_SELECTED_OBJECT',
	SET_GRID_ENABLED = 'SET_GRID_ENABLED',
}

export type LayersPayload = {
	layers: CustomFabricObject[];
	count: number;
};

export type PrintSide = {
	value: string;
	price: number;
};

export type CanvasActions =
	| { type: CanvasAction.SET_CANVASES; payload: CanvasObject[] }
	| {
			type: CanvasAction.SET_ACTIVE_TEMPLATE;
			payload: {
				template: Template | null;
				preloadCanvases: boolean;
				preloadId: string | null;
				activeVariants: string[];
			};
	  }
	| { type: CanvasAction.SET_ACTIVE_PRINTSIDE; payload: string }
	| { type: CanvasAction.SET_ACTIVE_VARIANT; payload: string | null }
	| { type: CanvasAction.SET_ACTIVE_VARIANTS; payload: string[] }
	| { type: CanvasAction.SET_GRID_ENABLED; payload: boolean }
	| { type: CanvasAction.SET_ERROR; payload: boolean }
	| {
			type: CanvasAction.SET_LAYERS;
			payload: LayersPayload;
	  }
	| {
			type: CanvasAction.SET_THREADS;
			payload: ThreadOption[];
	  }
	| { type: CanvasAction.SET_SELECTED_OBJECT; payload: any };
