import arrayMove from 'array-move';
import CanvasObjectType from '../../enums/CanvasObjectType';
import {
	CanvasObject,
	CanvasActions,
	CanvasAction,
	CustomFabricObject,
	EditorAction,
	EditorActions,
} from '../../types';
import CustomFabricImage from '../../types/CustomFabricImage';

export function updateLayers(
	canvas: fabric.Canvas,
	dispatch: React.Dispatch<CanvasActions>,
	position: string | undefined,
	editorDispatch: React.Dispatch<EditorActions>,
	placements: string[],
): void {
	if (!canvas) return;

	const canvasObjs = canvas.getObjects() as CustomFabricObject[];
	const templateImageLayer = canvasObjs.filter(
		(o) => o.get('objectType') === CanvasObjectType.PRODUCT_IMAGE,
	) as CustomFabricImage[];
	const backgroundLayer = canvasObjs.filter(
		(o) => o.get('objectType') === CanvasObjectType.BACKGROUND_IMAGE,
	) as CustomFabricImage[];

	let newCanvasObjects: CustomFabricObject[] = [];

	newCanvasObjects = canvasObjs.filter((obj) => !obj.get('hiddenLayer')).reverse();

	dispatch({
		type: CanvasAction.SET_LAYERS,
		payload: {
			layers: newCanvasObjects,
			count: newCanvasObjects.length,
		},
	});

	if (position) {
		editorDispatch({
			type: EditorAction.SET_PLACEMENTS,
			payload:
				newCanvasObjects.length === 0
					? placements.filter((p) => p !== position)
					: placements.includes(position)
					? placements
					: [...placements, position],
		});
	}

	canvas.sendToBack(templateImageLayer[0]);
	canvas.sendToBack(backgroundLayer[0]);
	canvas.renderAll();
}

export function sortLayers(
	activeCanvas: CanvasObject,
	dispatch: React.Dispatch<CanvasActions>,
	oldIndex: number,
	newIndex: number,
	position: string | undefined,
	editorDispatch: React.Dispatch<EditorActions>,
	placements: string[],
): void {
	const canvas = activeCanvas?.canvas;
	const layers = activeCanvas?.layers;

	if (!canvas || !layers) return;

	const newLayerOrder = arrayMove(layers, oldIndex, newIndex);

	newLayerOrder.forEach((i) => canvas.sendToBack(i));
	updateLayers(canvas, dispatch, position, editorDispatch, placements);
}
