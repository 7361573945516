import { StoreResponse } from '.';

export interface User {
	_id: string;
	alias: string;
	accessToken: string;
	displayName: string;
	primaryChannel: string;
	username: string;
}

export interface UploadsEntity {
	deleted: boolean;
	_id: string;
	channel: string;
	storagePath: string;
	name: string;
	size: number;
	url: string;
	type: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	transformUrl?: string | null;
}

export interface UserAssetsResponse {
	total: number;
	totalSize: number;
	totalStorage: number;
	uploads: UploadsEntity[] | null;
	loading: boolean;
	error: boolean;
}

export interface UserState {
	user: User | null;
	store: StoreResponse | null;
	assets: UserAssetsResponse | null;
	loading: boolean;
	error: boolean;
}

export enum UserAction {
	GET_USER = 'GET_USER',
	GET_USER_SUCCESS = 'GET_USER_SUCCESS',
	GET_USER_ERROR = 'GET_USER_ERROR',
	GET_USER_ASSETS = 'GET_USER_ASSETS',
	GET_USER_ASSETS_SUCCESS = 'GET_USER_ASSETS_SUCCESS',
	GET_USER_ASSETS_ERROR = 'GET_USER_ASSETS_ERROR',
	DELETE_USER_ASSET = 'DELETE_USER_ASSET',
	DELETE_USER_ASSET_SUCCESS = 'DELETE_USER_ASSET_SUCCESS',
	DELETE_USER_ASSET_ERROR = 'DELETE_USER_ASSET_ERROR',
}

export type UserActions =
	| { type: UserAction.GET_USER }
	| { type: UserAction.GET_USER_SUCCESS; payload: { user: User; store: StoreResponse; assets: UserAssetsResponse } }
	| { type: UserAction.GET_USER_ERROR }
	| { type: UserAction.GET_USER_ASSETS }
	| { type: UserAction.GET_USER_ASSETS_SUCCESS; payload: UserAssetsResponse }
	| { type: UserAction.GET_USER_ASSETS_ERROR }
	| { type: UserAction.DELETE_USER_ASSET }
	| { type: UserAction.DELETE_USER_ASSET_SUCCESS }
	| { type: UserAction.DELETE_USER_ASSET_ERROR };
