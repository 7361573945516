import { useContext, useCallback, useEffect, useState } from 'react';
import { Text, Divider, Button, styled } from '@streamelements/frontend-ui';
import { Sidebar, SidebarBlock, SidebarContainer } from '../../../Editor.style';
import { ScienceEvent, ScienceSource } from '../../../enums';
import { useActiveCanvas, useScience, useTemplate } from '../../../hooks';
import { CanvasesContext, UserContext } from '../../../store';
import { ThreadOption, CanvasAction } from '../../../types';
import { Badge } from '../../Badge';
import { ThreadSelector } from './ThreadSelector';

const ThreadContainer = styled(SidebarContainer, {
	width: '320px',
	zIndex: 1,
});

export function ThreadController() {
	const { template } = useTemplate();
	const { activeCanvas } = useActiveCanvas();
	const { trackEvent } = useScience();
	const { state: userState } = useContext(UserContext);
	const { state: canvasState, dispatch: canvasDispatch } = useContext(CanvasesContext);
	const [availableThreads, setAvailableThreads] = useState<{ [key: string]: string } | undefined>();
	const [selectedThreads, setSelectedThreads] = useState<ThreadOption[] | undefined>();

	const dispatchSelectedThreads = useCallback(
		(threads: ThreadOption[]) => {
			canvasDispatch({ type: CanvasAction.SET_THREADS, payload: threads });
		},
		[canvasDispatch],
	);

	function updateSelectedThreads(value: ThreadOption, index: number) {
		if (selectedThreads?.length) {
			const updatedThreads = selectedThreads;

			updatedThreads[index] = value;

			dispatchSelectedThreads(updatedThreads);
		}
	}

	function addThread() {
		if (selectedThreads && selectedThreads.length < 6 && availableThreads) {
			const addedThreads = selectedThreads;
			const filteredThreads = Object.keys(availableThreads).filter(
				(athread) => !addedThreads.some((thread) => athread === thread.value),
			);

			trackEvent({
				name: ScienceEvent.ADD_THREAD,
				source: ScienceSource.EDITOR_PANEL,
				placement: 'add_thread_button',
			});

			addedThreads.push({
				label: availableThreads[filteredThreads[0]],
				value: filteredThreads[0],
			});

			dispatchSelectedThreads(addedThreads);
		}
	}

	function removeThread(position: number) {
		if (selectedThreads?.length) {
			const removedThread = selectedThreads.filter((_t, i) => i !== position);

			trackEvent({
				name: ScienceEvent.REMOVE_THREAD,
				source: ScienceSource.EDITOR_PANEL,
				placement: 'trash_icon',
			});

			dispatchSelectedThreads(removedThread);
		}
	}

	useEffect(() => {
		if (activeCanvas) setSelectedThreads(activeCanvas.selectedThreads);
	}, [activeCanvas?.selectedThreads]);

	useEffect(() => {
		if (template && canvasState?.selectedPrintSide) {
			setAvailableThreads(template?.vendorPlacements[canvasState.selectedPrintSide].options.flat.threadColors);
		}
	}, [template, canvasState?.selectedPrintSide]);

	useEffect(() => {
		const activeItem = userState.store?.items?.find((item) => item.id === canvasState.preloadId);
		const savedThreads =
			canvasState?.selectedPrintSide && activeItem
				? activeItem?.placementsAssets[canvasState?.selectedPrintSide].threadColors
				: [];
		const threadArr = [] as ThreadOption[];

		if (availableThreads && !selectedThreads?.length) {
			if (savedThreads?.length) {
				Object.keys(availableThreads).forEach((key) => {
					savedThreads.forEach((threadColor) => {
						if (threadColor === key) threadArr.push({ label: availableThreads[key], value: key });
					});
				});
			} else {
				Object.keys(availableThreads).forEach((key) => {
					if (threadArr.length <= 0) threadArr.push({ label: availableThreads[key], value: key });
				});
			}

			dispatchSelectedThreads(threadArr);
		}
	}, [availableThreads, canvasDispatch, dispatchSelectedThreads, selectedThreads]);

	return (
		<ThreadContainer>
			<Sidebar>
				<SidebarBlock clearBottom>
					<Text.Subtitle weight="black" css={{ my: 'auto' }}>
						Embroidery
						<Badge css={{ display: 'inline-block' }} size="compact" type="beta">
							Beta
						</Badge>
					</Text.Subtitle>
				</SidebarBlock>
				<Divider />
				<SidebarBlock css={{ margin: '10px 1.25rem' }}>
					<Text.Body weight="black" css={{ my: 'auto' }}>
						Thread colors
					</Text.Body>
					<Text.Body css={{ margin: '2px 0 5px', fontSize: '0.75rem', color: '$textAlwaysDark70' }}>
						Select the thread colors of your design below
					</Text.Body>
				</SidebarBlock>
				<Button
					css={{ width: 'calc(100% - 40px)', margin: '0 auto 1rem' }}
					onClick={addThread}
					disabled={selectedThreads && selectedThreads.length >= 6}
					variant="outlined"
				>
					Add Extra Colour
				</Button>
				<Divider />
				<SidebarBlock css={{ mt: '$base' }}>
					{selectedThreads?.length &&
						selectedThreads.map((selectedThread, position) => (
							<ThreadSelector
								key={`${selectedThread.label}-${position}`}
								selectedThread={selectedThread}
								position={position}
								threads={availableThreads}
								setSelectedThreads={updateSelectedThreads}
								removeThread={removeThread}
								disableDelete={selectedThreads.length === 1}
							/>
						))}
				</SidebarBlock>
			</Sidebar>
		</ThreadContainer>
	);
}
