import { Button, styled } from "@streamelements/frontend-ui";

const ThemedIconButton = styled(Button, {
    compoundVariants: [
        {
            // @ts-ignore
            iconButton: 'true',
            color: 'neutral',
            // @ts-ignore
            css: {
                color: '$textPrimary70',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover' : {
                    color: '$textPrimaryMain',
                }
            }
        }
    ]
});

export default ThemedIconButton;