import CanvasObjectType from './CanvasObjectType';

export enum EditorState {
	NEW_STORE,
	CREATE,
	EDIT,
}

export enum ScienceSource {
	EDITOR_PANEL = 'editor_panel',
	PRODUCT_PREVIEW_POPUP = 'product_preview_popup',
	SAVING_POPUP_WINDOW = 'saving_popup_window',
	PRODUCT_PREVIEW = 'product_preview_popup',
	MULTISIDE_POPUP = 'multiside_popup',
}

export enum ScienceEvent {
	SAVE = 'save_item_accept_click',
	SAVE_AND_PUBLISH = 'save_and_publish_item_accept_click',
	SAVE_ITEM = 'save_item_click',
	EDIT_ITEM = 'edit_item_click',
	EXPORT = 'export_button_click',
	REPORT_BUG = 'report_bug_click',
	INFO_BANNER_APPEAR = 'info_banner_appear',
	ADD_THREAD = 'add_thread_click',
	REMOVE_THREAD = 'remove_thread_click',
	ITEM_GENERATOR = 'item_generator_click',
	ITEM_PREVIEW_START = 'item_preview_load_start',
	ITEM_PREVIEW_END = 'item_preview_load_end',
	VIEW_FULL_PREVIEW = 'view_full_preview_click',
	PREVIEW_ITEM_CLICK = 'preview_item_click',
	CHANGE_BACKGROUND_COLOR = 'change_background_color_click',
}

export enum SciencePrefix {
	DASHBOARD = 'dashboard',
}

export { CanvasObjectType };
