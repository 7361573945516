import { useContext, useState, useEffect } from 'react';
import { CanvasesContext } from '../store';
import { TemplateVariant } from '../types';

export function useVariantData(color: string) {
	const [variant, setVariant] = useState<TemplateVariant | null>(null);
	const { state: canvasState } = useContext(CanvasesContext);
	const { variants } = canvasState;

	useEffect(() => {
		if (color) {
			const foundVariant = variants.find((v) => v.color === color);

			setVariant(foundVariant || null);
		}
	}, [color, variants]);

	return { variant };
}
