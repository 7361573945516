import { useContext, useState, useEffect } from 'react';
import { CanvasesContext } from '../store';
import { TemplateVariant, ActiveVariantTemplateData } from '../types';
import { invertColor } from '../utils/ColorUtils';

export function useActiveVariant() {
	const { state: canvasState } = useContext(CanvasesContext);
	const { selectedPrintSide, selectedVariant, variants } = canvasState;
	const [activeVariant, setActiveVariant] = useState<TemplateVariant | null>(null);
	const [activeTemplateData, setActiveTemplateData] = useState<ActiveVariantTemplateData | null>(null);

	useEffect(() => {
		if (selectedPrintSide && selectedVariant) {
			const foundVariant = variants.find((v) => v.color === selectedVariant) as TemplateVariant;
			const variantTemplateData = foundVariant.templates[selectedPrintSide];

			setActiveVariant(foundVariant);
			setActiveTemplateData({
				...variantTemplateData,
				invertedColor: invertColor(variantTemplateData.backgroundColor || foundVariant.colorCodes[0]),
			});
		}
	}, [selectedPrintSide, selectedVariant, variants]);

	return { activeVariant, activeTemplateData };
}
