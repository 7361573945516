import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ProductEditor } from './App';
import './index.css';

ReactDOM.render(
	<BrowserRouter>
		<ProductEditor />
	</BrowserRouter>,
	document.getElementById('root'),
);
