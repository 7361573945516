import { createContext, useReducer, useEffect } from 'react';
import { templatesInitialState, TemplatesReducer } from '.';
import { getTemplates } from '../../services/merchCatalog';
import { TemplateState, TemplateAction, TemplateActions } from '../../types';

export const TemplatesContext = createContext<{
	state: TemplateState;
	dispatch: React.Dispatch<TemplateActions>;
}>({
	state: templatesInitialState,
	dispatch: () => null,
});

export const TemplatesProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(TemplatesReducer, templatesInitialState);

	useEffect(() => {
		async function fetchTemplates() {
			dispatch({ type: TemplateAction.GET_TEMPLATES });
			try {
				const res = await getTemplates();

				dispatch({ type: TemplateAction.GET_TEMPLATES_SUCCESS, payload: res.list });
			} catch {
				dispatch({ type: TemplateAction.GET_TEMPLATES_ERROR });
			}
		}

		if (!state.loading && !state.list.length) fetchTemplates();
	}, []);

	return <TemplatesContext.Provider value={{ state, dispatch }}>{children}</TemplatesContext.Provider>;
};
