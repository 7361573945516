import { UserState, UserAction, UserActions } from '../../types';

export const userInitialState = {
	user: null,
	store: null,
	error: false,
	loading: false,
	assets: {
		loading: false,
		error: false,
		total: 0,
		totalSize: 0,
		totalStorage: 0,
		uploads: [],
	},
};

export function UserReducer(state: UserState = userInitialState, action: UserActions): UserState {
	switch (action.type) {
		case UserAction.GET_USER:
			return {
				...state,
				loading: true,
			};
		case UserAction.GET_USER_SUCCESS:
			return {
				...state,
				user: action.payload.user,
				store: action.payload.store,
				assets: action.payload.assets,
				loading: false,
			};
		case UserAction.GET_USER_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case UserAction.GET_USER_ASSETS:
			if (!state.assets) return state;

			return {
				...state,
				assets: {
					...state.assets,
					error: false,
					loading: true,
				},
			};
		case UserAction.GET_USER_ASSETS_SUCCESS:
			return {
				...state,
				assets: {
					...action.payload,
					loading: false,
					error: false,
				},
			};
		case UserAction.GET_USER_ASSETS_ERROR:
			if (!state.assets) return state;

			return {
				...state,
				assets: {
					...state.assets,
					error: true,
					loading: false,
				},
			};
		default:
			throw new Error();
	}
}
