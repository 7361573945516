import { useContext, useEffect, useState } from 'react';
import { styled } from '@streamelements/frontend-ui';
import { ActionBlock } from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { EditorsContext } from '../../store';
import { setObjProp } from '../../store/ActionCreators/ObjectColorCreator';
import CustomFabricObject from '../../types/CustomFabricObject';
import ThemedSelect from '../ThemesSelect';

const StyledOption = styled('div', {
	padding: '$base calc($base * 2)',

	'&:hover': {
		cursor: 'pointer',
		background: '$uiTertiary',
		borderRadius: '$base',
	},
});

export default function FontFaceController() {
	const { state: editorState } = useContext(EditorsContext);
	const { activeCanvas } = useActiveCanvas();
	const [fonts, setFonts] = useState<{ label: string; value: string }[]>([]);
	const [value, setValue] = useState<string>('Arial');

	const CustomOption = (props: any) => {
		const { data, children, setValue } = props;
		return (
			<StyledOption style={{ fontFamily: data.value }} onClick={() => setValue(data)}>
				{children}
			</StyledOption>
		);
	};

	useEffect(() => {
		const canvas = activeCanvas?.canvas;

		if (!canvas) return;

		const activeObject = canvas.getActiveObject() as CustomFabricObject;

		if (activeObject) setValue(activeObject.get('fontFamily') || 'Arial');
	}, [activeCanvas]);

	useEffect(() => {
		if (activeCanvas) setObjProp(activeCanvas, 'fontFamily', value || '');
	}, [value]);

	useEffect(() => {
		if (!editorState.fonts) return;

		setFonts(editorState.fonts?.map((f) => ({ label: f, value: f })));
	}, [editorState.fonts]);

	return (
		<ActionBlock clearVerticalSpacing>
			<ThemedSelect
				placeholder="Font..."
				borderless
				value={fonts.find((i) => i.value === value)}
				onChange={(v: { value: string }) => setValue(v.value)}
				options={fonts}
				components={{
					Option: CustomOption,
				}}
			/>
		</ActionBlock>
	);
}
