import { fabric } from 'fabric';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { CanvasObject } from '../../types';
import CustomFabricObject from '../../types/CustomFabricObject';

interface FullBoundingRect {
	top: number;
	left: number;
	right: number;
	width: number;
	bottom: number;
	height: number;
	centerH: number;
	centerV: number;
}

export function AlignVerticalTop(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const activeObject = canvas.getActiveObject();
	const gridTop =
		clip.getPointByOrigin('center', 'top').y + activeObject.getBoundingRect().height / canvas.getZoom() / 2;
	const center = new fabric.Point(activeObject.getCenterPoint().x, gridTop);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export function AlignVerticalMiddle(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const gridCenter = clip.getCenterPoint();
	const activeObject = canvas.getActiveObject();
	const center = new fabric.Point(gridCenter.x, activeObject.getCenterPoint().y);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export function AlignVerticalBottom(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const activeObject = canvas.getActiveObject();
	const gridBottom =
		clip.getPointByOrigin('center', 'bottom').y - activeObject.getBoundingRect().height / canvas.getZoom() / 2;
	const center = new fabric.Point(activeObject.getCenterPoint().x, gridBottom);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export function AlignHorizontalLeft(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const activeObject = canvas.getActiveObject();
	const gridLeft =
		clip.getPointByOrigin('left', 'center').x + activeObject.getBoundingRect().width / canvas.getZoom() / 2;
	const center = new fabric.Point(gridLeft, activeObject.getCenterPoint().y);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export function AlignHorizontalMiddle(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const activeObject = canvas.getActiveObject();
	const gridCenter = clip.getCenterPoint();
	const center = new fabric.Point(activeObject.getCenterPoint().x, gridCenter.y);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export function AlignHorizontalRight(activeCanvas: CanvasObject): void {
	const canvas = activeCanvas.canvas;
	const selectedObject = activeCanvas.selectedObject;

	if (!canvas || !selectedObject) return;

	const clip = canvas
		.getObjects()
		.find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP) as fabric.Object;
	const activeObject = canvas.getActiveObject();
	const gridRight =
		clip.getPointByOrigin('right', 'center').x - activeObject.getBoundingRect().width / canvas.getZoom() / 2;
	const center = new fabric.Point(gridRight, activeObject.getCenterPoint().y);

	activeObject.setPositionByOrigin(center, 'center', 'center');
	activeObject.setCoords();

	canvas.renderAll();
}

export const getBoundsAndEdges = (canvas: fabric.Canvas): FullBoundingRect => {
	const Rect = {
		height: canvas.getHeight(),
		width: canvas.getWidth(),
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		centerH: 0,
		centerV: 0,
	};

	const clipLayer = canvas.getObjects().find((o: CustomFabricObject) => o.get('objectType') === CanvasObjectType.CLIP);

	if (clipLayer) {
		const { height, width, top, left } = clipLayer.getBoundingRect();
		Rect.height = height;
		Rect.width = width;
		Rect.top = top;
		Rect.left = left;
	}

	Rect.right = Rect.width + Rect.left;
	Rect.bottom = Rect.height + Rect.top;
	Rect.centerH = Rect.height / 2 + Rect.top;
	Rect.centerV = Rect.width / 2 + Rect.left;

	return Rect;
};

export const getUnscaledBoundsAndEdges = (activeCanvas: CanvasObject): FullBoundingRect => {
	const Rect = {
		height: Number(activeCanvas?.height),
		width: Number(activeCanvas?.width),
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		centerH: 0,
		centerV: 0,
	};

	if (activeCanvas?.clip) {
		const { height, width, top, left } = activeCanvas.clip;
		Rect.height = height - top;
		Rect.width = width - left;
		Rect.top = top;
		Rect.left = left;
	}

	Rect.right = Rect.width + Rect.left;
	Rect.bottom = Rect.height + Rect.top;
	Rect.centerH = Rect.height / 2 + Rect.top;
	Rect.centerV = Rect.width / 2 + Rect.left;

	return Rect;
};
