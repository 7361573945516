import { Text, Dropdown, Button, Slot, Divider } from '@streamelements/frontend-ui';
import { LayerActions } from '.';
import { ShapeChoosePartial } from '../';
import * as S from '../../Editor.style';
import { useActiveCanvas } from '../../hooks';
import { addText } from '../../store/ActionCreators/CanvasActionCreator';
import { LayerController, CanvasController } from '../Controllers';
import { SEGalleryIcon, ShapeIcon, UploadIcon, TextIcon } from '../Icons';

export function Layers({
	setAssetManagerModelOpen,
	setAssetManagerTab,
}: {
	setAssetManagerModelOpen: (open: boolean) => void;
	setAssetManagerTab: (tab: number) => void;
}) {
	const { activeCanvas } = useActiveCanvas();
	const canvas = activeCanvas?.canvas;

	if (!canvas) return null;

	return (
		<S.Sidebar>
			<S.SidebarBlock
				clearBottom
				css={{
					display: 'grid',
					gridAutoFlow: 'column',
					placeContent: 'space-between',
					placeItems: 'center',
					overflow: 'visible',
				}}
			>
				<Text.Subtitle weight="black">Layers</Text.Subtitle>
				<Dropdown.Root>
					<Dropdown.Trigger as={Button} variant="outlined" density="compact">
						Add New
					</Dropdown.Trigger>
					<Dropdown.Content css={{ minWidth: 200, marginTop: 8 }} align="start">
						<Dropdown.Item
							onSelect={() => {
								setAssetManagerTab(1);
								setAssetManagerModelOpen(true);
							}}
							className={S.dropdownItemStyle}
						>
							<SEGalleryIcon className={S.smallIcon} />
							<Text.Body>SE.Gallery</Text.Body>
						</Dropdown.Item>
						<Dropdown.Separator />
						<Dropdown.Item
							onSelect={() => {
								setAssetManagerTab(2);
								setAssetManagerModelOpen(true);
							}}
							className={S.dropdownItemStyle}
						>
							<UploadIcon className={S.smallIcon} />
							<Text.Body>Your uploads</Text.Body>
						</Dropdown.Item>
						<Dropdown.Separator />
						<Dropdown.Item onSelect={() => addText(activeCanvas)} className={S.dropdownItemStyle}>
							<TextIcon className={S.smallIcon} />
							<Text.Body>Text</Text.Body>
						</Dropdown.Item>
						<Dropdown.Separator />
						<Dropdown.Root>
							<Dropdown.Trigger as={Slot}>
								<div className={S.dropdownItemStyle}>
									<ShapeIcon className={S.smallIcon} />
									<Text.Body>Shapes</Text.Body>
								</div>
							</Dropdown.Trigger>
							<Dropdown.Content side={'right'}>
								<ShapeChoosePartial />
							</Dropdown.Content>
						</Dropdown.Root>
					</Dropdown.Content>
				</Dropdown.Root>
			</S.SidebarBlock>
			<Divider />
			<S.SidebarBlock>
				<LayerController />
			</S.SidebarBlock>
			<CanvasController />
			<LayerActions activeCanvas={activeCanvas} />
		</S.Sidebar>
	);
}
