/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from 'fabric';
import { useContext } from 'react';
import { CloseRounded } from '@streamelements/frontend-icons';
import { Text } from '@streamelements/frontend-ui';
import { ActionBlock, smallIcon } from '../../Editor.style';
import CanvasObjectType from '../../enums/CanvasObjectType';
import { useActiveCanvas } from '../../hooks';
import { CanvasesContext } from '../../store';
import { addImage, removeObject } from '../../store/ActionCreators/CanvasActionCreator';
import CustomFabricImage from '../../types/CustomFabricImage';
import CustomFabricObject from '../../types/CustomFabricObject';
import { PatternType } from '../../types/PatternType';
import { objectToPattern } from '../../utils/CanvasUtils';
import BricksPatternIcon from '../Icons/BricksPatternIcon';
import FullDropPatternIcon from '../Icons/FullDropPatternIcon';
import HalfDropPatternIcon from '../Icons/HalfDropPatternIcon';
import ThemedSelect, { ThemedSelectOption } from '../ThemesSelect';

const availablePatterns = [
	{
		label: (
			<ThemedSelectOption>
				<FullDropPatternIcon className={smallIcon} />
				<Text.Body>Full drop pattern</Text.Body>
			</ThemedSelectOption>
		),
		value: 'FullDrop',
	},
	{
		label: (
			<ThemedSelectOption>
				<HalfDropPatternIcon className={smallIcon} />
				<Text.Body>Half drop pattern</Text.Body>
			</ThemedSelectOption>
		),
		value: 'HalfDrop',
	},
	{
		label: (
			<ThemedSelectOption>
				<BricksPatternIcon className={smallIcon} />
				<Text.Body>Brick pattern</Text.Body>
			</ThemedSelectOption>
		),
		value: 'Brick',
	},
	{
		label: (
			<ThemedSelectOption>
				<CloseRounded className={smallIcon} />
				<Text.Body>Remove pattern</Text.Body>
			</ThemedSelectOption>
		),
		value: 'None',
	},
];

export default function PatternController() {
	const { activeCanvas } = useActiveCanvas();
	const { state: canvasState } = useContext(CanvasesContext);
	const canvas = activeCanvas?.canvas;

	if (!canvas) return null;

	const onPatternifyClicked = (patternType: PatternType, callback?: (img: CustomFabricImage) => void) => {
		const activeObject = canvas.getActiveObject() as CustomFabricObject;
		const objectType = activeObject.get('objectType');

		if (patternType === 'None' && activeObject && objectType === CanvasObjectType.PATTERN) {
			const sourceImage = activeObject.patternSourceImage;

			if (!sourceImage) return;

			removeObject(canvas, activeObject);

			fabric.util.loadImage(sourceImage, (img) => {
				addImage(canvasState, activeCanvas, img, 'Image', true, callback);
			});
		} else {
			if (objectType === CanvasObjectType.PATTERN) {
				onPatternifyClicked('None', (img) => {
					// @ts-ignore
					objectToPattern(activeCanvas, img, { patternType }, patternType);
				});
			} else {
				// @ts-ignore
				objectToPattern(activeCanvas, activeCanvas.canvas.getActiveObject(), { patternType }, patternType);
			}
		}
	};

	return (
		<ActionBlock clearVerticalSpacing>
			<ThemedSelect
				bigValue={true}
				placeholder="Select pattern"
				options={availablePatterns}
				onChange={(v: { value: PatternType }) => onPatternifyClicked(v.value)}
				borderless
			/>
		</ActionBlock>
	);
}
