// TODO Remove when we upgrade frontend-ui
export function LoaderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      width="150"
      height="150"
    >
      <path
        fill='grey'
        d="M 73 50 c 0 -12.7 -10.3 -23 -23 -23 S 27 37.3 27 50 m 0 0 A 1 1 0 0 0 29 50 c 0 -10.5 9 -21 21 -21 S 70 39 71 50 A 1 1 0 0 0 73 50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="1s"
          from="0 50 50"
          repeatCount="indefinite"
          to="360 50 50"
          type="rotate"
        ></animateTransform>
      </path>
    </svg>
  );
}
